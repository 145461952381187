<template>
  <svg viewBox="0 0 960 261" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M357.863 175.98c13.887-31.19 46.371-54.89 86.055-58.86 39.683-3.97 72.228 11.21 92.18 36.58-24.043-17.65-55.946-26.87-89.945-23.47-36.455 3.64-67.896 21.06-88.279 45.75h-.011Z"
      fill="#768ABC"
    />
    <path
      d="M.0206299 260.25C.0206366 182.92 60.2174 120.22 134.461 120.22c18.448 0 36.024 3.87 52.025 10.88 30.77-57.1701 89.513-95.8101 156.909-95.8101 37.367 0 72.067 11.8801 100.812 32.2001C484.683 31.29 537.31 9.41996 594.84 9.41997c41.087 0 79.667 11.16003 113.114 30.72003C732.869 15.42 766.597.249983 803.714.249986 834.053.249989 862.116 10.39 884.945 27.57 905.519 10.47 931.607.249998 959.98.25v260H.00061035.0206299Z"
      fill="#768ABC"
    />
    <path
      d="M80.8705 260.25c29.8775-61.69 91.2675-104 162.1425-104 23.792 0 46.511 4.77 67.345 13.43 25.858-47.97 75.166-80.38 131.753-80.38 31.382 0 60.528 9.9699 84.671 27.04 33.998-30.4 78.203-48.77 126.519-48.77 34.52 0 66.925 9.37 95.008 25.8 20.934-20.77 49.258-33.5 80.429-33.5 25.487 0 49.048 8.51 68.228 22.94C914.251 68.45 936.168 59.87 960 59.87v200.38H80.8705Z"
      fill="#6F7FB2"
    />
    <path
      d="M634.183 125.68c20.514-20.4 49.479-31.1703 79.748-26.7203 12.423 1.8303 23.943 6.0603 34.209 12.1803 15.049-13.9003 34.39-22.9903 55.976-24.7703 20.734-1.72 40.465 3.56 57.029 13.9403-14.578-3.1703-29.818-4.2403-45.409-2.9503-21.967 1.82-42.541 8.1703-60.948 18.1303-1.845.99-3.67 2.03-5.475 3.1-2.175-.73-4.371-1.41-6.597-2.03-6.407-1.82-12.994-3.24-19.741-4.23-31.392-4.62-61.942.58-88.792 13.35Z"
      fill="#768ABC"
    />
    <path
      d="M158.19 260.25c25.998-26.69 64.438-43.58 107.3-43.58 28.714 0 55.434 7.58 77.762 20.6 30.279-34.86 77.753-57.31 131.122-57.31 23.351 0 45.568 4.29 65.721 12.04 32.394-34.55 81.161-56.54 135.713-56.54 37.458 0 72.198 10.38 100.783 28.05 36.515-23.79 81.582-37.85 130.339-37.85 18.288 0 36.054 1.99 53.058 5.72v128.87H158.18h.01Z"
      fill="#506194"
    />
  </svg>
</template>
