<template>
  <svg viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="frameBtn4">
      <g
        @mouseenter="enterBtn4"
        @mouseleave="leaveBtn4"
        style="cursor: pointer"
      >
        <g id="Frame" clip-path="url(#clip0_256_1211)">
          <path
            id="borderBtn4"
            d="M373.64 164.21C361.75 100.98 306.13 53 239.5 53c-66.63 0-122.25 48-134.14 111.21-24.4421 10.589-45.2581 28.087-59.8899 50.346C30.8383 236.815 23.0284 262.863 23 289.5 23 364.77 84.23 426 159.5 426c28.747.022 56.759-9.081 80-26 23.241 16.919 51.253 26.022 80 26 75.27 0 136.5-61.23 136.5-136.5-.028-26.637-7.838-52.685-22.47-74.944-14.632-22.259-35.448-39.757-59.89-50.346Z"
            fill="#FFD600"
          />
          <g id="Group 232">
            <path
              id="Vector"
              d="m425.07 294.853-1.82 8.509c-1.629 3.423-3.956 6.468-6.83 8.94-1.57 1.41-3 2.53-4.08 3.42-.8.624-1.564 1.292-2.291 2-.28.95-.444 1.931-.49 2.92-1.41 16.32-9.76 25.799-25.521 28.999-5.481 1.1-6.351 2.34-8.471 5.35-1.195 1.773-2.533 3.445-4 5-5.38 5.54-21.261 18.41-52.623 18.41-9.869-.079-19.702-1.205-29.332-3.36-50.624-11.04-58.714-26.85-62.604-42.039-.54-2.11-2.001-3.36-5.691-6.14-3.86-2.89-8.66-6.49-11.661-12.92-.33-.71-.61-1.41-.86-2.1l-1.64-9.84v23c.098 3.658.942 7.259 2.48 10.58 3.001 6.419 7.801 9.999 11.661 12.919 3.7 2.77 5.15 4 5.691 6.13 3.92 15.2 12 30.949 62.634 41.939 9.634 2.18 19.474 3.33 29.352 3.43 31.362 0 47.243-12.88 52.623-18.41 1.467-1.555 2.805-3.227 4-4.999 2.12-3 3-4.25 8.471-5.35 15.761-3.16 24.111-12.63 25.521-28.95.048-.992.212-1.976.49-2.929.33-.37 1.411-1.24 2.291-1.95 1.12-.9 2.51-2 4.08-3.42 6.37-5.72 8.33-11.36 8.61-16.22l.01-22.919Z"
              fill="#1E8431"
            />
            <path
              id="Vector_2"
              d="M318.975 379.29c-9.869-.079-19.702-1.205-29.332-3.36-50.654-10.93-58.744-26.74-62.634-41.929-.54-2.11-2.001-3.36-5.691-6.14-3.86-2.89-8.66-6.49-11.661-12.92-3.91-8.38-2.33-16.319-1.29-21.569.313-1.36.533-2.74.66-4.13-.26-.71-.85-2-1.34-3.06-1.77-3.85-4.2-9.11-4.62-14.91-.63-8.559 3.2-15.789 10.761-20.359.584-.374 1.115-.825 1.58-1.34-.29-14.67 16.941-22.57 23.431-25.569 2.881-1.33 3.481-1.61 7.001-7.73 6.93-12.07 16.921-18.54 36.862-23.9 12.681-3.46 29.302-7.369 45.473-7.369 6.922-.076 13.822.789 20.511 2.569 19.002 5.3 25.822 6.89 28.722 7.56 7.341 1.72 15.651 9 19.772 17.22.53 1.05 1.84 2.07 4.3 3.94 3.429 2.485 6.617 5.287 9.521 8.37 6.37 6.879 8 18.329 8 18.589.31.49.8 1.23 1.19 1.81 4.11 6.14 12.651 18.92 4.23 30.069-1.41 1.87-1.64 7.33-.52 12.43 0 0 5.741 14.38-7.49 25.739-1.6 1.38-6.681 6.32-6.861 8.3-1.41 16.32-9.76 25.799-25.521 28.999-5.481 1.1-6.351 2.34-8.471 5.35-1.195 1.773-2.533 3.445-4 5-5.34 5.47-21.221 18.34-52.583 18.34Z"
              fill="#1FB118"
            />
            <path
              id="Vector_3"
              d="M219.148 260.282s7-3.579 6.79-10.909c-.21-7.33 12.411-13.3 17.211-15.52 4.8-2.22 6.951-3.75 11.751-12.11 4.8-8.359 11.421-13.809 30.462-18.919 19.041-5.11 42.203-9.89 60.544-4.82 18.341 5.07 25.491 6.82 29.132 7.68 3.64.86 9.77 5.62 12.751 11.59 2.98 5.969 8.77 7.499 15.551 14.839 6.78 7.34 4 11.76 6.13 15.85s11.251 14.329 6.62 20.459c-4.63 6.13-3.64 16.2-2.48 21.49 1.16 5.29 2.31 9.379-4.14 15.169-6.45 5.79-9.601 6-10.431 15.52-.83 9.52-4.3 16.709-17.051 19.269-12.751 2.56-13.401 8.69-17.871 13.3-4.47 4.61-25.162 22.329-72.335 12.1-47.173-10.23-51.803-23.19-54.613-34.099-2.81-10.91-13.911-12.11-18.051-21-4.141-8.89 2-17.559 0-23.999-2.001-6.44-11.551-18.9.03-25.89Z"
              fill="#44D13D"
            />
            <g id="Group" opacity=".1">
              <path
                id="Vector_4"
                d="M334.094 248.211v-21.56c0-8.8 8.001-15.269 16.291-13.179 8.131 1.999 16.631 4.239 18.781 4.759 2.555.598 4.803 2.11 6.321 4.25.228.32.435.654.62 1 3.1 6.21 7.66 9.66 11.331 12.44 2.323 1.661 4.486 3.535 6.46 5.6.823.908 1.329 2.059 1.44 3.28.197 3.348 1.075 6.622 2.58 9.619.47.9 2.13 3.45 3.551 5.67.6.934.979 1.992 1.109 3.094.13 1.102.007 2.219-.359 3.266-2.371 6.58-2.331 15.13-1.881 21.07.169 2.185-.146 4.38-.922 6.43s-1.994 3.904-3.568 5.429l-.16.15c-2.078 2.027-4.766 3.311-7.648 3.654-2.882.344-5.797-.273-8.293-1.754l-23.701-14.099c-6.727-4.069-12.285-9.811-16.132-16.667-3.847-6.856-5.852-14.591-5.82-22.452Z"
                fill="#2E2828"
              />
              <path
                id="Vector_5"
                d="M318.974 354.878c-8.227-.093-16.419-1.059-24.442-2.88-8.03-1.872-15.889-4.418-23.492-7.61-1.537-.667-2.861-1.743-3.829-3.112-.968-1.368-1.542-2.975-1.659-4.646-.118-1.672.226-3.344.992-4.834.767-1.49 1.928-2.741 3.356-3.617l29.122-17.31c6.634-3.966 14.218-6.06 21.947-6.06 7.729 0 15.313 2.094 21.946 6.06l17.551 10.43c1.201.735 2.217 1.736 2.97 2.926.753 1.189 1.223 2.536 1.374 3.935.15 1.4-.022 2.816-.505 4.138-.482 1.323-1.262 2.517-2.279 3.491-2.547 2.451-4.936 5.063-7.15 7.819-1.83 1.88-12.141 11.27-35.862 11.27h-.04Z"
                fill="#2E2828"
              />
              <path
                id="Vector_6"
                d="m235.969 308.468-.39-.33c-1.369-1.167-2.431-2.65-3.094-4.322-.662-1.672-.905-3.481-.706-5.268.57-5.25 1.08-12.7-.07-16.44l-.08-.27c-.904-3.001-1.11-6.168-.6-9.261.51-3.092 1.721-6.026 3.54-8.578 1.389-1.984 2.487-4.156 3.26-6.45 1.343-4.06 3.972-7.57 7.491-10 6.79-4.63 17.111-12.25 20.921-18.869 1.25-2.18 13.001-7.29 23.852-11.38 8.58-3.23 17.671 3.34 17.671 12.75v18.189c.034 7.873-1.975 15.62-5.832 22.483-3.856 6.864-9.428 12.61-16.17 16.676l-37.252 22.149c-1.943 1.146-4.195 1.658-6.443 1.465-2.248-.194-4.379-1.083-6.098-2.544Z"
                fill="#2E2828"
              />
            </g>
            <path
              id="Vector_7"
              d="M291.353 230.374c-1.073.659-1.968 1.572-2.608 2.657-.64 1.085-1.004 2.31-1.061 3.569-.057 1.258.194 2.512.733 3.651.538 1.138 1.347 2.128 2.356 2.883l7.001 5.16c.54.382 1.18.599 1.841.624.661.026 1.316-.14 1.884-.479.569-.339 1.027-.835 1.319-1.429.292-.594.405-1.259.326-1.916l-.81-8.85c-.085-1.245-.485-2.447-1.162-3.494-.678-1.047-1.61-1.904-2.711-2.491-1.1-.587-2.332-.885-3.578-.865-1.247.02-2.469.357-3.55.98h.02Z"
              fill="#44D13D"
            />
            <path
              id="Vector_8"
              d="M270.42 251.943c-.628 1.09-.975 2.319-1.011 3.576-.036 1.257.241 2.504.804 3.628.564 1.125 1.398 2.092 2.427 2.815 1.029.724 2.221 1.181 3.47 1.331l8.591.84c.662.044 1.323-.1 1.907-.416.583-.316 1.065-.791 1.39-1.37.324-.579.478-1.238.443-1.901-.035-.662-.257-1.302-.64-1.843l-5.001-7.26c-.697-1.032-1.648-1.868-2.762-2.425-1.114-.558-2.353-.819-3.597-.759-1.244.061-2.452.44-3.506 1.103-1.055.663-1.921 1.586-2.515 2.681Z"
              fill="#44D13D"
            />
            <path
              id="Vector_9"
              d="M262.75 282.28c-.029 1.25.26 2.487.84 3.595.579 1.108 1.43 2.05 2.474 2.74 1.043.689 2.244 1.103 3.49 1.201 1.247.099 2.498-.12 3.637-.636l7.85-3.71c.597-.293 1.1-.748 1.452-1.313.352-.565.538-1.217.538-1.882 0-.665-.186-1.317-.538-1.882-.352-.565-.855-1.02-1.452-1.313l-7.85-3.7c-1.139-.516-2.39-.735-3.637-.637-1.246.099-2.447.513-3.49 1.202-1.044.69-1.895 1.632-2.474 2.74-.58 1.108-.869 2.345-.84 3.595Z"
              fill="#44D13D"
            />
            <path
              id="Vector_10"
              d="M291.353 334.181c1.08.622 2.301.959 3.547.98 1.246.02 2.477-.276 3.577-.862s2.033-1.442 2.711-2.488c.678-1.046 1.079-2.247 1.166-3.49l.81-8.86c.081-.657-.031-1.324-.323-1.919-.292-.595-.75-1.092-1.32-1.43-.57-.339-1.226-.504-1.888-.475-.662.028-1.301.248-1.84.634l-7 5.15c-1.009.755-1.818 1.744-2.357 2.883-.538 1.139-.79 2.392-.733 3.651.057 1.258.422 2.484 1.061 3.569.64 1.085 1.536 1.998 2.609 2.657h-.02Z"
              fill="#44D13D"
            />
            <path
              id="Vector_11"
              d="M320.805 342.08c1.256-.031 2.485-.374 3.575-.999 1.091-.624 2.009-1.509 2.673-2.576.663-1.067 1.051-2.282 1.128-3.537.077-1.254-.158-2.507-.686-3.648l-3.59-8.08c-.259-.607-.691-1.126-1.242-1.49-.551-.364-1.197-.558-1.858-.558s-1.307.194-1.858.558c-.551.364-.983.883-1.242 1.49l-3.6 8.08c-.525 1.142-.759 2.395-.68 3.649.079 1.254.468 2.469 1.132 3.536.664 1.066 1.583 1.951 2.673 2.575 1.091.624 2.319.968 3.575 1Z"
              fill="#44D13D"
            />
            <path
              id="Vector_12"
              d="M350.248 334.181c1.074-.659 1.97-1.57 2.611-2.655.64-1.085 1.006-2.31 1.064-3.569.058-1.258-.193-2.512-.73-3.651-.538-1.139-1.347-2.13-2.355-2.885l-7.051-5.15c-.538-.382-1.176-.6-1.837-.627-.66-.027-1.314.139-1.882.477-.567.338-1.025.833-1.316 1.426-.292.593-.404 1.258-.325 1.914l.81 8.86c.084 1.245.483 2.447 1.161 3.495.678 1.047 1.611 1.904 2.712 2.49 1.101.587 2.333.883 3.58.861 1.247-.022 2.468-.361 3.548-.986h.01Z"
              fill="#44D13D"
            />
            <path
              id="Vector_13"
              d="M378.978 282.151c.029-1.25-.26-2.487-.839-3.595-.58-1.108-1.431-2.05-2.474-2.74-1.043-.689-2.244-1.103-3.491-1.201-1.246-.099-2.498.12-3.636.636l-7.851 3.7c-.597.294-1.1.748-1.452 1.313-.351.565-.538 1.217-.538 1.882 0 .665.187 1.317.538 1.882.352.565.855 1.02 1.452 1.313l7.851 3.71c1.138.516 2.39.735 3.636.637 1.247-.099 2.448-.513 3.491-1.202 1.043-.69 1.894-1.632 2.474-2.74.579-1.108.868-2.345.839-3.595Z"
              fill="#44D13D"
            />
            <path
              id="Vector_14"
              d="M371.187 251.943c-.594-1.097-1.46-2.022-2.516-2.686-1.056-.665-2.265-1.045-3.511-1.106-1.245-.06-2.486.202-3.601.761-1.115.559-2.066 1.397-2.763 2.431l-5 7.26c-.384.542-.606 1.181-.641 1.844-.035.662.119 1.321.444 1.9.324.579.806 1.054 1.39 1.37.583.316 1.245.461 1.907.416l8.6-.84c1.248-.152 2.439-.611 3.466-1.335 1.028-.723 1.86-1.69 2.424-2.814.563-1.123.84-2.369.805-3.625-.035-1.257-.38-2.485-1.004-3.576Z"
              fill="#44D13D"
            />
            <path
              id="Vector_15"
              d="M350.247 230.374c-1.081-.623-2.302-.96-3.549-.98-1.247-.02-2.479.278-3.579.865s-2.033 1.444-2.71 2.491c-.678 1.047-1.078 2.249-1.163 3.494l-.81 8.85c-.081.656.03 1.323.32 1.917.291.595.748 1.092 1.316 1.431.569.34 1.223.506 1.885.48.661-.027 1.3-.245 1.84-.628l7.05-5.16c1.009-.756 1.817-1.746 2.355-2.885.537-1.14.788-2.393.73-3.652-.058-1.258-.424-2.483-1.064-3.568-.641-1.085-1.537-1.997-2.611-2.655h-.01Z"
              fill="#44D13D"
            />
            <g id="Group_2" opacity=".1">
              <path
                id="Vector_16"
                d="M408.848 222.464H241.467c1.16-.94 2.22-2.5 4.37-6.24.908-1.598 1.91-3.141 3-4.62h147.95c.14.25.26.5.39.75.53 1.05 1.84 2.07 4.3 3.94 2.587 1.896 5.049 3.957 7.371 6.17Z"
                fill="#2E2828"
              />
              <path
                id="Vector_17"
                d="M425.91 255.002H208.676c1.527-1.592 3.259-2.974 5.15-4.11.584-.374 1.116-.824 1.58-1.34-.04-1.829.203-3.654.72-5.41h203.473c.2.31.411.62.601.9 2.219 3.129 4.131 6.465 5.71 9.96Z"
                fill="#2E2828"
              />
              <path
                id="Vector_18"
                d="M423.91 287.562H208.316c-.2-.46-.43-.95-.63-1.39-1.49-3.043-2.718-6.208-3.67-9.46H423.71c-.73 2.54-.69 6.81.2 10.85Z"
                fill="#2E2828"
              />
              <path
                id="Vector_19"
                d="M420.269 309.282c-1.149 1.457-2.439 2.796-3.851 4-2.335 2.029-4.429 4.321-6.24 6.83H212.895c-1.275-1.595-2.353-3.337-3.21-5.19-.845-1.808-1.479-3.707-1.89-5.66l212.474.02Z"
                fill="#2E2828"
              />
              <path
                id="Vector_20"
                d="M401.429 341.839c-4.19 4.34-10 7.23-17.391 8.72-2.92.59-4.53 1.21-5.71 2.13H236.659c-2.995-3.161-5.393-6.836-7.081-10.85h171.851Z"
                fill="#2E2828"
              />
            </g>
          </g>
          <g id="Group 231">
            <path
              id="Vector_21"
              d="m283.07 294.853-1.82 8.509c-1.629 3.423-3.956 6.468-6.83 8.94-1.57 1.41-3 2.53-4.08 3.42-.8.624-1.564 1.292-2.291 2-.28.95-.444 1.931-.49 2.92-1.41 16.32-9.76 25.799-25.521 28.999-5.481 1.1-6.351 2.34-8.471 5.35-1.195 1.773-2.533 3.445-4 5-5.38 5.54-21.261 18.41-52.623 18.41-9.869-.079-19.702-1.205-29.332-3.36-50.6237-11.04-58.7142-26.85-62.6045-42.039-.54-2.11-2.0001-3.36-5.6903-6.14-3.8603-2.89-8.6606-6.49-11.6608-12.92-.33-.71-.61-1.41-.86-2.1l-1.6402-9.84v23c.0981 3.658.942 7.259 2.4802 10.58 3.0002 6.419 7.8005 9.999 11.6608 12.919 3.7002 2.77 5.1503 4 5.6903 6.13 3.9203 15.2 12.0008 30.949 62.6345 41.939 9.634 2.18 19.474 3.33 29.352 3.43 31.362 0 47.243-12.88 52.623-18.41 1.467-1.555 2.805-3.227 4-4.999 2.12-3 3-4.25 8.471-5.35 15.761-3.16 24.111-12.63 25.521-28.95.048-.992.212-1.976.49-2.929.33-.37 1.411-1.24 2.291-1.95 1.12-.9 2.51-2 4.08-3.42 6.37-5.72 8.33-11.36 8.61-16.22l.01-22.919Z"
              fill="#1E8431"
            />
            <path
              id="Vector_22"
              d="M176.975 379.29c-9.869-.079-19.702-1.205-29.332-3.36-50.6537-10.93-58.7442-26.74-62.6344-41.929-.5401-2.11-2.0002-3.36-5.6904-6.14-3.8603-2.89-8.6606-6.49-11.6608-12.92-3.9102-8.38-2.3301-16.319-1.29-21.569.3125-1.36.533-2.74.66-4.13-.26-.71-.8501-2-1.3401-3.06-1.7701-3.85-4.2003-9.11-4.6203-14.91-.63-8.559 3.2002-15.789 10.7607-20.359.5842-.374 1.1157-.825 1.5801-1.34-.29-14.67 16.9411-22.57 23.4315-25.569 2.8802-1.33 3.4807-1.61 7.0007-7.73 6.93-12.07 16.921-18.54 36.862-23.9 12.681-3.46 29.302-7.369 45.473-7.369 6.922-.076 13.822.789 20.511 2.569 19.002 5.3 25.822 6.89 28.722 7.56 7.341 1.72 15.651 9 19.772 17.22.53 1.05 1.84 2.07 4.3 3.94 3.429 2.485 6.617 5.287 9.521 8.37 6.37 6.879 8 18.329 8 18.589.31.49.8 1.23 1.19 1.81 4.11 6.14 12.651 18.92 4.23 30.069-1.41 1.87-1.64 7.33-.52 12.43 0 0 5.741 14.38-7.49 25.739-1.6 1.38-6.681 6.32-6.861 8.3-1.41 16.32-9.76 25.799-25.521 28.999-5.481 1.1-6.351 2.34-8.471 5.35-1.195 1.773-2.533 3.445-4 5-5.34 5.47-21.221 18.34-52.583 18.34Z"
              fill="#1FB118"
            />
            <path
              id="Vector_23"
              d="M77.1476 260.282s7.0004-3.579 6.7904-10.909c-.21-7.33 12.4108-13.3 17.211-15.52 4.8-2.22 6.951-3.75 11.751-12.11 4.8-8.359 11.421-13.809 30.462-18.919 19.041-5.11 42.203-9.89 60.544-4.82 18.341 5.07 25.491 6.82 29.132 7.68 3.64.86 9.77 5.62 12.751 11.59 2.98 5.969 8.77 7.499 15.551 14.839 6.78 7.34 4 11.76 6.13 15.85s11.251 14.329 6.62 20.459c-4.63 6.13-3.64 16.2-2.48 21.49 1.16 5.29 2.31 9.379-4.14 15.169-6.45 5.79-9.601 6-10.431 15.52-.83 9.52-4.3 16.709-17.051 19.269-12.751 2.56-13.401 8.69-17.871 13.3-4.47 4.61-25.162 22.329-72.335 12.1-47.173-10.23-51.8031-23.19-54.6132-34.099-2.8102-10.91-13.911-12.11-18.0512-21-4.1403-8.89 2.0001-17.559 0-23.999-2.0002-6.44-11.5508-18.9.03-25.89Z"
              fill="#44D13D"
            />
            <g id="Group_3" opacity=".1">
              <path
                id="Vector_24"
                d="M192.094 248.211v-21.56c0-8.8 8.001-15.269 16.291-13.179 8.131 1.999 16.631 4.239 18.781 4.759 2.555.598 4.803 2.11 6.321 4.25.228.32.435.654.62 1 3.1 6.21 7.66 9.66 11.331 12.44 2.323 1.661 4.486 3.535 6.46 5.6.823.908 1.329 2.059 1.44 3.28.197 3.348 1.075 6.622 2.58 9.619.47.9 2.13 3.45 3.551 5.67.6.934.979 1.992 1.109 3.094.13 1.102.007 2.219-.359 3.266-2.371 6.58-2.331 15.13-1.881 21.07.169 2.185-.146 4.38-.922 6.43s-1.994 3.904-3.568 5.429l-.16.15c-2.078 2.027-4.766 3.311-7.648 3.654-2.882.344-5.797-.273-8.293-1.754l-23.701-14.099c-6.727-4.069-12.285-9.811-16.132-16.667-3.847-6.856-5.852-14.591-5.82-22.452Z"
                fill="#2E2828"
              />
              <path
                id="Vector_25"
                d="M176.974 354.878c-8.227-.093-16.419-1.059-24.442-2.88-8.03-1.872-15.889-4.418-23.492-7.61-1.537-.667-2.861-1.743-3.829-3.112-.968-1.368-1.542-2.975-1.659-4.646-.118-1.672.226-3.344.992-4.834.767-1.49 1.928-2.741 3.356-3.617l29.122-17.31c6.634-3.966 14.218-6.06 21.947-6.06 7.729 0 15.313 2.094 21.946 6.06l17.551 10.43c1.201.735 2.217 1.736 2.97 2.926.753 1.189 1.223 2.536 1.374 3.935.15 1.4-.022 2.816-.505 4.138-.482 1.323-1.262 2.517-2.279 3.491-2.547 2.451-4.936 5.063-7.15 7.819-1.83 1.88-12.141 11.27-35.862 11.27h-.04Z"
                fill="#2E2828"
              />
              <path
                id="Vector_26"
                d="m93.9691 308.468-.39-.33c-1.3692-1.167-2.4315-2.65-3.0941-4.322-.6625-1.672-.905-3.481-.7062-5.268.5701-5.25 1.0801-12.7-.07-16.44l-.08-.27c-.9042-3.001-1.1093-6.168-.5997-9.261.5097-3.092 1.7206-6.026 3.5399-8.578 1.389-1.984 2.4869-4.156 3.2602-6.45 1.3424-4.06 3.9722-7.57 7.4908-10 6.79-4.63 17.111-12.25 20.921-18.869 1.25-2.18 13.001-7.29 23.852-11.38 8.58-3.23 17.671 3.34 17.671 12.75v18.189c.034 7.873-1.975 15.62-5.832 22.483-3.856 6.864-9.428 12.61-16.17 16.676l-37.252 22.149c-1.943 1.146-4.195 1.658-6.443 1.465-2.2478-.194-4.3792-1.083-6.0979-2.544Z"
                fill="#2E2828"
              />
            </g>
            <path
              id="Vector_27"
              d="M149.353 230.374c-1.073.659-1.968 1.572-2.608 2.657-.64 1.085-1.004 2.31-1.061 3.569-.057 1.258.194 2.512.733 3.651.538 1.138 1.347 2.128 2.356 2.883l7.001 5.16c.54.382 1.18.599 1.841.624.661.026 1.316-.14 1.884-.479.569-.339 1.027-.835 1.319-1.429.292-.594.405-1.259.326-1.916l-.81-8.85c-.085-1.245-.485-2.447-1.162-3.494-.678-1.047-1.61-1.904-2.711-2.491-1.1-.587-2.332-.885-3.578-.865-1.247.02-2.469.357-3.55.98h.02Z"
              fill="#44D13D"
            />
            <path
              id="Vector_28"
              d="M128.42 251.943c-.628 1.09-.975 2.319-1.011 3.576-.036 1.257.241 2.504.804 3.628.564 1.125 1.398 2.092 2.427 2.815 1.029.724 2.221 1.181 3.47 1.331l8.591.84c.662.044 1.323-.1 1.907-.416.583-.316 1.065-.791 1.39-1.37.324-.579.478-1.238.443-1.901-.035-.662-.257-1.302-.64-1.843l-5.001-7.26c-.697-1.032-1.648-1.868-2.762-2.425-1.114-.558-2.353-.819-3.597-.759-1.244.061-2.452.44-3.506 1.103-1.055.663-1.921 1.586-2.515 2.681Z"
              fill="#44D13D"
            />
            <path
              id="Vector_29"
              d="M120.75 282.28c-.029 1.25.26 2.487.84 3.595.579 1.108 1.43 2.05 2.474 2.74 1.043.689 2.244 1.103 3.49 1.201 1.247.099 2.498-.12 3.637-.636l7.85-3.71c.597-.293 1.1-.748 1.452-1.313.352-.565.538-1.217.538-1.882 0-.665-.186-1.317-.538-1.882-.352-.565-.855-1.02-1.452-1.313l-7.85-3.7c-1.139-.516-2.39-.735-3.637-.637-1.246.099-2.447.513-3.49 1.202-1.044.69-1.895 1.632-2.474 2.74-.58 1.108-.869 2.345-.84 3.595Z"
              fill="#44D13D"
            />
            <path
              id="Vector_30"
              d="M149.353 334.181c1.08.622 2.301.959 3.547.98 1.246.02 2.477-.276 3.577-.862s2.033-1.442 2.711-2.488c.678-1.046 1.079-2.247 1.166-3.49l.81-8.86c.081-.657-.031-1.324-.323-1.919-.292-.595-.75-1.092-1.32-1.43-.57-.339-1.226-.504-1.888-.475-.662.028-1.301.248-1.84.634l-7 5.15c-1.009.755-1.818 1.744-2.357 2.883-.538 1.139-.79 2.392-.733 3.651.057 1.258.422 2.484 1.061 3.569.64 1.085 1.536 1.998 2.609 2.657h-.02Z"
              fill="#44D13D"
            />
            <path
              id="Vector_31"
              d="M178.805 342.08c1.256-.031 2.485-.374 3.575-.999 1.091-.624 2.009-1.509 2.673-2.576.663-1.067 1.051-2.282 1.128-3.537.077-1.254-.158-2.507-.686-3.648l-3.59-8.08c-.259-.607-.691-1.126-1.242-1.49-.551-.364-1.197-.558-1.858-.558s-1.307.194-1.858.558c-.551.364-.983.883-1.242 1.49l-3.6 8.08c-.525 1.142-.759 2.395-.68 3.649.079 1.254.468 2.469 1.132 3.536.664 1.066 1.583 1.951 2.673 2.575 1.091.624 2.319.968 3.575 1Z"
              fill="#44D13D"
            />
            <path
              id="Vector_32"
              d="M208.248 334.181c1.074-.659 1.97-1.57 2.611-2.655.64-1.085 1.006-2.31 1.064-3.569.058-1.258-.193-2.512-.73-3.651-.538-1.139-1.347-2.13-2.355-2.885l-7.051-5.15c-.538-.382-1.176-.6-1.837-.627-.66-.027-1.314.139-1.882.477-.567.338-1.025.833-1.316 1.426-.292.593-.404 1.258-.325 1.914l.81 8.86c.084 1.245.483 2.447 1.161 3.495.678 1.047 1.611 1.904 2.712 2.49 1.101.587 2.333.883 3.58.861 1.247-.022 2.468-.361 3.548-.986h.01Z"
              fill="#44D13D"
            />
            <path
              id="Vector_33"
              d="M236.978 282.151c.029-1.25-.26-2.487-.839-3.595-.58-1.108-1.431-2.05-2.474-2.74-1.043-.689-2.244-1.103-3.491-1.201-1.246-.099-2.498.12-3.636.636l-7.851 3.7c-.597.294-1.1.748-1.452 1.313-.351.565-.538 1.217-.538 1.882 0 .665.187 1.317.538 1.882.352.565.855 1.02 1.452 1.313l7.851 3.71c1.138.516 2.39.735 3.636.637 1.247-.099 2.448-.513 3.491-1.202 1.043-.69 1.894-1.632 2.474-2.74.579-1.108.868-2.345.839-3.595Z"
              fill="#44D13D"
            />
            <path
              id="Vector_34"
              d="M229.187 251.943c-.594-1.097-1.46-2.022-2.516-2.686-1.056-.665-2.265-1.045-3.511-1.106-1.245-.06-2.486.202-3.601.761-1.115.559-2.066 1.397-2.763 2.431l-5 7.26c-.384.542-.606 1.181-.641 1.844-.035.662.119 1.321.444 1.9.324.579.806 1.054 1.39 1.37.583.316 1.245.461 1.907.416l8.6-.84c1.248-.152 2.439-.611 3.466-1.335 1.028-.723 1.86-1.69 2.424-2.814.563-1.123.84-2.369.805-3.625-.035-1.257-.38-2.485-1.004-3.576Z"
              fill="#44D13D"
            />
            <path
              id="Vector_35"
              d="M208.247 230.374c-1.081-.623-2.302-.96-3.549-.98-1.247-.02-2.479.278-3.579.865s-2.033 1.444-2.71 2.491c-.678 1.047-1.078 2.249-1.163 3.494l-.81 8.85c-.081.656.03 1.323.32 1.917.291.595.748 1.092 1.316 1.431.569.34 1.223.506 1.885.48.661-.027 1.3-.245 1.84-.628l7.05-5.16c1.009-.756 1.817-1.746 2.355-2.885.537-1.14.788-2.393.73-3.652-.058-1.258-.424-2.483-1.064-3.568-.641-1.085-1.537-1.997-2.611-2.655h-.01Z"
              fill="#44D13D"
            />
            <g id="Group_4" opacity=".1">
              <path
                id="Vector_36"
                d="M266.848 222.464H99.4668c1.1602-.94 2.2202-2.5 4.3702-6.24.908-1.598 1.91-3.141 3-4.62h147.95c.14.25.26.5.39.75.53 1.05 1.84 2.07 4.3 3.94 2.587 1.896 5.049 3.957 7.371 6.17Z"
                fill="#2E2828"
              />
              <path
                id="Vector_37"
                d="M283.91 255.002H66.6758c1.5269-1.592 3.2589-2.974 5.1503-4.11.5842-.374 1.1157-.824 1.5801-1.34-.0405-1.829.2024-3.654.7201-5.41H277.599c.2.31.411.62.601.9 2.219 3.129 4.131 6.465 5.71 9.96Z"
                fill="#2E2828"
              />
              <path
                id="Vector_38"
                d="M281.91 287.562H66.3159c-.2-.46-.43-.95-.63-1.39-1.4899-3.043-2.7178-6.208-3.6703-9.46H281.71c-.73 2.54-.69 6.81.2 10.85Z"
                fill="#2E2828"
              />
              <path
                id="Vector_39"
                d="M278.269 309.282c-1.149 1.457-2.439 2.796-3.851 4-2.335 2.029-4.429 4.321-6.24 6.83H70.8953c-1.2751-1.595-2.3529-3.337-3.2103-5.19-.8452-1.808-1.4794-3.707-1.8901-5.66l212.4741.02Z"
                fill="#2E2828"
              />
              <path
                id="Vector_40"
                d="M259.429 341.839c-4.19 4.34-10 7.23-17.391 8.72-2.92.59-4.53 1.21-5.71 2.13H94.6586c-2.9943-3.161-5.393-6.836-7.0805-10.85H259.429Z"
                fill="#2E2828"
              />
            </g>
          </g>
          <g id="Group 230">
            <g id="Group 236">
              <path
                id="Vector_41"
                d="M242.975 271.786c-9.869-.079-19.702-1.205-29.332-3.36-50.654-10.93-58.744-26.74-62.634-41.929-.54-2.11-2.001-3.36-5.691-6.14-3.86-2.89-8.66-6.49-11.661-12.92-3.91-8.379-2.33-16.319-1.29-21.569.313-1.36.533-2.74.66-4.13-.26-.71-.85-2-1.34-3.06-1.77-3.85-4.2-9.11-4.62-14.91-.63-8.559 3.2-15.789 10.761-20.359.584-.374 1.115-.825 1.58-1.34-.29-14.67 16.941-22.569 23.431-25.569 2.881-1.33 3.481-1.61 7.001-7.73 6.93-12.0699 16.921-18.5397 36.862-23.8996 12.681-3.4599 29.302-7.3698 45.473-7.3698 6.922-.0752 13.822.7894 20.511 2.5699 19.002 5.2999 25.822 6.8899 28.722 7.5598 7.341 1.72 15.651 8.9998 19.772 17.2197.53 1.05 1.84 2.07 4.3 3.94 3.429 2.485 6.617 5.287 9.521 8.37 6.37 6.879 8 18.329 8 18.589.31.49.8 1.23 1.19 1.81 4.11 6.14 12.651 18.92 4.23 30.069-1.41 1.87-1.64 7.33-.52 12.43 0 0 5.741 14.38-7.49 25.739-1.6 1.38-6.681 6.32-6.861 8.3-1.41 16.32-9.76 25.799-25.521 28.999-5.481 1.1-6.351 2.34-8.471 5.35-1.195 1.773-2.533 3.445-4 5-5.34 5.47-21.221 18.34-52.583 18.34Z"
                fill="#1FB118"
              />
              <path
                id="Vector_42"
                d="M143.148 152.779s7-3.58 6.79-10.91c-.21-7.33 12.411-13.3 17.211-15.52 4.8-2.219 6.951-3.749 11.751-12.109s11.421-13.81 30.462-18.9198c19.041-5.1098 42.203-9.8897 60.544-4.8198 18.341 5.0698 25.491 6.8198 29.132 7.6798 3.64.8599 9.77 5.6198 12.751 11.5898 2.98 5.97 8.77 7.5 15.551 14.839 6.78 7.34 4 11.76 6.13 15.85s11.251 14.33 6.62 20.46c-4.63 6.129-3.64 16.199-2.48 21.489 1.16 5.29 2.31 9.38-4.14 15.17-6.45 5.789-9.601 5.999-10.431 15.519-.83 9.52-4.3 16.71-17.051 19.27-12.751 2.56-13.401 8.69-17.871 13.299-4.47 4.61-25.162 22.33-72.335 12.1-47.173-10.23-51.803-23.189-54.613-34.099-2.81-10.91-13.911-12.11-18.051-20.999-4.141-8.89 2-17.56 0-24-2.001-6.44-11.551-18.899.03-25.889Z"
                fill="#44D13D"
              />
              <g id="Group_5" opacity=".1">
                <path
                  id="Vector_43"
                  d="M258.094 140.708v-21.559c0-8.8 8.001-15.27 16.291-13.18 8.131 2 16.631 4.24 18.781 4.76 2.555.598 4.803 2.109 6.321 4.25.228.32.435.654.62 1 3.1 6.21 7.66 9.66 11.331 12.44 2.323 1.66 4.486 3.535 6.46 5.599.823.909 1.329 2.06 1.44 3.28.197 3.349 1.075 6.622 2.58 9.62.47.9 2.13 3.45 3.551 5.67.6.933.979 1.991 1.109 3.093.13 1.102.007 2.219-.359 3.267-2.371 6.58-2.331 15.129-1.881 21.069.169 2.185-.146 4.381-.922 6.431s-1.994 3.903-3.568 5.429l-.16.15c-2.078 2.026-4.766 3.31-7.648 3.654-2.882.343-5.797-.273-8.293-1.754l-23.701-14.1c-6.727-4.069-12.285-9.811-16.132-16.666-3.847-6.856-5.852-14.592-5.82-22.453Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_44"
                  d="M242.974 247.376c-8.227-.094-16.419-1.059-24.442-2.88-8.03-1.873-15.889-4.418-23.492-7.61-1.537-.668-2.861-1.744-3.829-3.112s-1.542-2.975-1.659-4.647c-.118-1.671.226-3.343.992-4.833.767-1.49 1.928-2.741 3.356-3.618l29.122-17.309c6.634-3.966 14.218-6.061 21.947-6.061 7.729 0 15.313 2.095 21.946 6.061l17.551 10.43c1.201.735 2.217 1.736 2.97 2.925.753 1.19 1.223 2.536 1.374 3.936.15 1.4-.022 2.815-.505 4.138-.482 1.322-1.262 2.516-2.279 3.49-2.547 2.452-4.936 5.064-7.15 7.82-1.83 1.88-12.141 11.27-35.862 11.27h-.04Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_45"
                  d="m159.969 200.965-.39-.33c-1.369-1.166-2.431-2.65-3.094-4.322-.662-1.672-.905-3.48-.706-5.268.57-5.25 1.08-12.699-.07-16.439l-.08-.27c-.904-3.001-1.11-6.169-.6-9.261.51-3.092 1.721-6.027 3.54-8.579 1.389-1.983 2.487-4.155 3.26-6.45 1.343-4.059 3.972-7.57 7.491-9.999 6.79-4.63 17.111-12.25 20.921-18.87 1.25-2.18 13.001-7.29 23.852-11.38 8.58-3.23 17.671 3.34 17.671 12.75v18.19c.034 7.872-1.975 15.619-5.832 22.483-3.856 6.864-9.428 12.61-16.17 16.676l-37.252 22.149c-1.943 1.146-4.195 1.658-6.443 1.464-2.248-.193-4.379-1.083-6.098-2.544Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_46"
                  d="M215.353 122.871c-1.073.659-1.968 1.572-2.608 2.657-.64 1.085-1.004 2.311-1.061 3.569-.057 1.259.194 2.512.733 3.651.538 1.139 1.347 2.128 2.356 2.883l7.001 5.16c.54.382 1.18.599 1.841.625.661.025 1.316-.141 1.884-.48.569-.339 1.027-.835 1.319-1.429.292-.594.405-1.259.326-1.916l-.81-8.85c-.085-1.244-.485-2.447-1.162-3.493-.678-1.047-1.61-1.905-2.711-2.492-1.1-.587-2.332-.884-3.578-.864-1.247.02-2.469.357-3.55.979h.02Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_47"
                  d="M194.42 144.44c-.628 1.091-.975 2.319-1.011 3.577-.036 1.257.241 2.504.804 3.628.564 1.124 1.398 2.092 2.427 2.815 1.029.723 2.221 1.18 3.47 1.33l8.591.84c.662.045 1.323-.099 1.907-.415.583-.317 1.065-.792 1.39-1.37.324-.579.478-1.238.443-1.901-.035-.663-.257-1.302-.64-1.844l-5.001-7.26c-.697-1.032-1.648-1.867-2.762-2.425-1.114-.557-2.353-.818-3.597-.758-1.244.06-2.452.44-3.506 1.103-1.055.662-1.921 1.586-2.515 2.68Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_48"
                  d="M186.75 174.774c-.029 1.25.26 2.487.84 3.595.579 1.108 1.43 2.051 2.474 2.74 1.043.69 2.244 1.103 3.49 1.202 1.247.099 2.498-.12 3.637-.637l7.85-3.71c.597-.293 1.1-.748 1.452-1.313.352-.564.538-1.216.538-1.882 0-.665-.186-1.317-.538-1.881-.352-.565-.855-1.02-1.452-1.314l-7.85-3.7c-1.139-.516-2.39-.735-3.637-.636-1.246.099-2.447.512-3.49 1.202-1.044.689-1.895 1.632-2.474 2.74-.58 1.108-.869 2.345-.84 3.594Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_49"
                  d="M215.353 226.677c1.08.622 2.301.959 3.547.979 1.246.021 2.477-.276 3.577-.862s2.033-1.442 2.711-2.488c.678-1.045 1.079-2.246 1.166-3.489l.81-8.86c.081-.658-.031-1.325-.323-1.92-.292-.594-.75-1.091-1.32-1.43-.57-.338-1.226-.503-1.888-.475-.662.028-1.301.249-1.84.635l-7 5.15c-1.009.754-1.818 1.744-2.357 2.883-.538 1.139-.79 2.392-.733 3.65.057 1.259.422 2.484 1.061 3.569.64 1.086 1.536 1.998 2.609 2.658h-.02Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_50"
                  d="M244.805 234.576c1.256-.032 2.485-.375 3.575-.999 1.091-.624 2.009-1.509 2.673-2.576.663-1.068 1.051-2.283 1.128-3.537.077-1.254-.158-2.508-.686-3.648l-3.59-8.08c-.259-.608-.691-1.126-1.242-1.49-.551-.364-1.197-.558-1.858-.558s-1.307.194-1.858.558c-.551.364-.983.882-1.242 1.49l-3.6 8.08c-.525 1.141-.759 2.395-.68 3.649.079 1.254.468 2.469 1.132 3.535.664 1.067 1.583 1.952 2.673 2.576 1.091.624 2.319.967 3.575 1Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_51"
                  d="M274.248 226.677c1.074-.659 1.97-1.571 2.611-2.656.64-1.084 1.006-2.31 1.064-3.568.058-1.259-.193-2.512-.73-3.651-.538-1.14-1.347-2.13-2.355-2.885l-7.051-5.15c-.538-.383-1.176-.601-1.837-.627-.66-.027-1.314.139-1.882.476-.567.338-1.025.834-1.316 1.427-.292.593-.404 1.258-.325 1.914l.81 8.86c.084 1.244.483 2.447 1.161 3.494.678 1.047 1.611 1.905 2.712 2.491 1.101.586 2.333.883 3.58.861 1.247-.022 2.468-.362 3.548-.986h.01Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_52"
                  d="M302.978 174.646c.029-1.25-.26-2.487-.839-3.595-.58-1.108-1.431-2.051-2.474-2.74-1.043-.69-2.244-1.103-3.491-1.202-1.246-.099-2.498.12-3.636.637l-7.851 3.7c-.597.293-1.1.748-1.452 1.313-.351.564-.538 1.216-.538 1.882 0 .665.187 1.317.538 1.882.352.564.855 1.019 1.452 1.313l7.851 3.71c1.138.516 2.39.735 3.636.636 1.247-.099 2.448-.512 3.491-1.202 1.043-.689 1.894-1.632 2.474-2.74.579-1.108.868-2.344.839-3.594Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_53"
                  d="M295.187 144.441c-.594-1.097-1.46-2.022-2.516-2.687-1.056-.664-2.265-1.045-3.511-1.105-1.245-.06-2.486.202-3.601.761-1.115.559-2.066 1.396-2.763 2.431l-5 7.26c-.384.541-.606 1.181-.641 1.843-.035.663.119 1.322.444 1.901.324.579.806 1.054 1.39 1.37.583.316 1.245.46 1.907.416l8.6-.84c1.248-.152 2.439-.611 3.466-1.335 1.028-.724 1.86-1.691 2.424-2.814.563-1.124.84-2.369.805-3.626-.035-1.256-.38-2.484-1.004-3.575Z"
                  fill="#44D13D"
                />
                <path
                  id="Vector_54"
                  d="M274.247 122.871c-1.081-.622-2.302-.959-3.549-.979-1.247-.02-2.479.277-3.579.864s-2.033 1.445-2.71 2.492c-.678 1.046-1.078 2.249-1.163 3.493l-.81 8.85c-.081.656.03 1.323.32 1.917.291.595.748 1.092 1.316 1.432.569.339 1.223.505 1.885.479.661-.026 1.3-.245 1.84-.628l7.05-5.16c1.009-.755 1.817-1.746 2.355-2.885.537-1.139.788-2.393.73-3.652-.058-1.258-.424-2.483-1.064-3.568-.641-1.085-1.537-1.996-2.611-2.655h-.01Z"
                  fill="#44D13D"
                />
              </g>
              <g id="Group_6" opacity=".1">
                <path
                  id="Vector_55"
                  d="M332.848 114.962H165.467c1.16-.94 2.22-2.5 4.37-6.24.908-1.598 1.91-3.141 3-4.62h147.95c.14.25.26.5.39.75.53 1.05 1.84 2.07 4.3 3.94 2.587 1.895 5.049 3.956 7.371 6.17Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_56"
                  d="M349.91 147.5H132.676c1.527-1.592 3.259-2.975 5.15-4.11.584-.374 1.116-.825 1.58-1.34-.04-1.83.203-3.655.72-5.41h203.473c.2.31.411.62.601.9 2.219 3.129 4.131 6.464 5.71 9.96Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_57"
                  d="M347.91 180.059H132.316c-.2-.46-.43-.95-.63-1.39-1.49-3.043-2.718-6.208-3.67-9.46H347.71c-.73 2.54-.69 6.81.2 10.85Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_58"
                  d="M344.269 201.779c-1.149 1.457-2.439 2.797-3.851 4-2.335 2.03-4.429 4.322-6.24 6.83H136.895c-1.275-1.594-2.353-3.337-3.21-5.19-.845-1.808-1.479-3.707-1.89-5.66l212.474.02Z"
                  fill="#2E2828"
                />
                <path
                  id="Vector_59"
                  d="M325.429 234.337c-4.19 4.34-10 7.23-17.391 8.72-2.92.59-4.53 1.21-5.71 2.13H160.659c-2.995-3.161-5.393-6.837-7.081-10.85h171.851Z"
                  fill="#2E2828"
                />
              </g>
              <path
                id="Vector_60"
                d="m349.07 187.348-1.82 8.509c-1.629 3.423-3.956 6.468-6.83 8.94-1.57 1.41-3 2.53-4.08 3.42-.8.624-1.564 1.292-2.291 2-.28.95-.444 1.931-.49 2.92-1.41 16.32-9.76 25.799-25.521 28.999-5.481 1.1-6.351 2.34-8.471 5.35-1.195 1.773-2.533 3.445-4 5-5.38 5.54-21.261 18.41-52.623 18.41-9.869-.079-19.702-1.205-29.332-3.36-50.624-11.04-58.714-26.85-62.604-42.039-.54-2.11-2.001-3.36-5.691-6.14-3.86-2.89-8.66-6.49-11.661-12.92-.33-.71-.61-1.41-.86-2.1l-1.64-9.84v23c.098 3.658.942 7.259 2.48 10.58 3.001 6.419 7.801 9.999 11.661 12.919 3.7 2.77 5.15 4 5.691 6.13 3.92 15.2 12 30.949 62.634 41.939 9.634 2.18 19.474 3.33 29.352 3.43 31.362 0 47.243-12.88 52.623-18.41 1.467-1.555 2.805-3.227 4-4.999 2.12-3 3-4.25 8.471-5.35 15.761-3.16 24.111-12.63 25.521-28.95.048-.992.212-1.976.49-2.929.33-.37 1.411-1.24 2.291-1.95 1.12-.9 2.51-2 4.08-3.42 6.37-5.72 8.33-11.36 8.61-16.22l.01-22.919Z"
                fill="#1E8431"
              />
            </g>
            <g id="hover_eyes4">
              <path
                id="hover_eyes_2"
                d="M267.286 177.249c11.047 0 20.002-8.954 20.002-20 0-11.045-8.955-19.999-20.002-19.999-11.046 0-20.001 8.954-20.001 19.999 0 11.046 8.955 20 20.001 20Z"
                fill="white"
              />
              <path
                id="hover_eyes_3"
                d="M273.956 170.582c2.636 0 5.214-.782 7.406-2.247 2.192-1.464 3.901-3.546 4.91-5.982 1.009-2.436 1.273-5.116.759-7.702-.515-2.585-1.784-4.961-3.649-6.825-1.864-1.864-4.239-3.134-6.825-3.648-2.586-.514-5.267-.25-7.703.759-2.436 1.008-4.518 2.717-5.982 4.909-1.465 2.192-2.247 4.769-2.247 7.406-.001 1.751.343 3.485 1.012 5.103.67 1.617 1.651 3.087 2.89 4.326 1.238 1.238 2.708 2.219 4.326 2.889 1.618.669 3.352 1.013 5.103 1.012Z"
                fill="#1E8431"
              />
              <path
                id="hover_eyes_4"
                d="m202.521 171.539 30.002-10.92-30.002-10.92"
                stroke="#1E8431"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                id="hover_eyes_5"
                d="M271.055 187.266c-.094 3.405-1.513 6.639-3.955 9.014-2.442 2.375-5.714 3.704-9.121 3.704-3.406 0-6.678-1.329-9.12-3.704-2.442-2.375-3.861-5.609-3.955-9.014-.095 3.405-1.513 6.639-3.955 9.014-2.442 2.375-5.715 3.704-9.121 3.704-3.407 0-6.679-1.329-9.121-3.704s-3.861-5.609-3.955-9.014"
                stroke="#1E8431"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        id="text4"
        d="M132.021 213.944h3.84V211h-16.448v24.512c0 4.224 2.816 7.104 7.04 7.104h6.976c2.048 0 4.48-.448 6.272-1.856 1.216 1.152 2.944 1.856 4.992 1.856h6.976c2.176 0 5.952-.448 8.064-1.792v1.792h12.928v-14.08h4.992v-3.52h-4.992V211h-12.928v26.688c-1.984 1.344-5.696 1.792-7.68 1.792-1.024 0-1.728-.704-1.728-1.792v-23.744h6.72V211h-19.392v24.512c0 1.152.192 2.176.576 3.072-1.408.64-3.008.896-4.608.896-1.024 0-1.6-.704-1.6-1.792v-23.744Zm-12.352 34.816h38.848v16h14.144v-17.152c0-1.088-.704-1.792-1.728-1.792h-51.264v2.944Zm65.429-16.384h14.144v-.064l12.928-6.528 12.928 6.592h14.145V211h-14.145v5.184l-12.928 6.592-12.928-6.528V211h-14.144v21.376Zm29.952-8.128 10.048-5.12v10.304l-10.048-5.184Zm-15.808 5.12v-10.176l10.048 5.056-10.048 5.12Zm-14.144 29.248h.064c.384 3.712 3.072 6.144 6.976 6.144h47.105v-2.944H200.97c-1.024 0-1.728-.768-1.728-1.792v-4.672h39.744v-2.944h-39.744v-5.888h40.001v-2.944H219.53v-4.864h20.289v-2.944h-55.297v2.944h20.288v4.864h-19.712v15.04Zm103.638 6.08h14.144v-53.76h-14.144v24.384h-3.328c-.576-17.024-6.976-25.024-18.624-25.024-12.16 0-18.624 8.768-18.624 27.52s6.464 27.52 18.624 27.52c11.968 0 18.432-8.448 18.688-26.432h3.264v25.792Zm-21.952-1.728c-3.584 0-4.544-5.056-4.544-25.152s.96-25.152 4.544-25.152c3.584 0 4.544 5.056 4.544 25.152s-.96 25.152-4.544 25.152Zm43.222.576c4.864 0 9.664-1.856 13.248-5.12.832 3.136 3.456 5.12 7.104 5.12 4.352 0 7.296-3.008 7.296-7.424 0-4.352-2.944-7.36-7.296-7.36-.512 0-1.024.064-1.536.128.256-1.216.448-2.56.448-3.904V221.56h7.296v-2.944h-7.296V211h-11.776v7.616h-6.336v2.944h6.336v23.424c0 10.24-2.816 16.192-7.488 16.192v2.368Zm29.12.576h12.416V211h-12.416v23.616h-6.912v3.584h6.912v25.92Zm14.464.64h12.928V211H353.59v53.76Z"
        fill="#2E2828"
      />
    </g>
    <defs>
      <clipPath id="clip0_256_1211">
        <path fill="white" d="M23 53h433v373H23z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup>
import { defineEmits } from "vue";
import { gsap } from "gsap";

const emit = defineEmits(["btn4Entered", "btn4Leaved"]);

const enterBtn4 = () => {
  emit("btn4Entered");
  gsap.to(["#borderBtn4", "#hover_eyes4"], {
    duration: 0.2,
    opacity: 1,
  });
  gsap.to(
    ["#frameBtn1", "#frameBtn2", "#frameBtn3", "#frameBtn5", "#frameBtn6"],
    {
      duration: 0.2,
      opacity: 0.2,
    }
  );
  gsap.to("#text4", {
    duration: 0.2,
    opacity: 0,
  });
};
const leaveBtn4 = () => {
  emit("btn4Leaved");
  gsap.to(["#borderBtn4", "#hover_eyes4"], {
    duration: 0.2,
    opacity: 0,
  });
  gsap.to(
    ["#frameBtn1", "#frameBtn2", "#frameBtn3", "#frameBtn5", "#frameBtn6"],
    {
      duration: 0.2,
      opacity: 1,
    }
  );
  gsap.to("#text4", {
    duration: 0.2,
    opacity: 1,
  });
};
</script>

<style lang="scss" scoped>
#borderBtn4,
#hover_eyes4 {
  opacity: 0;
}
#text4 {
  pointer-events: none;
}
</style>
