<template>
  <div class="container" @mousemove="moveMouseText">
    <div class="backgroundImgWrapper">
      <img class="star1" src="@/assets/star1.png" />
      <img class="star2" src="@/assets/star2.png" />
      <img class="star3" src="@/assets/star3.png" />
      <Monster ref="monsterRef" class="monster" />
      <IntroCaption class="introCaption" />
      <Ball1 class="ball1" />
      <Ball2 class="ball2" />
      <Planet class="planet" />
      <Satellite class="satellite" />
      <SpaceBurger ref="spaceBurgerRef" class="spaceburger" />
      <CloudL class="cloudL" />
      <CloudR class="cloudR" />
      <Moon class="moon" />
      <IntroArrow class="arrow" />
      <div class="twinkleWrapper">
        <Twinkle :ref="twinkleRef" v-for="twinkle in 3" :key="twinkle.id" />
      </div>
    </div>
    <div class="textWrapper">
      <img ref="textRef1" class="text1" src="@/assets/text1.webp" />
      <img ref="textRef2" class="text2" src="@/assets/text2.webp" />
      <img ref="textRef3" class="text3" src="@/assets/text3.webp" />
    </div>
    <div class="canvasWrapper">
      <canvas ref="canvasRef" @mousemove="onMouseMove" />
    </div>
  </div>
  <div class="introOnion">
    <svg
      class="wave"
      viewBox="0 0 1920 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1920-250.275v415.262c-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.26-63.07-120 0-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.26-63.07-120 0-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.26-63.07-120 0-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.258-63.07-120 0-38.742 63.074-78.608 69.676-120.002 0-41.391-69.6722-81.257-63.07-119.999 0-38.738 63.074-78.608 69.676-119.999 0-41.39-69.6722-81.26-63.07-119.998 0-38.742 63.074-78.608 69.676-120.002 0-41.391-69.6722-81.257-63.07-119.999 0-38.739 63.074-78.608 69.676-119.999 0-41.394-69.6722-81.2603-63.07-120.002 0v-415.262h1920Z"
        fill="#AA0C0C"
      />
    </svg>
    <div class="msgWrapper">
      <div ref="msgWrapperRef">
        <p>평범한 글자에는 담을 수 없었던<br />롯데리아 스피릿!</p>
        <p>
          두 가지 폰트를 만나기 전에<br />
          폰트 탄생의 든든한 배경을 이루는<br />
          롯데리아만의 개척과 도전의<br />
          역사를 살펴보지 않을래?
        </p>
      </div>
      <IntroBurger />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import Monster from "../assets/intro/IntroMonster.vue";
import IntroCaption from "../assets/intro/IntroCaption.vue";
import Ball1 from "../assets/intro/Ball1.vue";
import Ball2 from "../assets/intro/Ball2.vue";
import Planet from "../assets/intro/Planet.vue";
import Satellite from "../assets/intro/Satellite.vue";
import SpaceBurger from "../assets/intro/SpaceBurger.vue";
import Moon from "../assets/intro/Moon.vue";
import IntroArrow from "../assets/intro/IntroArrow.vue";
import CloudL from "../assets/intro/CloudL.vue";
import CloudR from "../assets/intro/CloudR.vue";
import Twinkle from "../assets/intro/1-01-03_twinkle.vue";
import IntroBurger from "../assets/intro/IntroBurger.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const msgWrapperRef = ref();

const canvasRef = ref();
const ctx = ref();

const particleArray = [];
const textRef1 = ref();
const textRef2 = ref();
const textRef3 = ref();
const hue = ref(0);
const text = ref("");
const monsterRef = ref();

const twinkleArray = ref([]);
const twinkleRef = (el) => twinkleArray.value.push(el);

const textArray = "촵딱";
const randomText = () => {
  for (let i = 0; i < 1; i++)
    text.value = textArray.charAt(Math.floor(Math.random() * textArray.length));
  return text.value;
};

const mouse = reactive({
  x: -100,
  y: -100,
});

const onMouseMove = (e) => {
  if (matchMedia("(min-width: 480px)").matches) {
    mouse.x = e.pageX;
    mouse.y = e.pageY;
    for (let i = 0; i < 2; i++) {
      particleArray.push(new Particle());
    }
  }
};
const moveMouseText = (e) => {
  if (matchMedia("(min-width: 480px)").matches) {
    mouse.x = e.pageX - window.innerWidth / 2;
    mouse.y = e.pageY - window.innerHeight / 2;
    let monsterX = mouse.x / window.innerWidth;
    let monsterY = mouse.y / window.innerHeight;
    if (monsterX < 0) {
      monsterX *= -1;
    }
    if (monsterY < 0) {
      monsterY *= -1;
    }
    if (monsterX < monsterY) {
      if (monsterY < 0.1) {
        monsterY = 0.1;
      }
      monsterRef.value.monsterRef.style.opacity = monsterY;
    } else if (monsterX > monsterY) {
      if (monsterX < 0.1) {
        monsterX = 0.1;
      }
      monsterRef.value.monsterRef.style.opacity = monsterX;
    }
    textRef1.value.style.transform = `translate(${mouse.x / -5}px,
      ${mouse.y / -5}px)
      rotateY(${mouse.x / 20}deg)
      rotateX(${mouse.y / 10}deg)`;
    textRef2.value.style.transform = `translate(${mouse.x / -5}px,
      ${mouse.y / -5}px)
      rotateY(${mouse.x / 20}deg)
      rotateX(${mouse.y / 10}deg)`;
    textRef3.value.style.transform = `translate(${mouse.x / -5}px,
      ${mouse.y / -5}px)
      rotateY(${mouse.x / 20}deg)
      rotateX(${mouse.y / 10}deg)`;
  }
};

class Particle {
  constructor() {
    this.x = mouse.x;
    this.y = mouse.y;
    if (matchMedia("(max-width: 480px)").matches) {
      this.size = Math.random() * 60 + 1;
    } else {
      this.size = Math.random() * 100 + 1;
    }
    this.speedX = Math.random() * 3 - 1.5;
    this.speedY = Math.random() * 3 - 1.5;
    this.color = `white`;
    this.text = text.value;
  }
  update() {
    this.x += this.speedX;
    this.y += this.speedY;
    if (this.size > 0.2) {
      this.size /= 1.2;
    }
  }
  draw() {
    randomText();
    ctx.value.beginPath();
    if (this.text === "촵") {
      ctx.value.font = `${this.size * 1.5}px chab`;
      ctx.value.fillStyle = this.color;
      ctx.value.lineWidth = 3;
      ctx.value.strokeStyle = "#ff6a5f";
      ctx.value.strokeText(this.text, this.x * 0.93, this.y * 1.07);
    } else {
      ctx.value.font = `${this.size * 1.5}px ddag`;
      ctx.value.fillStyle = this.color;
      ctx.value.lineWidth = 3;
      ctx.value.strokeStyle = "#44d13d";
      ctx.value.strokeText(this.text, this.x * 0.93, this.y * 1.07);
    }
    ctx.value.fillText(this.text, this.x * 0.93, this.y * 1.07);
    ctx.value.fill();
  }
}

function handleParticles() {
  for (let i = 0; i < particleArray.length; i++) {
    particleArray[i].update();
    particleArray[i].draw();
    if (particleArray[i].size <= 0.3) {
      particleArray.splice(i, 1);
      i--;
    }
  }
}

function animate() {
  ctx.value.clearRect(0, 0, canvasRef.value.width, canvasRef.value.height);
  handleParticles();
  randomText();
  hue.value += 2;
  requestAnimationFrame(animate);
}

const spaceBurgerRef = ref();
const textTl = gsap.timeline();
const starTl = gsap.timeline();
const cloudTl = gsap.timeline();
const spaceBurgerTl = gsap.timeline();

onMounted(() => {
  ctx.value = canvasRef.value.getContext("2d");
  canvasRef.value.width = window.innerWidth;
  canvasRef.value.height = window.innerHeight;

  animate();

  textTl
    .to(".text1", {
      opacity: 1,
      duration: 0,
      repeat: -1,
      repeatDelay: 6,
    })
    .to(
      ".text1",
      {
        opacity: 0,
        duration: 0,
        delay: 2,
        repeat: -1,
        repeatDelay: 6,
      },
      "<"
    )
    .to(
      ".text2",
      {
        opacity: 1,
        duration: 0,
        repeat: -1,
        repeatDelay: 6,
      },
      "<"
    )
    .to(
      ".text2",
      {
        opacity: 0,
        duration: 0,
        delay: 2,
        repeat: -1,
        repeatDelay: 6,
      },
      "<"
    )
    .to(
      ".text3",
      {
        opacity: 1,
        duration: 0,
        repeat: -1,
        repeatDelay: 6,
      },
      "<"
    )
    .to(
      ".text3",
      {
        opacity: 0,
        duration: 0,
        delay: 2,
        repeat: -1,
        repeatDelay: 6,
      },
      "<"
    );

  starTl
    .to(".star1", {
      opacity: 1,
      duration: 1,
      ease: "power2",
      yoyo: true,
      repeat: -1,
      repeatDelay: 1.5,
    })
    .to(".star2", {
      opacity: 1,
      duration: 1,
      ease: "power2",
      yoyo: true,
      repeat: -1,
      delay: 0.5,
      repeatDelay: 1.5,
    })
    .to(".star3", {
      opacity: 1,
      duration: 1,
      ease: "power2",
      yoyo: true,
      repeat: -1,
      delay: 1,
      repeatDelay: 1.5,
    });

  cloudTl.to(".cloudL", {
    scale: 0.9,
    duration: 1.5,
    repeat: -1,
    yoyo: true,
    transformOrigin: "left bottom",
    ease: "none",
  });
  cloudTl.to(
    ".cloudR",
    {
      scale: 0.9,
      duration: 1.5,
      repeat: -1,
      yoyo: true,
      transformOrigin: "right bottom",
      ease: "none",
    },
    "<"
  );

  // 반짝이
  gsap.to(twinkleArray.value[0].twinkleRef, {
    scale: 0,
    yoyo: true,
    repeat: -1,
    ease: "power2",
    duration: 1.2,
  });
  gsap.to(twinkleArray.value[1].twinkleRef, {
    scale: 0,
    yoyo: true,
    repeat: -1,
    ease: "power2",
    duration: 1.2,
    delay: 1,
  });
  gsap.to(twinkleArray.value[2].twinkleRef, {
    scale: 0,
    yoyo: true,
    repeat: -1,
    ease: "power2",
    duration: 1.2,
    delay: 1.5,
  });

  gsap.to(".planet", {
    rotate: -40,
    repeat: -1,
    duration: 3,
    yoyo: true,
    ease: "power1",
  });
  gsap.to(".satellite", {
    rotate: -40,
    repeat: -1,
    duration: 3.5,
    yoyo: true,
    ease: "power1",
  });
  spaceBurgerTl.set(spaceBurgerRef.value.spaceBurgerRef, {
    xPercent: -50,
  });
  spaceBurgerTl.to(spaceBurgerRef.value.spaceBurgerRef, {
    yPercent: -30,
    duration: 2,
    yoyo: true,
    ease: "power1",
    repeat: -1,
    repeatDelay: 0.1,
  });
  window.addEventListener("resize", () => {
    canvasRef.value.width = window.innerWidth;
    canvasRef.value.height = window.innerHeight;
    spaceBurgerTl.restart();
  });

  // des
  const scrollConceptAni = gsap.timeline();
  if (matchMedia("(min-width: 480px)").matches) {
    ScrollTrigger.create({
      animation: scrollConceptAni,
      trigger: msgWrapperRef.value,
      start: "top 50%",
      end: "top 45%",
      scrub: 2,
    });
  } else {
    ScrollTrigger.create({
      animation: scrollConceptAni,
      trigger: msgWrapperRef.value,
      start: "top 60%",
      end: "top 50%",
      scrub: 2,
    });
  }
  scrollConceptAni.fromTo(
    msgWrapperRef.value,
    {
      y: "10%",
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      duration: 2,
      ease: "none",
    }
  );

  gsap.to("#introLeft", {
    y: "25%",
    duration: 0,
    repeat: -1,
    repeatDelay: 0.6,
  });
  gsap.to("#introLeft", {
    y: 0,
    duration: 0,
    repeat: -1,
    delay: 0.3,
    repeatDelay: 0.6,
  });
  gsap.to("#introRight", {
    y: "25%",
    duration: 0,
    repeat: -1,
    delay: 0.3,
    repeatDelay: 0.6,
  });
  gsap.to("#introRight", {
    y: 0,
    duration: 0,
    repeat: -1,
    repeatDelay: 0.6,
  });
  gsap.to("#introChab", {
    opacity: 0,
    duration: 0,
    repeat: -1,
    repeatDelay: 0.6,
  });
  gsap.to("#introChab", {
    opacity: 1,
    duration: 0,
    repeat: -1,
    delay: 0.3,
    repeatDelay: 0.6,
  });
  gsap.to("#introDdag", {
    opacity: 0,
    duration: 0,
    repeat: -1,
    delay: 0.3,
    repeatDelay: 0.6,
  });
  gsap.to("#introDdag", {
    opacity: 1,
    duration: 0,
    repeat: -1,
    repeatDelay: 0.6,
  });
});
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background: #00298e;
  .backgroundImgWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/introBack.webp") center center/cover no-repeat;
    overflow: hidden;
    pointer-events: none;
    svg {
      position: absolute;
      vertical-align: bottom;
    }
    .monster {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 40%;
      opacity: 0.1;
      @media screen and (max-width: 480px) {
        display: none;
        width: 170%;
        opacity: 0.2;
      }
    }
    .introCaption {
      top: 48px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100px;
      z-index: 1;
      @media screen and (max-width: 480px) {
        top: 35px;
        width: 110px;
      }
    }
    .ball1 {
      top: 0;
      left: 0;
      width: 25%;
      @media screen and (max-width: 480px) {
        width: 50%;
      }
    }
    .ball2 {
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: 14%;
      @media screen and (max-width: 480px) {
        display: none;
      }
    }
    .planet {
      left: 14%;
      top: 70%;
      width: 4%;
    }
    .satellite {
      top: 10%;
      right: 20%;
      width: 6%;
      @media screen and (max-width: 480px) {
        top: 20%;
        right: 10%;
        width: 16%;
      }
    }
    .spaceburger {
      bottom: 0;
      left: 50%;
      transform: translate(0, -46%) translate3d(0, 0, 0);
      width: 10%;
      z-index: 2;
      @media screen and (max-width: 480px) {
        width: 35%;
        transform: translate(0, -32%) translate3d(0, 0, 0);
      }
    }
    .cloudL {
      width: 40%;
      bottom: 0;
      left: 0;
    }
    .cloudR {
      width: 40%;
      bottom: 0;
      right: 0;
    }
    .moon {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 37%;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
    .arrow {
      bottom: 16px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 2.5%;
      @media screen and (max-width: 480px) {
        width: 10%;
      }
    }
    .twinkleWrapper {
      position: absolute;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      svg {
        position: absolute;
        transform: translate(0, -50%);
        width: 5%;
        @media screen and (max-width: 480px) {
          width: 8%;
        }
        &:nth-child(1) {
          top: 85%;
          left: 18%;
        }
        &:nth-child(2) {
          top: 18%;
          right: 35%;
        }
        &:nth-child(3) {
          top: 65%;
          right: 15%;
        }
      }
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
      opacity: 0;
    }
  }
  .textWrapper {
    position: absolute;
    left: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    @media screen and (max-width: 480px) {
      left: 50%;
      transform: translate(-50%, 0);
      width: 90%;
    }
    img {
      position: absolute;
      width: 100%;
      pointer-events: none;
      opacity: 0;
    }
  }
  .canvasWrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 2;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}

.introOnion {
  font-family: "chab";
  overflow: hidden;
  .wave {
    background: #f9f9d7;
    vertical-align: bottom;
    @media screen and (max-width: 480px) {
      & {
        transform-origin: top;
        transform: scale(1.2);
      }
    }
  }
  .msgWrapper {
    text-align: center;
    background: #f9f9d7;
    color: #8e0606;
    padding-top: 5vw;
    div {
      p {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 3vw;
        word-break: keep-all;
        white-space: pre-line;
        line-height: 4.5vw;
        @media screen and (max-width: 480px) {
          & {
            font-size: 5vw;
            line-height: 8vw;
          }
        }
        &:nth-child(1) {
          margin: 16vw 0 5vw 0;
        }
        &:nth-child(2) {
          margin-bottom: 10vw;
        }
      }
    }
    svg {
      width: 20%;
    }
  }
}
</style>
