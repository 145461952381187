<template>
  <svg viewBox="0 0 503 284" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 362">
      <path
        id="Vector"
        d="M401.68 236.744H-.00062943v35.443c0 6.521 5.29272943 11.814 11.81412943 11.814H389.866c6.521 0 11.814-5.293 11.814-11.814v-35.443Z"
        fill="#AA4500"
      />
      <path
        id="cheese_2"
        d="M11.8135 224.93H389.866c6.527 0 11.814 5.287 11.814 11.814H-.00062943c0-6.527 5.28681943-11.814 11.81412943-11.814Z"
        fill="#FFD600"
      />
      <path
        id="cheese_1"
        d="m59.0723 236.744 31.266 31.266c2.3097 2.31 6.0489 2.31 8.3526 0l31.2661-31.266H59.0723Z"
        fill="#FFD519"
      />
      <path
        id="tomato"
        d="M389.613 219.872c0 2.794-2.646 5.056-5.907 5.056H17.4682c-3.2607 0-5.9071-2.262-5.9071-5.056v-25.276c0-2.795 2.6464-5.057 5.9071-5.057H383.706c3.261 0 5.907 2.262 5.907 5.057v25.276Z"
        fill="#F7342B"
      />
      <path
        id="onion"
        d="M18.6377 165.918H383.045c3.768 0 6.822 3.055 6.822 6.823v9.983c0 3.768-3.054 6.822-6.822 6.822H18.6377c-3.768 0-6.8226-3.054-6.8226-6.822v-9.983c0-3.768 3.0546-6.823 6.8226-6.823Z"
        fill="#DDE5AC"
      />
      <path
        id="lettuce"
        d="M-.00062942 189.487c0 1.843.93920042 3.556 2.48684942 4.549l20.00138 12.8c3.4674 2.221 7.7973 3.326 12.1331 3.326 4.3357 0 8.6656-1.105 12.1272-3.326l17.2958-11.07c3.4675-2.215 7.7974-3.325 12.1273-3.325 4.3298 0 8.6656 1.11 12.1331 3.325l17.2959 11.07c3.461 2.221 7.797 3.326 12.127 3.326 4.33 0 8.666-1.105 12.133-3.326l17.296-11.07c3.462-2.215 7.797-3.325 12.127-3.325 4.33 0 8.666 1.11 12.133 3.325l17.29 11.07c3.468 2.221 7.804 3.326 12.134 3.326 4.329 0 8.665-1.105 12.133-3.326l17.29-11.07c3.467-2.215 7.797-3.325 12.133-3.325 4.335 0 8.665 1.11 12.127 3.325l17.296 11.07c3.467 2.221 7.797 3.326 12.133 3.326 4.336 0 8.666-1.105 12.127-3.326l17.296-11.07c3.467-2.215 7.797-3.325 12.133-3.325 4.336 0 8.66 1.11 12.127 3.325l17.296 11.07c3.462 2.221 7.798 3.326 12.127 3.326 4.33 0 8.666-1.105 12.133-3.326l20.002-12.8c1.547-.993 2.487-2.706 2.487-4.549 0-3.261-2.647-5.907-5.907-5.907H5.90643c-3.26069 0-5.90705942 2.646-5.90705942 5.907Z"
        fill="#00E030"
      />
      <path
        id="face(bun)"
        d="M200.841.460938c106.327 0 189.026 59.070662 189.026 153.584062v5.907c0 3.26-2.646 5.907-5.907 5.907H17.7221c-3.2607 0-5.907-2.647-5.907-5.907v-5.907C11.8151 59.5316 94.514.460938 200.841.460938Z"
        fill="#F28518"
      />
      <g id="cheek_R">
        <path
          id="Vector_2"
          d="M132.32 128.888c0 2.611-2.114 4.725-4.725 4.725-2.611 0-4.726-2.114-4.726-4.725 0-2.611 2.115-4.726 4.726-4.726 2.611 0 4.725 2.115 4.725 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_3"
          d="M121.688 147.308c0 2.611-2.115 4.725-4.726 4.725-2.611 0-4.726-2.114-4.726-4.725 0-2.611 2.115-4.726 4.726-4.726 2.611 0 4.726 2.115 4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_4"
          d="M111.055 128.888c0 2.611-2.115 4.725-4.726 4.725-2.611 0-4.726-2.114-4.726-4.725 0-2.611 2.115-4.726 4.726-4.726 2.611 0 4.726 2.115 4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_5"
          d="M121.688 110.472c0 2.611-2.115 4.725-4.726 4.725-2.611 0-4.726-2.114-4.726-4.725 0-2.611 2.115-4.726 4.726-4.726 2.611 0 4.726 2.115 4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_6"
          d="M142.953 110.472c0 2.611-2.115 4.725-4.726 4.725-2.61 0-4.725-2.114-4.725-4.725 0-2.611 2.115-4.726 4.725-4.726 2.611 0 4.726 2.115 4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_7"
          d="M153.586 128.888c0 2.611-2.115 4.725-4.726 4.725-2.611 0-4.725-2.114-4.725-4.725 0-2.611 2.114-4.726 4.725-4.726 2.611 0 4.726 2.115 4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_8"
          d="M142.953 147.308c0 2.611-2.115 4.725-4.726 4.725-2.61 0-4.725-2.114-4.725-4.725 0-2.611 2.115-4.726 4.725-4.726 2.611 0 4.726 2.115 4.726 4.726Z"
          fill="#FFA526"
        />
      </g>
      <g id="cheek_L">
        <path
          id="Vector_9"
          d="M316.623 128.888c0 2.611 2.115 4.725 4.726 4.725 2.61 0 4.725-2.114 4.725-4.725 0-2.611-2.115-4.726-4.725-4.726-2.611 0-4.726 2.115-4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_10"
          d="M327.256 147.308c0 2.611 2.114 4.725 4.725 4.725 2.611 0 4.726-2.114 4.726-4.725 0-2.611-2.115-4.726-4.726-4.726-2.611 0-4.725 2.115-4.725 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_11"
          d="M337.889 128.888c0 2.611 2.114 4.725 4.725 4.725 2.611 0 4.726-2.114 4.726-4.725 0-2.611-2.115-4.726-4.726-4.726-2.611 0-4.725 2.115-4.725 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_12"
          d="M327.256 110.472c0 2.611 2.114 4.725 4.725 4.725 2.611 0 4.726-2.114 4.726-4.725 0-2.611-2.115-4.726-4.726-4.726-2.611 0-4.725 2.115-4.725 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_13"
          d="M305.99 110.472c0 2.611 2.115 4.725 4.726 4.725 2.611 0 4.725-2.114 4.725-4.725 0-2.611-2.114-4.726-4.725-4.726-2.611 0-4.726 2.115-4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_14"
          d="M295.357 128.888c0 2.611 2.115 4.725 4.726 4.725 2.611 0 4.726-2.114 4.726-4.725 0-2.611-2.115-4.726-4.726-4.726-2.611 0-4.726 2.115-4.726 4.726Z"
          fill="#FFA526"
        />
        <path
          id="Vector_15"
          d="M305.99 147.308c0 2.611 2.115 4.725 4.726 4.725 2.611 0 4.725-2.114 4.725-4.725 0-2.611-2.114-4.726-4.725-4.726-2.611 0-4.726 2.115-4.726 4.726Z"
          fill="#FFA526"
        />
      </g>
      <path
        id="mouth (Stroke)"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M252.075 126.795c-1.075-2.198-3.729-3.108-5.926-2.033-14.89 7.287-29.175 7.249-43.294.035-2.179-1.114-4.847-.25-5.961 1.929-1.113 2.179-.249 4.848 1.93 5.961 16.704 8.534 33.927 8.496 51.219.034 2.198-1.075 3.108-3.729 2.032-5.926Z"
        fill="#8E0606"
      />
      <path
        id="White"
        d="M265.821 116.882c-13.049 0-23.628-10.58-23.628-23.6287 0-13.0487 10.579-23.6283 23.628-23.6283s23.628 10.5796 23.628 23.6283-10.579 23.6287-23.628 23.6287Z"
        fill="white"
      />
      <path
        id="black"
        d="M265.818 110.974c-9.788 0-17.721-7.934-17.721-17.7216 0-9.788 7.933-17.7212 17.721-17.7212s17.721 7.9332 17.721 17.7212c0 9.7876-7.933 17.7216-17.721 17.7216Z"
        fill="#8E0606"
      />
      <path
        id="white"
        d="M183.122 116.882c-13.049 0-23.629-10.58-23.629-23.6287 0-13.0487 10.58-23.6283 23.629-23.6283 13.048 0 23.628 10.5796 23.628 23.6283s-10.58 23.6287-23.628 23.6287Z"
        fill="white"
      />
      <path
        id="black_2"
        d="M183.119 110.974c-9.788 0-17.722-7.934-17.722-17.7216 0-9.788 7.934-17.7212 17.722-17.7212s17.721 7.9332 17.721 17.7212c0 9.7876-7.933 17.7216-17.721 17.7216Z"
        fill="#8E0606"
      />
      <g id="Group 394">
        <path
          id="exR"
          d="M399.215 194.811c-16.312 0-29.536-13.224-29.536-29.535 0-16.312 13.224-29.536 29.536-29.536s29.535 13.224 29.535 29.536c0 16.311-13.223 29.535-29.535 29.535Z"
          fill="#F28518"
        />
        <path
          id="exL"
          d="M117.535 194.811c-16.312 0-29.5353-13.224-29.5353-29.535 0-16.312 13.2233-29.536 29.5353-29.536 16.312 0 29.535 13.224 29.535 29.536 0 16.311-13.223 29.535-29.535 29.535Z"
          fill="#F28518"
        />
      </g>
      <path
        id="computer"
        d="M490.642 177.672H182.766c-6.327 0-11.46 5.775-11.46 12.892v93.435h330.796v-93.435c0-7.117-5.134-12.892-11.46-12.892Z"
        fill="#C8C1C1"
      />
    </g>
  </svg>
</template>
