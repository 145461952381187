<template>
  <div>
    <Toggle @toggleClicked="toggleClicked" />
    <transition name="menuFadeAni">
      <Menu
        v-show="toggleValue"
        @moveToIntro="moveToIntro"
        @moveToConcept="moveToConcept"
        @moveToChab="moveToChab"
        @moveToDdag="moveToDdag"
        @moveToTry="moveToTry"
        @moveToDownload="moveToDownload"
      />
    </transition>
    <div
      v-for="section in sections"
      :key="section.id"
      :class="section.title"
      :ref="sectionRef"
    >
      <component :is="section.component" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Toggle from "./components/Toggle.vue";
import Menu from "./components/Menu.vue";
import Intro from "./components/Intro.vue";
import Concept1 from "./components/Concept1.vue";
import Concept2 from "./components/Concept2.vue";
import Chab from "./components/Chab.vue";
import Ddag from "./components/Ddag.vue";
import Experience from "./components/Experience.vue";
import Sticker from "./components/Sticker.vue";
import Download from "./components/Download.vue";

const sectionArray = ref([]);
const sectionRef = (el) => sectionArray.value.push(el);
const sections = [
  { component: Intro },
  { component: Concept1 },
  { component: Concept2 },
  { component: Chab },
  { component: Ddag },
  { component: Experience },
  { component: Sticker },
  { component: Download },
];

const toggleValue = ref(false);

const toggleClicked = () => {
  toggleValue.value = !toggleValue.value;
};

const moveToIntro = () => {
  if (matchMedia("(max-width: 480px)").matches) {
    setTimeout(() => {
      toggleValue.value = false;
      scrollTo({ top: 0, behavior: "smooth" });
    }, 1000);
  } else {
    toggleValue.value = false;
    scrollTo({ top: 0, behavior: "smooth" });
  }
};
const moveToConcept = () => {
  if (matchMedia("(max-width: 480px)").matches) {
    setTimeout(() => {
      toggleValue.value = false;
      const conceptLocation = sectionArray.value[1].offsetTop;
      scrollTo({ top: conceptLocation, behavior: "smooth" });
    }, 1000);
  } else {
    toggleValue.value = false;
    const conceptLocation = sectionArray.value[1].offsetTop;
    scrollTo({ top: conceptLocation, behavior: "smooth" });
  }
};
const moveToChab = () => {
  if (matchMedia("(max-width: 480px)").matches) {
    setTimeout(() => {
      toggleValue.value = false;
      const chabLocation = sectionArray.value[3].offsetTop;
      scrollTo({ top: chabLocation, behavior: "smooth" });
    }, 1000);
  } else {
    toggleValue.value = false;
    const chabLocation = sectionArray.value[3].offsetTop;
    scrollTo({ top: chabLocation, behavior: "smooth" });
  }
};
const moveToDdag = () => {
  if (matchMedia("(max-width: 480px)").matches) {
    setTimeout(() => {
      toggleValue.value = false;
      const ddagLocation = sectionArray.value[4].offsetTop;
      scrollTo({ top: ddagLocation, behavior: "smooth" });
    }, 1000);
  } else {
    toggleValue.value = false;
    const ddagLocation = sectionArray.value[4].offsetTop;
    scrollTo({ top: ddagLocation, behavior: "smooth" });
  }
};
const moveToTry = () => {
  if (matchMedia("(max-width: 480px)").matches) {
    setTimeout(() => {
      toggleValue.value = false;
      const tryLocation = sectionArray.value[5].offsetTop;
      scrollTo({ top: tryLocation, behavior: "smooth" });
    }, 1000);
  } else {
    toggleValue.value = false;
    const tryLocation = sectionArray.value[5].offsetTop;
    scrollTo({ top: tryLocation, behavior: "smooth" });
  }
};
const moveToDownload = () => {
  if (matchMedia("(max-width: 480px)").matches) {
    setTimeout(() => {
      const downLocation = sectionArray.value[6].offsetTop;
      scrollTo({ top: downLocation, behavior: "smooth" });
      toggleValue.value = false;
    }, 1000);
  } else {
    const downLocation = sectionArray.value[6].offsetTop;
    scrollTo({ top: downLocation, behavior: "smooth" });
    toggleValue.value = false;
  }
};

const sua = navigator.userAgent.match(/SamsungBrowser/i);

onMounted(() => {
  // except for bottom bar
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  window.addEventListener("scroll", () => {
    if (toggleValue.value) {
      toggleValue.value = false;
    }
  });

  if (
    sua ||
    navigator.userAgent.indexOf("KAKAO") >= 0 ||
    navigator.userAgent.indexOf("[FB") >= 0 ||
    navigator.userAgent.indexOf("Instagram") >= 0 ||
    navigator.userAgent.indexOf("trill") >= 0
  ) {
    alert("본 홈페이지는 Google Chrome에 최적화되어 있습니다.");
  }

  setTimeout(() => {
    const video = document.getElementsByTagName("video");
    for (let i = 0; i < video.length; i++) {
      setTimeout(() => {
        video[i].play();
      }, 500);
    }
  }, 500);
});
</script>

<style lang="scss">
@font-face {
  font-family: "chab";
  src: url("./assets/font/chab.woff2") format("woff2"),
    url("./assets/font/chab.otf") format("opentype");
}
@font-face {
  font-family: "ddag";
  src: url("./assets/font/ddag.woff2") format("woff2"),
    url("./assets/font/ddag.otf") format("opentype");
}
@font-face {
  font-family: "PyeongChang";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2206-02@1.0/PyeongChang-Bold.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
#app {
  letter-spacing: 0.05rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
body,
p {
  margin: 0;
}
p {
  word-break: keep-all;
}
.menuFadeAni-enter-active,
.menuFadeAni-leave-active {
  transition: opacity 0.2s;
}
.menuFadeAni-enter-from,
.menuFadeAni-leave-to {
  opacity: 0;
}
.menuFadeAni-enter-to,
.menuFadeAni-leave-from {
  opacity: 1;
}
svg {
  vertical-align: bottom;
}
</style>
