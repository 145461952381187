<template>
  <div>
    <div class="swiperContainer">
      <div class="maskWrapper">
        <div class="mask" />
      </div>
      <p class="swiperContainer-number">②</p>
      <p class="swiperTitle">도전정신</p>
      <p class="swiperDes">
        밥으로 버거 번을 만들고<br />
        군대리아의 전설을 정식 메뉴로 만들어버리는 등<br />
        고객의 입맛, 취향에 딱-붙는 메뉴 개발을 위해<br />
        끊임없이 도전해온 롯데리아
      </p>
      <Swiper
        class="slider"
        :modules="[Navigation, Pagination]"
        :loop="true"
        :navigation="true"
        :pagination="{ clickable: true }"
      >
        <SwiperSlide
          v-for="slide in swiperLottie"
          :key="slide.id"
          class="slide"
        >
          <div class="slideFlex">
            <div class="videoWrapper">
              <Vue3Lottie
                class="swiperLottie"
                :loop="true"
                :auto-play="true"
                background-color="transparent"
                :animation-data="slide.lottie"
              />
            </div>
            <div class="lottieDesWrapper">
              <p>{{ slide.title }}</p>
              <div class="hrLine" />
              <p>{{ slide.des }}</p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="conceptDes">
      <div class="conceptDes-textWrapper">
        <div class="blank" />
        <p v-for="des in titleDes" :key="des.id" :ref="titleDesRef">
          {{ des.text }}
        </p>
      </div>
      <BurgerBook />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BurgerBook from "../assets/concept/BurgerBook.vue";

import { Vue3Lottie } from "vue3-lottie";
import swiper1 from "../assets/concept/2-02-01_challenge(1).json";
import swiper2 from "../assets/concept/2-02-02_challenge(2).json";
import swiper3 from "../assets/concept/2-02-03_challenge(3).json";
import swiper4 from "../assets/concept/2-02-04_challenge(4).json";
import swiper5 from "../assets/concept/2-02-05_challenge(5).json";

gsap.registerPlugin(ScrollTrigger);

const swiperLottie = [
  {
    lottie: swiper1,
    title: "1992\n불고기버거",
    des: "K-버거의 자존심\n업계 최초 불고기버거 출시",
  },
  {
    lottie: swiper2,
    title: "1999\n라이스버거",
    des: "한국인의 밥심\n(김치,새우,불고기)",
  },
  {
    lottie: swiper3,
    title: "2015\n모짜렐라인더버거",
    des: "이탈리아 자연산 치즈가\n길게 늘어나는 버거",
  },
  {
    lottie: swiper4,
    title: "2015\n라면버거",
    des: "번스를 라면으로 만든\n이색 버거",
  },
  {
    lottie: swiper5,
    title: "2020\n밀리터리버거",
    des: "군대리아의 전설을\n눈앞에",
  },
];

const titleDesArray = ref([]);
const titleDesRef = (el) => titleDesArray.value.push(el);
const titleDes = [
  { text: "개척과 도전의\n롯데리아 스피릿을\n담아낸 폰트!?" },
  {
    text: "시선을 촵 ~ 사로잡고\n즐거움을 딱 - 선사하는\n색다른 폰트가 탄생했어!",
  },
  {
    text: "어떻게 생겼길래\n자꾸자꾸 쓰고 싶은지\n촵~땡겨체와 딱-붙어체를\n만나보자구!",
  },
];

const scrollConceptAni = gsap.timeline();
onMounted(() => {
  ScrollTrigger.create({
    animation: scrollConceptAni,
    trigger: titleDesArray.value[0],
    start: "top 45%",
    end: "30% 45%",
    scrub: 2,
  });
  scrollConceptAni.fromTo(
    titleDesArray.value,
    {
      y: "10%",
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
    }
  );
});
</script>

<style lang="scss">
// scoped 없음
@import "../assets/concept/swiper-pagination.scss";
@import "../assets/concept/navigation.scss";

.pin-spacer {
  background: #f28518;
}
.swiperContainer {
  font-family: "ddag";
  background: #7a2900;
  color: #ffd600;
  overflow: hidden;
  .maskWrapper {
    position: absolute;
    width: 100%;
    padding-bottom: 140%;
    overflow: hidden;
    .mask {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140%;
      padding-bottom: 140%;
      border-radius: 50%;
      background: #aa4500;
      @media screen and (max-width: 480px) {
        & {
          width: 200%;
          padding-bottom: 200%;
        }
      }
    }
  }
  &-number {
    position: relative;
    text-align: center;
    font-size: 4vw;
    padding-top: 12vw;
    z-index: 3;
    @media screen and (max-width: 480px) {
      & {
        font-size: 6vw;
      }
    }
  }
  .swiperTitle {
    position: relative;
    text-align: center;
    font-size: 15vw;
    letter-spacing: 0.01rem;
    z-index: 3;
    @media screen and (max-width: 480px) {
      & {
        font-size: 18vw;
      }
    }
  }
  .swiperDes {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 2vw;
    letter-spacing: 0.075rem;
    text-align: center;
    word-break: keep-all;
    white-space: pre-line;
    margin: 4vw 0;
    z-index: 3;
    @media screen and (max-width: 480px) {
      & {
        font-size: 4vw;
        margin-bottom: 30vw;
      }
    }
  }
  .slider {
    position: relative;
    height: 50vw;
    z-index: 3;
    @media screen and (max-width: 480px) {
      & {
        height: 150vw;
      }
    }
    .slide {
      .slideFlex {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 2vw;
        width: 65.1%;
        text-align: center;
        height: 100%;
        @media screen and (max-width: 480px) {
          & {
            flex-direction: column-reverse;
            justify-content: center;
          }
        }
        .videoWrapper {
          width: 50%;
          overflow: hidden;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
          @media screen and (max-width: 480px) {
            & {
              width: 75%;
            }
          }
          .swiperLottie {
            width: 100%;
            border-radius: 50%;
          }
        }
        .lottieDesWrapper {
          position: relative;
          width: 50%;
          margin: 1vw;
          white-space: pre-line;
          pointer-events: none;
          @media screen and (max-width: 480px) {
            & {
              width: 100%;
            }
          }
          .hrLine {
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            height: 1px;
            margin: 10% 0;
            background: #ffd600;
            @media screen and (max-width: 480px) {
              & {
                width: 100%;
              }
            }
          }
          p {
            margin-left: 0.5%;
            word-break: keep-all;
            &:nth-child(1) {
              font-family: "chab";
              font-size: 4vw;
              letter-spacing: 0.05rem;
              margin-bottom: 2vw;
              @media screen and (max-width: 480px) {
                & {
                  font-size: 7vw;
                  margin-bottom: 0;
                }
              }
            }
            &:nth-child(3) {
              font-size: 2vw;
              letter-spacing: 0.075rem;
              margin-bottom: 6vw;
              @media screen and (max-width: 480px) {
                & {
                  font-size: 3.31vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
.conceptDes {
  background: #ffe9e7;
  svg {
    position: relative;
    margin-bottom: 6vw;
    @media screen and (max-width: 480px) {
      & {
        margin-top: 22vw;
      }
    }
  }
  &-textWrapper {
    position: absolute;
    font-family: "chab";
    text-align: center;
    word-break: keep-all;
    width: 100%;
    z-index: 1;
    .blank {
      position: absolute;
      width: 100%;
      background: white;
      @media screen and (max-width: 480px) {
        & {
          height: 50vw;
        }
      }
    }
    p {
      position: relative;
      text-align: center;
      font-size: 2vw;
      line-height: 3vw;
      opacity: 0;
      white-space: pre-line;
      @media screen and (max-width: 480px) {
        & {
          font-size: 4vw;
          line-height: 6vw;
        }
      }
      &:nth-child(2) {
        font-size: 6vw;
        line-height: 8vw;
        color: #f7342b;
        margin-top: 12vw;
        @media screen and (max-width: 480px) {
          & {
            margin-top: 0;
            padding-top: 12vw;
          }
        }
      }
      &:nth-child(3) {
        color: #8e0606;
        letter-spacing: 0.075rem;
        margin: 6vw 0 4vw 0;
      }
      &:nth-child(4) {
        color: #8e0606;
        letter-spacing: 0.075rem;
      }
    }
  }
}
</style>
