<template>
  <div class="menuLogoWrapper" @click="goToHome">
    <Vue3Lottie
      ref="logoMenuRef"
      :loop="false"
      :auto-play="true"
      background-color="transparent"
      :animation-data="logoMenu"
    />
  </div>
  <div class="toggleMenuWrapper">
    <Vue3Lottie
      ref="toggleMenuRef"
      :loop="false"
      :auto-play="false"
      background-color="transparent"
      :animation-data="toggleMenu"
      @click="toggleMenuPlay"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { Vue3Lottie } from "vue3-lottie";

import logoMenu from "../assets/menu/0-02-00_logo_button.json";
import toggleMenu from "../assets/menu/0-03-00_hamburger_button.json";

const emit = defineEmits(["toggleClicked"]);

const logoMenuRef = ref();
const toggleMenuRef = ref();
const playedCheck = ref(true);

const goToHome = () => {
  // eslint-disable-next-line no-undef
  gtag("event", "롯데이츠 바로가기", {
    event_category: "롯데리아 촵딱체 출시!",
    event_label: "click",
  });
  open("https://www.lotteeatz.com/");
};

const delayLoopLottie = () => {
  setInterval(() => {
    logoMenuRef.value.setDirection("forward");
    logoMenuRef.value.play();
    setTimeout(() => {
      logoMenuRef.value.setDirection("reverse");
      logoMenuRef.value.play();
    }, 5000);
  }, 10000);
};
const toggleMenuPlay = () => {
  if (!playedCheck.value) {
    playedCheck.value = true;
    toggleMenuRef.value.setDirection("reverse");
  } else {
    playedCheck.value = false;
    toggleMenuRef.value.setDirection("forward");
  }
  emit("toggleClicked");
  toggleMenuRef.value.play();
};

onMounted(() => {
  setTimeout(() => {
    delayLoopLottie();
    window.addEventListener("scroll", () => {
      playedCheck.value = true;
      toggleMenuRef.value.setDirection("reverse");
      toggleMenuRef.value.play();
    });
  }, 1000);
});
</script>

<style lang="scss" scoped>
.menuLogoWrapper {
  position: fixed;
  top: 2.5vw;
  left: 2.5%;
  width: 2.5%;
  min-width: 48px;
  z-index: 10;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    & {
      width: 2%;
      min-width: 34px;
    }
  }
}
.toggleMenuWrapper {
  position: fixed;
  top: 2.5vw;
  right: 2.5vw;
  width: 2.5%;
  min-width: 48px;
  z-index: 10;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    & {
      width: 2%;
      min-width: 34px;
    }
  }
}
</style>
