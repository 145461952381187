<template>
  <svg viewBox="0 0 264 361" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_334_6461"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="264"
      height="361"
    >
      <path fill="#D9D9D9" d="M.306641.525391h263v360h-263z" />
    </mask>
    <g mask="url(#mask0_334_6461)">
      <path
        d="M180.307 360.525c99.411 0 180-80.588 180-180 0-99.4109-80.589-179.999609-180-179.999609C80.8954.525391.306641 81.1141.306641 180.525c0 99.412 80.588759 180 180.000359 180Z"
        fill="#FF6A5F"
      />
      <path
        d="M36.2992 288.525c79.3128 28.132 166.1908-50.133 288.0138 0 15.722-20.932 26.986-45.401 32.386-72-125.683-31.444-240.222 30.037-352.79275 0 5.4 26.599 16.66405 51.068 32.38595 72h.007ZM36.3 72.5252C20.5781 93.4573 9.31406 117.926 3.91406 144.525c88.20004-43.91 192.03094 58.331 352.79294 0-5.4-26.599-16.664-51.0677-32.386-71.9998-149.878 42.4128-214.319-22.8656-288.014 0H36.3Z"
        fill="#F7342B"
      />
      <g opacity=".2">
        <path
          d="M360.305 180.525c0 99.408-80.585 180-180 180-12.03 0-23.779-1.181-35.149-3.431 82.575-16.355 144.837-89.191 144.837-176.569 0-87.377-62.262-160.2137-144.837-176.56836 11.37-2.25 23.119-3.431249 35.149-3.431249 99.415 0 180 80.585109 180 179.999609Z"
          fill="#001235"
        />
      </g>
    </g>
  </svg>
</template>
