<template>
  <svg viewBox="0 0 122 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m103.553 96.6445 9.14 15.8385m-15.8395-9.143 15.8385 9.14M99.7637 99.5527l12.9283 12.9283"
      stroke="#E7E2E2"
      stroke-width=".9"
      stroke-miterlimit="10"
    />
    <path
      d="m78.5977 94.418 16.0321-16.0322 3.9607 3.9607c1.0926 1.0926 1.0926 2.8637 0 3.9563L86.5146 98.3786c-1.0926 1.0926-2.8637 1.0927-3.9563.0001l-3.9606-3.9607Z"
      fill="#F7342B"
    />
    <path
      d="M32.6096 70.0095 4.89806 97.7211c-1.0925 1.0925-1.0925 2.8639 0 3.9559l15.83834 15.839c1.0925 1.092 2.8637 1.092 3.9562 0l27.7116-27.7119c1.0925-1.0925 1.0925-2.8637 0-3.9562L36.5659 70.0095c-1.0925-1.0925-2.8638-1.0924-3.9563 0Z"
      fill="white"
    />
    <path
      d="M34.5924 71.9838 6.88086 99.6953 22.7148 115.529l27.7115-27.7113-15.8339-15.8339Z"
      fill="#00298E"
    />
    <path
      d="M45.4781 78.9156 43.5 76.9375 11.8322 108.605l1.9781 1.978 31.6678-31.6674Z"
      fill="white"
    />
    <path
      d="m14.7984 87.8207-1.9781 1.9781 19.7946 19.7942 1.9781-1.978-19.7946-19.7943Zm9.8946-9.8926-1.9782 1.9781 19.7946 19.7946 1.9782-1.9781L24.693 77.9281ZM99.9104 2.70877 72.1988 30.4203c-1.0925 1.0925-1.0925 2.8638 0 3.9563l15.8384 15.8383c1.0924 1.0925 2.8637 1.0925 3.9562 0l27.7116-27.7116c1.092-1.0924 1.092-2.8637 0-3.9562L103.867 2.70876c-1.093-1.09249-2.864-1.09249-3.9566.00001Z"
      fill="white"
    />
    <path
      d="M101.887 4.6869 74.1758 32.3984l15.8339 15.8339 27.7113-27.7115-15.834-15.8339Z"
      fill="#00298E"
    />
    <path
      d="m112.773 11.6149-1.978-1.97818L79.1271 41.3045l1.9781 1.9782 31.6678-31.6678Z"
      fill="white"
    />
    <path
      d="m82.0934 20.5258-1.9782 1.9781 19.7946 19.7946 1.9782-1.9782-19.7946-19.7945Zm9.8906-9.8985-1.9781 1.9782L109.8 32.4001l1.979-1.9782-19.795-19.7946Z"
      fill="white"
    />
    <path
      d="m45.4789 78.9097 35.6285-35.6285-.9912-.9912-35.6285 35.6285.9912.9912Z"
      fill="#8A8181"
    />
    <path
      d="M44.4867 77.9195 80.1152 42.291l-.9912-.9913-35.6285 35.6285.9912.9913Z"
      fill="#A19A9A"
    />
    <path
      d="M61.5189 38.8229 39.0325 61.3093c-.2725.2725-.2725.7143 0 .9869l22.4864 22.4864c.2725.2725.7144.2725.9869 0l22.4864-22.4864c.2725-.2725.2725-.7144 0-.9869L62.5058 38.8229c-.2725-.2725-.7144-.2725-.9869 0Z"
      fill="white"
    />
    <path
      d="m58.0475 48.0111-9.829 9.829c-2.1874 2.1874-2.1874 5.7339 0 7.9214l9.829 9.829c2.1874 2.1874 5.7339 2.1874 7.9214 0l9.829-9.829c2.1874-2.1875 2.1874-5.734 0-7.9214l-9.829-9.829c-2.1875-2.1874-5.734-2.1874-7.9214 0Z"
      fill="#AA0C0C"
    />
    <path
      d="m59.0334 48.9451-9.8774 9.8775c-1.6449 1.6448-1.6449 4.3116 0 5.9564l9.8774 9.8775c1.6448 1.6448 4.3116 1.6448 5.9565 0l9.8774-9.8775c1.6449-1.6448 1.6449-4.3116 0-5.9564l-9.8774-9.8775c-1.6449-1.6448-4.3117-1.6448-5.9565 0Z"
      fill="#F7342B"
    />
    <path
      d="M64.4486 66.0386c.5172-.5172.9782-1.0094 1.4767-1.4642.2554-.2305.2741-.4174.1059-.7103-.3925-.6916-.7663-1.3894-1.109-2.1059-.1433-.2991-.2181-.2118-.3863-.0436-.972.9844-1.9564 1.9626-2.9346 2.9408-.4486.4486-.4548.4548-.9408.0436-1.1838-1.0093-1.8256-2.3115-1.9315-3.8567-.1121-1.676.4424-3.1339 1.6137-4.324 2.0063-2.0311 5.0966-2.1682 7.2461-.6168 2.4424 1.757 3.0655 5.109 1.4455 7.5888-1.0405 1.595-2.542 2.4361-4.4423 2.5732-.025 0-.0561-.0062-.1558-.0249h.0124Z"
      fill="#FFA526"
    />
    <path
      d="M62.8601 67.0547c-.866.866-1.7383 1.7383-2.6106 2.6043-.2243.2181-.461.4175-.7788.4736-.5421.0996-.947-.1371-1.2336-.5733-.187-.2803-.3303-.5919-.486-.8909-1.2773-2.4362-2.5483-4.8661-3.8256-7.3022-.1059-.1994-.0872-.3115.0748-.4673.7103-.6853 1.4019-1.3894 2.0997-2.0934.1433-.1496.1994-.1371.2928.0498 1.2087 2.324 2.4299 4.6417 3.6449 6.9657.0374.0748.081.1558.1246.2306.162.2679.324.299.5545.0747.4299-.4174.8474-.8473 1.271-1.271.8287-.8287 1.6574-1.6511 2.4798-2.486.1682-.1682.2305-.1557.3364.0561.2555.5234.5296 1.0343.81 1.5389.0935.1683.0748.2617-.0561.3988-.9034.891-1.7944 1.7881-2.6916 2.6853l-.0062.0063Z"
      fill="#FBFBFB"
    />
    <path
      d="M16.6234 16.4124C-7.12742 40.1632.673204 63.8018 7.00965 70.1383c7.63865 7.6386 11.59505 3.676 11.59505 3.676l55.4205-55.4206s3.9564-3.9564-3.676-11.59498C64.0128.462267 40.3741-7.33836 16.6234 16.4124Zm88.7976 33.3717L50.0001 105.205s-3.9564 3.956.4922 8.411 7.4205 7.42 39.0903-24.2494C121.252 57.6968 118.28 54.7311 113.832 50.2763c-4.455-4.4549-8.411-.4922-8.411-.4922Z"
      fill="#FFA526"
    />
    <path
      d="M19.9941 27.6972c.5483-.5483.5483-1.433 0-1.9813s-1.433-.5483-1.9813 0-.5483 1.433 0 1.9813 1.433.5483 1.9813 0Zm3.957-3.957c.5483-.5483.5483-1.433 0-1.9813s-1.433-.5483-1.9813 0-.5483 1.433 0 1.9813 1.433.5483 1.9813 0Zm-1.9824 9.8945c.5483-.5483.5483-1.433 0-1.9813s-1.433-.5483-1.9813 0-.5483 1.433 0 1.9813 1.433.5483 1.9813 0Zm3.9629-3.957c.5483-.5483.5483-1.433 0-1.9813s-1.433-.5483-1.9813 0-.5483 1.433 0 1.9813 1.433.5483 1.9813 0Zm1.9746-9.8946c.5483-.5482.5483-1.433 0-1.9813-.5483-.5482-1.433-.5482-1.9813 0-.5483.5483-.5483 1.4331 0 1.9813.5483.5483 1.433.5483 1.9813 0Zm1.9824 5.9375c.5483-.5482.5483-1.433 0-1.9813-.5483-.5482-1.433-.5482-1.9813 0-.5483.5483-.5483 1.4331 0 1.9813.5483.5483 1.433.5483 1.9813 0Zm3.9551-3.9628c.5483-.5483.5483-1.4331 0-1.9814-.5483-.5482-1.433-.5482-1.9813 0-.5483.5483-.5483 1.4331 0 1.9814.5483.5482 1.433.5482 1.9813 0Z"
      fill="#F28518"
    />
    <path
      d="m51.7558 48.5821 4.9519-4.9519c.2726-.2726.2726-.7144 0-.9869l-.0044-.0044c-.2725-.2725-.7143-.2725-.9868 0l-4.952 4.9519c-.2725.2725-.2725.7144 0 .9869l.0044.0044c.2725.2725.7144.2725.9869 0Z"
      fill="#8A8181"
    />
    <path
      d="m50.7636 47.5919 4.952-4.952c.2725-.2725.2725-.7143 0-.9868l-.0045-.0045c-.2725-.2725-.7143-.2725-.9868 0l-4.952 4.952c-.2725.2725-.2725.7143 0 .9869l.0044.0044c.2726.2725.7144.2725.9869 0Z"
      fill="#A19A9A"
    />
    <path
      d="m49.7792 46.6016 4.952-4.9519c.2725-.2725.2725-.7144 0-.9869l-.0044-.0044c-.2725-.2725-.7144-.2725-.9869 0l-4.9519 4.952c-.2726.2725-.2726.7143 0 .9868l.0044.0044c.2725.2726.7143.2725.9868 0Z"
      fill="#8A8181"
    />
    <path
      d="m48.787 45.6114 4.952-4.952c.2725-.2725.2725-.7143 0-.9868l-.0044-.0044c-.2725-.2725-.7144-.2725-.9869 0l-4.9519 4.9519c-.2725.2725-.2725.7144 0 .9869l.0044.0044c.2725.2725.7143.2725.9868 0Z"
      fill="#A19A9A"
    />
    <path
      d="m43.8359 56.4923 4.9519-4.952c.2725-.2725.2725-.7143 0-.9869l-.0044-.0044c-.2725-.2725-.7143-.2725-.9869 0l-4.9519 4.952c-.2725.2725-.2725.7143 0 .9869l.0044.0044c.2725.2725.7144.2725.9869 0Z"
      fill="#8A8181"
    />
    <path
      d="m42.8515 55.5079 4.9519-4.952c.2726-.2725.2726-.7143 0-.9868l-.0044-.0044c-.2725-.2726-.7143-.2726-.9868 0l-4.952 4.9519c-.2725.2725-.2725.7144 0 .9869l.0044.0044c.2725.2725.7144.2725.9869 0Z"
      fill="#A19A9A"
    />
    <path
      d="m41.8593 54.5176 4.952-4.9519c.2725-.2725.2725-.7144 0-.9869l-.0044-.0044c-.2726-.2725-.7144-.2725-.9869 0l-4.952 4.952c-.2725.2725-.2725.7143 0 .9868l.0044.0044c.2726.2726.7144.2726.9869 0Z"
      fill="#8A8181"
    />
    <path
      d="m40.8691 53.5313 4.9519-4.9519c.2725-.2725.2725-.7144 0-.9869l-.0044-.0044c-.2725-.2725-.7143-.2725-.9869 0L39.8778 52.54c-.2725.2726-.2725.7144 0 .9869l.0044.0044c.2725.2725.7144.2725.9869 0Z"
      fill="#A19A9A"
    />
    <path
      d="m79.1894 76.0157 4.9519-4.952c.2725-.2725.2725-.7143 0-.9868l-.0044-.0044c-.2725-.2725-.7143-.2725-.9868 0l-4.952 4.9519c-.2725.2725-.2725.7144 0 .9869l.0044.0044c.2725.2725.7144.2725.9869 0Z"
      fill="#8A8181"
    />
    <path
      d="m78.1991 75.0255 4.952-4.952c.2725-.2725.2725-.7143 0-.9869l-.0044-.0044c-.2725-.2725-.7144-.2725-.9869 0l-4.9519 4.952c-.2725.2725-.2725.7143 0 .9869l.0044.0044c.2725.2725.7143.2725.9868 0Z"
      fill="#A19A9A"
    />
    <path
      d="m77.2128 74.0352 4.952-4.9519c.2725-.2725.2725-.7144 0-.9869l-.0044-.0044c-.2725-.2725-.7144-.2725-.9869 0l-4.952 4.952c-.2725.2725-.2725.7143 0 .9868l.0045.0044c.2725.2725.7143.2725.9868 0Z"
      fill="#8A8181"
    />
    <path
      d="m76.2167 73.0489 4.952-4.952c.2725-.2725.2725-.7143 0-.9868l-.0044-.0044c-.2725-.2725-.7144-.2725-.9869 0l-4.9519 4.9519c-.2726.2725-.2726.7144 0 .9869l.0044.0044c.2725.2725.7143.2725.9868 0Z"
      fill="#A19A9A"
    />
    <path
      d="m71.2714 83.9259 4.952-4.952c.2725-.2725.2725-.7143 0-.9869l-.0044-.0044c-.2726-.2725-.7144-.2725-.9869 0l-4.952 4.952c-.2725.2725-.2725.7143 0 .9869l.0044.0044c.2726.2725.7144.2725.9869 0Z"
      fill="#8A8181"
    />
    <path
      d="m70.287 82.9415 4.952-4.952c.2725-.2725.2725-.7143 0-.9868l-.0044-.0044c-.2725-.2726-.7144-.2726-.9869 0l-4.9519 4.9519c-.2725.2725-.2725.7144 0 .9869l.0044.0044c.2725.2725.7143.2725.9868 0Z"
      fill="#A19A9A"
    />
    <path
      d="m69.2909 81.9551 4.952-4.9519c.2725-.2725.2725-.7144 0-.9869l-.0044-.0044c-.2725-.2725-.7144-.2725-.9869 0l-4.9519 4.952c-.2725.2725-.2725.7143 0 .9868l.0044.0044c.2725.2726.7143.2726.9868 0Z"
      fill="#8A8181"
    />
    <path
      d="m68.3046 80.9649 4.952-4.9519c.2725-.2726.2725-.7144 0-.9869l-.0044-.0044c-.2726-.2725-.7144-.2725-.9869 0l-4.952 4.9519c-.2725.2726-.2725.7144 0 .9869l.0045.0044c.2725.2725.7143.2725.9868 0Z"
      fill="#A19A9A"
    />
    <path
      d="m95.9797 88.9148-6.8508 6.8508 1.9781 1.9782 6.8508-6.8508-1.9781-1.9782Z"
      fill="#E7E2E2"
    />
    <path
      d="m98.9857 89.9518-8.8245 8.8245c-.5475.5475-.5485 1.4337-.0022 1.9807.5462.546 1.4329.545 1.9803-.003l8.8247-8.8241c.547-.5474.548-1.4341.002-1.9803-.546-.5463-1.4328-.5453-1.9803.0022Z"
      fill="#F7342B"
    />
    <path
      d="M116.728 90.5008 90.7098 116.519c-.3116.312-.8474.156-.9471-.274-1.5825-7.208.4299-15.046 6.0374-20.6539 5.6079-5.6075 13.4459-7.6199 20.6539-6.0374.43.0935.586.6293.274.9471Zm-4.035 23.3812c.774 0 1.402-.628 1.402-1.402 0-.774-.628-1.402-1.402-1.402-.774 0-1.402.628-1.402 1.402 0 .774.628 1.402 1.402 1.402Z"
      fill="#E7E2E2"
    />
  </svg>
</template>
