<template>
  <svg
    class="wave"
    viewBox="0 0 1920 215"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1920-250.275v415.262c-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.26-63.07-120 0-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.26-63.07-120 0-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.26-63.07-120 0-38.74 63.074-78.61 69.676-120 0-41.39-69.6722-81.258-63.07-120 0-38.742 63.074-78.608 69.676-120.002 0-41.391-69.6722-81.257-63.07-119.999 0-38.738 63.074-78.608 69.676-119.999 0-41.39-69.6722-81.26-63.07-119.998 0-38.742 63.074-78.608 69.676-120.002 0-41.391-69.6722-81.257-63.07-119.999 0-38.739 63.074-78.608 69.676-119.999 0-41.394-69.6722-81.2603-63.07-120.002 0v-415.262h1920Z"
      fill="#AA0C0C"
    />
  </svg>
</template>
