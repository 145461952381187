<template>
  <div class="menuContainer">
    <div v-for="msg in msgs" :key="msg.id" :ref="msgRef" class="menuMsg">
      <div :id="msg.id" class="menuMsg-title">{{ msg.title }}</div>
      <p :id="msg.id" class="menuMsg-des">{{ msg.text }}</p>
    </div>
    <div class="burgerMenus">
      <IntroButton
        class="menuBtn"
        @click="moveToIntro"
        @btn1Entered="btn1Entered"
        @btn1Leaved="btn1Leaved"
      />
      <DesignConceptButton
        class="menuBtn"
        @click="moveToConcept"
        @btn2Entered="btn2Entered"
        @btn2Leaved="btn2Leaved"
      />
      <ChabButton
        class="menuBtn"
        @click="moveToChab"
        @btn3Entered="btn3Entered"
        @btn3Leaved="btn3Leaved"
      />
      <DdagButton
        class="menuBtn"
        @click="moveToDdag"
        @btn4Entered="btn4Entered"
        @btn4Leaved="btn4Leaved"
      />
      <TryItButton
        class="menuBtn"
        @click="moveToTry"
        @btn5Entered="btn5Entered"
        @btn5Leaved="btn5Leaved"
      />
      <DownloadButton
        class="menuBtn"
        @click="moveToDownload"
        @btn6Entered="btn6Entered"
        @btn6Leaved="btn6Leaved"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { gsap } from "gsap";

import IntroButton from "../assets/menu/0-01-01_intro_button.vue";
import DesignConceptButton from "../assets/menu/0-01-02_designConcept_button.vue";
import ChabButton from "../assets/menu/0-01-03_chab_button.vue";
import DdagButton from "../assets/menu/0-01-04_ddag_button.vue";
import TryItButton from "../assets/menu/0-01-05_tryIt(menu).vue";
import DownloadButton from "../assets/menu/0-01-06_download_button.vue";

const msgArray = ref([]);
const msgRef = (el) => msgArray.value.push(el);
const msgs = [
  { title: "인트로", text: `롯데리아 촵딱 유니버스에\n여러분을 초대합니다.` },
  { title: "디자인 컨셉", text: "개척과 도전의\n롯데리아 스피릿" },
  {
    title: "촵땡겨체",
    text: "무엇이든 촵~땡겨버리는\n치명적인 폰트",
    id: "chabId",
  },
  { title: "딱붙어체", text: "어디에나 딱-붙어버리는\n매력적인 폰트" },
  { title: "폰트 맛보기", text: "한 번 쓰면\n멈출 수 없어!" },
  { title: "다운로드", text: "폰트도 테이크아웃이 되나요?" },
];

const emit = defineEmits([
  "moveToIntro",
  "moveToConcept",
  "moveToChab",
  "moveToDdag",
  "moveToTry",
  "moveToDownload",
]);
const moveToIntro = () => {
  emit("moveToIntro");
};
const moveToConcept = () => {
  emit("moveToConcept");
};
const moveToChab = () => {
  emit("moveToChab");
};
const moveToDdag = () => {
  emit("moveToDdag");
};
const moveToTry = () => {
  emit("moveToTry");
};
const moveToDownload = () => {
  emit("moveToDownload");
};

const btn1Entered = () => {
  gsap.to(msgArray.value[0], {
    opacity: 1,
  });
};
const btn1Leaved = () => {
  gsap.to(msgArray.value[0], {
    opacity: 0,
  });
};
const btn2Entered = () => {
  gsap.to(msgArray.value[1], {
    opacity: 1,
  });
};
const btn2Leaved = () => {
  gsap.to(msgArray.value[1], {
    opacity: 0,
  });
};
const btn3Entered = () => {
  gsap.to(msgArray.value[2], {
    opacity: 1,
  });
};
const btn3Leaved = () => {
  gsap.to(msgArray.value[2], {
    opacity: 0,
  });
};
const btn4Entered = () => {
  gsap.to(msgArray.value[3], {
    opacity: 1,
  });
};
const btn4Leaved = () => {
  gsap.to(msgArray.value[3], {
    opacity: 0,
  });
};
const btn5Entered = () => {
  gsap.to(msgArray.value[4], {
    opacity: 1,
  });
};
const btn5Leaved = () => {
  gsap.to(msgArray.value[4], {
    opacity: 0,
  });
};
const btn6Entered = () => {
  gsap.to(msgArray.value[5], {
    opacity: 1,
  });
};
const btn6Leaved = () => {
  gsap.to(msgArray.value[5], {
    opacity: 0,
  });
};
</script>

<style lang="scss" scoped>
.menuContainer {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: #edebeb;
  z-index: 9;
  .menuMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-family: "ddag";
    pointer-events: none;
    z-index: 2;
    opacity: 0;
    &-title {
      display: inline-block;
      padding: 0.5vw 3vw;
      margin-bottom: 16px;
      color: white;
      font-size: 3vw;
      background: #2e2828;
      border-radius: 1em;
    }
    &-des {
      white-space: pre-wrap;
      font-size: 5vw;
      line-height: 7vw;
    }
  }
  .burgerMenus {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 60%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media screen and (max-width: 480px) {
      & {
        width: 80%;
      }
    }
    .menuBtn {
      width: calc(100% / 3);
      @media screen and (max-width: 480px) {
        & {
          width: 50%;
        }
      }
    }
  }
}
#chabId {
  font-family: "chab";
}
</style>
