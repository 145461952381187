<template>
  <svg viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_334_6086)">
      <path
        d="M42.2029 74.6753c19.7052 0 35.6794-15.9742 35.6794-35.6795 0-19.7052-15.9742-35.67939-35.6794-35.67939S6.52344 19.2906 6.52344 38.9958c0 19.7053 15.97426 35.6795 35.67946 35.6795Z"
        fill="#FFA526"
      />
      <path
        opacity=".15"
        d="M67.4291 64.2216c-13.9303 13.9304-36.5226 13.9304-50.4599 0-13.93029-13.9303-13.93029-36.5226 0-50.4599"
        fill="#001235"
      />
      <path
        d="M65.5544 78.0819 2.48126 15.0087c-3.303138-3.3031-3.303138-8.66199 0-11.97209 3.30313-3.310108 8.66204-3.303139 11.97214 0L77.5266 66.1098c3.3031 3.3031 3.3031 8.662 0 11.9721-3.3032 3.3031-8.6621 3.3031-11.9722 0Z"
        fill="#F28518"
      />
    </g>
    <defs>
      <clipPath id="clip0_334_6086">
        <path fill="white" d="M0 .5625h80v80H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
