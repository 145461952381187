<template>
  <svg viewBox="0 0 960 261" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M602.14 175.98c-13.886-31.19-46.37-54.89-86.054-58.86-39.683-3.97-72.228 11.21-92.18 36.58 24.043-17.65 55.946-26.87 89.945-23.47 36.454 3.64 67.896 21.06 88.279 45.75h.01Z"
      fill="#768ABC"
    />
    <path
      d="M959.979 260.25c0-77.33-60.196-140.03-134.44-140.03-18.448 0-36.024 3.87-52.025 10.88-30.77-57.1699-89.513-95.8099-156.909-95.81-37.367 0-72.067 11.8801-100.812 32.2001-40.476-36.2-93.103-58.07006-150.633-58.07007-41.087 0-79.667 11.15997-113.114 30.71997C227.131 15.42 193.403.250017 156.286.250014 125.947.250011 97.8844 10.39 75.055 27.57 54.4814 10.47 28.3934.250002.019554.25l-.0000228 260H959.999h-.02Z"
      fill="#768ABC"
    />
    <path
      d="M879.13 260.25c-29.878-61.69-91.268-104-162.143-104-23.792 0-46.511 4.77-67.345 13.43-25.858-47.97-75.166-80.3799-131.753-80.38-31.382 0-60.528 9.97-84.671 27.04-33.998-30.4-78.203-48.77-126.519-48.77-34.52 0-66.925 9.37-95.008 25.8-20.934-20.77-49.258-33.5-80.429-33.5-25.487 0-49.0479 8.51-68.2278 22.94C45.7492 68.45 23.8321 59.87.00001752 59.87L0 260.25h879.13Z"
      fill="#6F7FB2"
    />
    <path
      d="M801.81 260.25c-25.998-26.69-64.438-43.58-107.3-43.58-28.714 0-55.434 7.58-77.762 20.6-30.279-34.86-77.753-57.31-131.122-57.31-23.351 0-45.568 4.29-65.721 12.04-32.394-34.55-81.161-56.54-135.713-56.54-37.458 0-72.198 10.38-100.783 28.05-36.515-23.79-81.582-37.85-130.3391-37.85-18.2876 0-36.0539 1.99-53.05817 5.72L.0117188 260.25H801.82h-.01Z"
      fill="#506194"
    />
    <path
      d="M325.817 125.68c-20.514-20.4-49.479-31.1703-79.748-26.7203-12.423 1.8303-23.943 6.0603-34.209 12.1803-15.049-13.9003-34.39-22.9903-55.976-24.7703-20.734-1.72-40.465 3.56-57.0285 13.9403 14.5775-3.1703 29.8175-4.2403 45.4085-2.9503 21.967 1.82 42.541 8.1703 60.948 18.1303 1.845.99 3.67 2.03 5.475 3.1 2.175-.73 4.371-1.41 6.597-2.03 6.407-1.82 12.994-3.24 19.741-4.23 31.392-4.62 61.942.58 88.792 13.35Z"
      fill="#768ABC"
    />
  </svg>
</template>
