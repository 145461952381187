<template>
  <div class="container">
    <div class="ddagTitle" ref="ddagTitleRef">
      <div ref="bubbleWrapperRef" class="ddagTitle-bubbleWrapper">
        <img
          v-for="ddagBubble in ddagBubbles"
          :key="ddagBubble.id"
          :ref="bubbleRef"
          class="ddagTitle-bubble"
          :src="ddagBubble.src"
          :alt="`ddag${ddagBubble.id}`"
        />
      </div>
      <img
        ref="ddagTitleImgRef"
        class="ddagTitle-sign"
        src="../assets/ddag/ddagTitle.webp"
        alt="ddagTitle"
      />
      <p class="ddagTitle-des">어떤 재료건 딱-붙이는 도전정신의 총체</p>
      <DdagSign class="ddagTitle-sign2" />
      <div class="ddagTitle-des2">
        <p>글자 구성</p>
        <p>한글 2,780자</p>
        <p>영문 94자</p>
        <p>특수문자 991자</p>
        <p>딩벳 6자</p>
        <p>-</p>
        <p>총 3,871자</p>
        <p><br /></p>
      </div>
    </div>
    <div class="ddagWrapper">
      <div class="menu">
        <div
          class="menu-des"
          :class="showDes ? 'selected' : ''"
          @click="selectDes"
        >
          디자인 특징
        </div>
        <div
          class="menu-bat"
          :class="showBat ? 'selected' : ''"
          @click="selectBat"
        >
          특별한 딩벳
        </div>
        <div
          class="menu-pre"
          :class="showPre ? 'selected' : ''"
          @click="selectPre"
        >
          글자 견본
        </div>
      </div>
      <!-- 디자인 특징 -->
      <div v-show="showDes" class="board">
        <div class="ddagDesVideoWrapper">
          <div class="ddagDesVideoWrapper-size">
            <img
              v-if="isIos"
              src="../assets/ddag/4-03-01_feature(1).png"
              :style="ddagDropDownArray[0] ? { opacity: 1 } : { opacity: 0 }"
            />
            <video
              v-else
              src="../assets/ddag/4-03-01_feature(1).webm"
              :style="ddagDropDownArray[0] ? { opacity: 1 } : { opacity: 0 }"
              loop
              muted
              playsinline
            />
            <img
              v-if="isIos"
              src="../assets/ddag/4-03-02_feature(2).png"
              :style="ddagDropDownArray[1] ? { opacity: 1 } : { opacity: 0 }"
            />
            <video
              v-else
              src="../assets/ddag/4-03-02_feature(2).webm"
              :style="ddagDropDownArray[1] ? { opacity: 1 } : { opacity: 0 }"
              loop
              muted
              playsinline
            />
            <img
              v-if="isIos"
              src="../assets/ddag/4-03-03_feature(3).png"
              :style="ddagDropDownArray[2] ? { opacity: 1 } : { opacity: 0 }"
            />
            <video
              v-else
              src="../assets/ddag/4-03-03_feature(3).webm"
              :style="ddagDropDownArray[2] ? { opacity: 1 } : { opacity: 0 }"
              loop
              muted
              playsinline
            />
            <img
              v-if="isIos"
              src="../assets/ddag/4-03-04_feature(4).png"
              :style="ddagDropDownArray[3] ? { opacity: 1 } : { opacity: 0 }"
            />
            <video
              v-else
              src="../assets/ddag/4-03-04_feature(4).webm"
              :style="ddagDropDownArray[3] ? { opacity: 1 } : { opacity: 0 }"
              loop
              muted
              playsinline
            />
          </div>
        </div>
        <div class="ddagDesWrapper">
          <div class="ddagDropDown">
            <transition-group name="ddagDropDownFade">
              <div
                v-for="(ddagDropDownItem, i) in ddagDropDownItems"
                :key="ddagDropDownItem.title"
                :ref="ddagDropDownRef"
                class="ddagDropDown-item"
                :class="ddagDropDownArray[i] ? 'selected' : ''"
              >
                <div class="ddagDropDown-item-title" @click="selectDropDown(i)">
                  <p>{{ ddagDropDownItem.title }}</p>
                  <img
                    src="../assets/dropdownDdagClosed.png"
                    :style="ddagDropDownArray[i] ? 'opacity: 0' : ''"
                    alt="dropdownArrow"
                  />
                </div>
                <p v-show="ddagDropDownArray[i]" class="ddagDropDown-item-des">
                  {{ ddagDropDownItem.des }}
                </p>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <!-- 특별한 딩벳 -->
      <div v-show="showBat" class="board">
        <div class="dingbat">
          <div
            v-for="dingbatImg in dingbatImgs"
            :key="dingbatImg.id"
            class="dingbatImg"
            :class="dingbatImg.class"
            :ref="dingbatRef"
          >
            <p :ref="dingbatTextRef">
              {{ dingbatImg.ligature }}
            </p>
          </div>
          <div class="swapSwitch">
            <p class="swapSwitch-title">
              버튼을 눌러 합자 기능을 활용해 딩벳을 사용해보세요!
            </p>
            <div
              ref="dingbatSwitch"
              class="swapSwitch-switch"
              @click="switchDingbat"
            >
              <div ref="dingbatBall" class="swapSwitch-switch-ball" />
            </div>
            <div class="dingbatBurgerWrapper">
              <img
                src="../assets/ddag/ddagBack.webp"
                ref="dingbatBackRef"
                class="dingbatBurgerWrapper-back"
              />
              <DingbatBurger />
            </div>
          </div>
        </div>
      </div>
      <!-- 글자 견본 -->
      <div v-show="showPre" class="board">
        <div class="sample">
          <div
            v-for="(letter, i) in literal"
            :key="letter.id"
            :ref="letterRef"
            :data-title="letter.word"
            class="sample-block"
            @mousemove="previewHover(i)"
          >
            {{ letter.word }}
          </div>
        </div>
        <div class="circleWrapper">
          <div class="circle">
            {{ focusWord }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { gsap } from "gsap";

import DdagSign from "../assets/ddag/DdagSign.vue";
import DingbatBurger from "../assets/chab/dingbatBurger.vue";

const setX = ref(0);
const setY = ref(0);

const bubbleWrapperRef = ref();
const bubbleArray = ref([]);
const bubbleRef = (el) => bubbleArray.value.push(el);
const ddagBubbles = ref([
  { src: require("../assets/ddag/ddag_01.webp") },
  { src: require("../assets/ddag/ddag_02.webp") },
  { src: require("../assets/ddag/ddag_03.webp") },
  { src: require("../assets/ddag/ddag_04.webp") },
  { src: require("../assets/ddag/ddag_05.webp") },
]);

const focusWord = ref("");
const letterArray = ref([]);
const letterRef = (el) => letterArray.value.push(el);
const literal = [
  { word: "Aa" },
  { word: "Bb" },
  { word: "Cc" },
  { word: "Dd" },
  { word: "Ee" },
  { word: "Ff" },
  { word: "Gg" },
  { word: "Hh" },
  { word: "Ii" },
  { word: "Jj" },
  { word: "Kk" },
  { word: "Ll" },
  { word: "Mm" },
  { word: "Nn" },
  { word: "Oo" },
  { word: "Pp" },
  { word: "Qq" },
  { word: "Rr" },
  { word: "Ss" },
  { word: "Tt" },
  { word: "Uu" },
  { word: "Vv" },
  { word: "Ww" },
  { word: "Xx" },
  { word: "Yy" },
  { word: "Zz" },
  { word: "!" },
  { word: "?" },
  { word: "&" },
  { word: "@" },
  { word: "1" },
  { word: "2" },
  { word: "3" },
  { word: "4" },
  { word: "5" },
  { word: "6" },
  { word: "7" },
  { word: "8" },
  { word: "9" },
  { word: "0" },
  { word: "가" },
  { word: "나" },
  { word: "다" },
  { word: "라" },
  { word: "마" },
  { word: "바" },
  { word: "사" },
  { word: "아" },
  { word: "자" },
  { word: "차" },
  { word: "카" },
  { word: "타" },
  { word: "파" },
  { word: "하" },
  { word: "." },
];

const previewHover = (i) => {
  letterArray.value.map((word) => {
    word.style.background = "#21c11a";
  });
  letterArray.value[i].style.background = "#008d1d";
  focusWord.value = letterArray.value[i].getAttribute("data-title");
};

const showDes = ref(true);
const showBat = ref(false);
const showPre = ref(false);
const selectDes = () => {
  if (!showDes.value) {
    showBat.value = false;
    showPre.value = false;
    showDes.value = true;
  }
};
const selectBat = () => {
  if (!showBat.value) {
    showDes.value = false;
    showPre.value = false;
    showBat.value = true;
  }
};
const selectPre = () => {
  if (!showPre.value) {
    showDes.value = false;
    showBat.value = false;
    showPre.value = true;
  }
};

const ddagDropDownArray = ref([]);
const ddagDropDownRef = (el) => ddagDropDownArray.value.push(el);
const ddagDropDownItems = [
  {
    title: "햄버거를 닮은 글자",
    des: "획들이 넘치도록 쌓고 또 쌓았습니다. 가로획을 차곡차곡 쌓아 올려 햄버거의 정체성을 표현하는 글자입니다.",
  },
  {
    title: "관심을 바라는 글자",
    des: "엉뚱하게 행동하면 관심 받을 줄 알았습니다. ㅂ의 x, E의 가로획, # 등 엉뚱한 시도로 관심을 갈구하는 글자입니다.",
  },
  {
    title: "꽉꽉 채워 만든 글자",
    des: "재료와 자신감은 가득 찰수록 좋습니다. 꽉 찬 모듈로 존재감을 입증하는 글자입니다.",
  },
  {
    title: "아무나 못 쓰는 글자",
    des: "여러분의 도전을 기다립니다. 쓰는 것도 읽는 것도 쉽지 않아 도전정신을 자극하는 글자입니다.",
  },
];
ddagDropDownArray.value[0] = true;
ddagDropDownArray.value[1] = false;
ddagDropDownArray.value[2] = false;
ddagDropDownArray.value[3] = false;

const selectDropDown = (i) => {
  for (let j = 0; j < ddagDropDownArray.value.length; j++) {
    ddagDropDownArray.value[j] = false;
  }
  ddagDropDownArray.value[i] = !ddagDropDownArray.value[i];
};

// dingbat
const dingbatImgs = [
  { class: "img1", ligature: "*딱" },
  { class: "img2", ligature: "*화이팅" },
  { class: "img3", ligature: "*엄지척" },
  { class: "img4", ligature: "*행복딱" },
  { class: "img5", ligature: "*롯데리아" },
  { class: "img6", ligature: "*포테이토" },
];

const dingbatBackRef = ref();
const dingbatSwitch = ref();
const onDingbat = ref(false);
const dingbatArray = ref([]);
const dingbatRef = (el) => dingbatArray.value.push(el);
const dingbatTextArray = ref([]);
const dingbatTextRef = (el) => dingbatTextArray.value.push(el);
const switchDingbat = () => {
  if (!onDingbat.value) {
    dingbatArray.value.map((item) => (item.style.fontFeatureSettings = ""));
    if (matchMedia("(max-width: 480px)").matches) {
      dingbatTextArray.value[0].style.fontSize = "13vw";
      dingbatTextArray.value[1].style.fontSize = "13vw";
      dingbatTextArray.value[2].style.fontSize = "7.5vw";
      dingbatTextArray.value[3].style.fontSize = "6.5vw";
      dingbatTextArray.value[4].style.fontSize = "13vw";
      dingbatTextArray.value[5].style.fontSize = "13vw";
    } else {
      dingbatTextArray.value.map((item) => (item.style.fontSize = "4vw"));
    }
    gsap.to("#sunglass", { y: 0, duration: 0.3, ease: "power2" });
    gsap.to(dingbatBall.value, { x: "215%", duration: 0.3, ease: "power2" });
    gsap.to(dingbatSwitch.value, {
      background: "#FFD600",
      duration: 0.3,
      ease: "power2",
    });
    gsap.to(dingbatBackRef.value, {
      opacity: 1,
      duration: 0.3,
      ease: "power2",
    });
    onDingbat.value = true;
  } else {
    dingbatArray.value.map(
      (item) => (item.style.fontFeatureSettings = "normal")
    );

    if (matchMedia("(max-width: 480px)").matches) {
      dingbatTextArray.value.map((item) => (item.style.fontSize = "6vw"));
    }
    gsap.to("#sunglass", { y: "-170%", duration: 0.3, ease: "power2" });
    gsap.to(dingbatBall.value, { x: "20%", duration: 0.3, ease: "power2" });
    gsap.to(dingbatSwitch.value, {
      background: "#DED6D6",
      duration: 0.3,
      ease: "power2",
    });
    gsap.to(dingbatBackRef.value, {
      opacity: 0,
      duration: 0.3,
      ease: "power2",
    });
    onDingbat.value = false;
  }
};

const ddagTitleImgRef = ref();
const ddagTitleRef = ref();
const dingbatBall = ref();
const setRandomBubble = (i) => {
  gsap.set(bubbleArray.value[i], {
    x: gsap.utils.random(0, setX.value - setX.value / 5, 1),
  });
};

const bubbleAnimation = () => {
  for (let i = 0; i < 5; i++) {
    gsap.from(bubbleArray.value[i], {
      xPercent: gsap.utils.random(-50, 50, 1),
      y: setY.value,
      opacity: 1,
      duration: gsap.utils.random(5, 8, 0.5),
      rotate: gsap.utils.random(-45, 45, 1),
      onComplete() {
        gsap.delayedCall(gsap.utils.random(0, 1, 0.1), () => {
          this.restart();
          setRandomBubble(i);
        });
      },
    });
  }
};

const isIos = ref(false);
const ua = navigator.userAgent.toLowerCase();
let isIpad =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

onMounted(() => {
  if (
    ua.indexOf("iphone") > -1 ||
    ua.indexOf("ipad") > -1 ||
    ua.indexOf("ipod") > -1 ||
    isIpad
  ) {
    isIos.value = true;
  }

  dingbatArray.value.map((item) => (item.style.fontFeatureSettings = "normal"));
  gsap.set("#sunglass", {
    y: "-170%",
  });
  gsap.to(ddagTitleImgRef.value, {
    rotate: -30,
    duration: 0,
    repeat: -1,
    repeatDelay: 0.8,
  });
  gsap.to(ddagTitleImgRef.value, {
    rotate: 0,
    duration: 0,
    delay: 0.4,
    repeat: -1,
    repeatDelay: 0.8,
  });

  gsap.to("#ddagSign1", {
    fill: "#21C11A",
    duration: 0,
    repeatDelay: 0.3,
    repeat: -1,
  });
  gsap.to("#ddagSign1", {
    fill: "#FFD600",
    duration: 0,
    delay: 0.3,
    repeatDelay: 0.6,
    repeat: -1,
  });

  gsap.to("#ddagSign2", {
    fill: "#FFD600",
    duration: 0,
    repeatDelay: 0.3,
    repeat: -1,
  });
  gsap.to("#ddagSign2", {
    fill: "#21C11A",
    duration: 0,
    delay: 0.3,
    repeatDelay: 0.6,
    repeat: -1,
  });

  setTimeout(() => {
    setX.value = ddagTitleRef.value.offsetWidth;
    setY.value = ddagTitleRef.value.offsetHeight;
    bubbleWrapperRef.value.style.height = `${setY.value}px`;
    bubbleAnimation();
  }, 1000);

  window.addEventListener("resize", () => {
    setX.value = ddagTitleRef.value.offsetWidth;
    setY.value = ddagTitleRef.value.offsetHeight;
    bubbleWrapperRef.value.style.height = `${setY.value}px`;
    ddagTitleImgRef.value.style.transform = "translate3d(-50%, 0, 0)";

    if (matchMedia("(max-width: 480px)").matches) {
      if (onDingbat.value) {
        dingbatTextArray.value[0].style.fontSize = "13vw";
        dingbatTextArray.value[1].style.fontSize = "13vw";
        dingbatTextArray.value[2].style.fontSize = "7.5vw";
        dingbatTextArray.value[3].style.fontSize = "6.5vw";
        dingbatTextArray.value[4].style.fontSize = "13vw";
        dingbatTextArray.value[5].style.fontSize = "13vw";
      } else {
        dingbatTextArray.value.map((item) => (item.style.fontSize = "6vw"));
      }
    } else {
      if (!onDingbat.value) {
        dingbatTextArray.value.map((item) => (item.style.fontSize = "4vw"));
      }
    }
  });
});
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: #e3f8e2;
  font-family: "ddag";
  z-index: 1;
  .ddagTitle {
    width: 100%;
    padding-top: 10vw;
    overflow: hidden;
    &-bubbleWrapper {
      position: absolute;
      width: 100%;
      height: 120vw;
      overflow: hidden;
      img {
        position: absolute;
        width: 18.75%;
        opacity: 0;
        pointer-events: none;
        z-index: 0;
      }
    }
    &-sign {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      width: 43.75%;
      margin-bottom: 6vw;
      z-index: 2;
      @media screen and (max-width: 480px) {
        & {
          margin-bottom: 4vw;
        }
      }
    }
    &-des {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      display: inline-block;
      width: 70%;
      background: #21c11a;
      color: white;
      border: 0.5vw solid #1e8431;
      border-radius: 5rem;
      box-sizing: border-box;
      font-size: 3vw;
      padding: 0.3vw 0;
      margin-bottom: 1vw;
      z-index: 2;
      @media screen and (max-width: 480px) {
        & {
          width: 90%;
          font-size: 4vw;
          padding: 1.5vw 0;
          margin: 6vw 0;
        }
      }
    }
    &-sign2 {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      width: 47%;
      margin: 5vw 0;
    }
    &-des2 {
      position: relative;
      font-size: 2vw;
      color: #1e8431;
      letter-spacing: 0.075rem;
      text-align: center;
      line-height: 3.5vw;
      z-index: 2;
      @media screen and (max-width: 480px) {
        & {
          font-size: 4vw;
          line-height: 5vw;
        }
      }
      p {
        @media screen and (max-width: 480px) {
          & {
            margin-bottom: 2vw;
          }
        }
      }
      p:nth-child(1) {
        position: relative;
        background: #1e8431;
        color: white;
        display: inline-block;
        border-radius: 5rem;
        padding: 0.5vw 1vw;
        line-height: 2vw;
        margin-top: 3vw;
        margin-bottom: 2vw;
        @media screen and (max-width: 480px) {
          & {
            font-size: 5vw;
            padding: 3.5vw 6vw;
            margin-top: 10vw;
            margin-bottom: 4vw;
          }
        }
      }
      p:last-child {
        margin-bottom: 5vw;
      }
    }
  }
  .ddagWrapper {
    position: relative;
    top: 0;
    width: 97%;
    margin: 0 1.5%;
    padding-bottom: 1.5%;
    .menu {
      display: flex;
      height: 5vw;
      color: white;
      @media screen and (max-width: 480px) {
        & {
          height: 10vw;
        }
      }
      &-des,
      &-bat,
      &-pre {
        z-index: 1;
        width: 100%;
        height: 100%;
        text-align: center;
        border-radius: 1.2em 1.2em 0 0;
        background: #1e8431;
        line-height: 5vw;
        font-size: 2vw;
        cursor: pointer;
        &.selected {
          background: #21c11a;
        }
        @media screen and (max-width: 480px) {
          & {
            font-size: 3.5vw;
            line-height: 10vw;
            letter-spacing: 0.075rem;
          }
        }
      }
    }
    .board {
      display: flex;
      background: #21c11a;
      @media screen and (max-width: 480px) {
        &:nth-child(2) {
          flex-direction: column;
        }
        &:nth-child(4) {
          flex-direction: column-reverse;
        }
      }
      .ddagDesVideoWrapper {
        width: 50%;
        @media screen and (max-width: 480px) {
          & {
            width: 100%;
          }
        }
        &-size {
          position: relative;
          width: 95%;
          padding-bottom: 95%;
          height: 0;
          margin: 2.5%;
          background: #e3f8e2;
          vertical-align: bottom;
          img {
            position: absolute;
            width: 100%;
            vertical-align: bottom;
          }
          video {
            position: absolute;
            width: 100%;
            vertical-align: bottom;
          }
        }
      }
      .ddagDesWrapper {
        width: 50%;
        overflow: hidden;
        @media screen and (max-width: 480px) {
          & {
            width: 100%;
          }
        }
        .ddagDropDown {
          width: 95%;
          margin: 2.5%;
          &-item {
            width: 100%;
            color: #1e8431;
            transition: 0.3s, color 0s;
            &.selected {
              color: white;
            }
            &-title {
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              border-top: 1px solid white;
              p {
                font-size: 4vw;
                margin: 1.5vw 0 1.5vw 0;
                word-break: keep-all;
                @media screen and (max-width: 480px) {
                  & {
                    font-size: 6vw;
                  }
                }
              }
              img {
                position: relative;
                width: 3vw;
                height: 3vw;
                margin-top: 2.8vw;
                vertical-align: bottom;
                @media screen and (max-width: 480px) {
                  & {
                    width: 6vw;
                    height: 6vw;
                  }
                }
              }
            }
            &-des {
              font-size: 2vw;
              letter-spacing: 0.075rem;
              margin: 0 10px 1.5vw 0;
              @media screen and (max-width: 480px) {
                & {
                  font-size: 4vw;
                }
              }
            }
          }
        }
      }
      .dingbat {
        position: relative;
        width: 100vw;
        height: calc(50vw - 7.5px);
        left: -1px;
        top: -1px;
        overflow: hidden;
        color: white;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          "a g g d"
          "b g g e"
          "c g g f";
        @media screen and (max-width: 480px) {
          left: 0;
          height: calc(var(--vh, 1vh) * 90);
          & {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(5, 1fr);
            grid-template-areas:
              "a b c"
              "d e f"
              "g g g"
              "g g g"
              "g g g";
          }
        }
        .dingbatImg {
          $imgCount: 6;
          font-feature-settings: "liga";
          font-size: 4vw;
          letter-spacing: 0.075rem;
          @for $i from 1 through $imgCount {
            &:nth-child(#{$i}) {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              top: 1px;
              left: 1px;
              margin-left: -1px;
              margin-top: -1px;
              border: 1px solid white;
              box-sizing: border-box;
              @media screen and (max-width: 480px) {
                & {
                  display: block;
                  top: 50%;
                  left: calc(50% + 1px);
                  transform: translate(-50%, -50%);
                  width: calc(100% + 1px);
                  margin-left: -1px;
                  p {
                    position: relative;
                    top: 50%;
                    transform: translate(0, -50%);
                    text-align: center;
                    @media screen and (max-width: 480px) {
                      & {
                        font-size: 6vw;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .img1 {
          grid-area: a;
        }
        .img2 {
          grid-area: b;
        }
        .img3 {
          grid-area: c;
        }
        .img4 {
          grid-area: d;
        }
        .img5 {
          grid-area: e;
        }
        .img6 {
          grid-area: f;
        }
        .swapSwitch {
          grid-area: g;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &-title {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            word-break: keep-all;
            width: 40%;
            height: calc(100% / 3);
            font-size: 2vw;
            letter-spacing: 0.075rem;
            z-index: 2;
            @media screen and (max-width: 480px) {
              & {
                width: 70%;
                font-size: 4vw;
              }
            }
          }
          &-switch {
            width: 23.75vw;
            height: 10vw;
            background: #ded6d6;
            border-radius: 10vw;
            cursor: pointer;
            z-index: 2;
            @media screen and (max-width: 480px) {
              & {
                width: 50vw;
                height: 21vw;
              }
            }
            &-ball {
              position: relative;
              top: 50%;
              transform: translate(20%, -50%);
              width: 30%;
              height: 0;
              padding-bottom: 30%;
              border-radius: 50%;
              background: white;
            }
          }
          .dingbatBurgerWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            &-back {
              position: absolute;
              transform: translate(-50%, 0);
              vertical-align: bottom;
              width: calc(50% - 1px);
              height: 100%;
              left: 50%;
              opacity: 0;
              pointer-events: none;
              z-index: 1;
              @media screen and (max-width: 480px) {
                & {
                  left: calc(50% + 1px);
                  width: 100%;
                  height: 60%;
                }
              }
            }
            svg {
              position: relative;
              width: 40%;
              vertical-align: bottom;
              z-index: 2;
            }
          }
        }
      }
      .sample {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        left: -1px;
        top: -1px;
        @media screen and (max-width: 480px) {
          & {
            width: 100%;
          }
        }
        &-block {
          width: 20%;
          height: calc(10vw - 0.5px);
          position: relative;
          top: 1px;
          left: 1.5px;
          margin-left: -1px;
          margin-top: -1px;
          line-height: calc(10vw - 0.5px);
          text-align: center;
          font-size: 4.5vw;
          color: white;
          background: #21c11a;
          border: 1px solid #7ddf8e;
          box-sizing: border-box;
          @media screen and (max-width: 480px) {
            & {
              top: 0;
              width: calc(20% + 1px);
              height: calc(20vw - 0.5px);
              line-height: calc(20vw - 0.5px);
            }
          }
        }
      }
      .circleWrapper {
        position: sticky;
        width: 50%;
        height: 100%;
        top: 5%;
        background: #21c11a;
        @media screen and (max-width: 900px) {
          & {
            top: 30vw;
          }
        }
        @media screen and (max-width: 480px) {
          & {
            top: 0;
            width: 100%;
          }
        }
        .circle {
          position: relative;
          left: 50%;
          transform: translate(-50%, 0);
          width: 95%;
          height: 0;
          padding-bottom: 95%;
          margin: 10px 0;
          border-radius: 50%;
          text-align: center;
          line-height: 47vw;
          font-size: 18vw;
          background: white;
          color: #2e2828;
          @media screen and (max-width: 480px) {
            & {
              line-height: 94vw;
              font-size: 36vw;
            }
          }
        }
      }
    }
  }
}

.ddagDropDownFade-leave-active {
  position: absolute;
}
</style>
