<template>
  <div class="experienceContainer">
    <div class="experienceWrapper">
      <div
        id="topMenu"
        contenteditable="true"
        ref="experienceInputRef1"
        type="text"
        class="experienceInput"
      >
        {{ inputModel }}
      </div>
      <div class="experienceMenu">
        <div class="experienceMenu-item">
          <div
            class="experienceMenu-item-selectFont"
            :class="chabValue ? 'selected' : ''"
            @click="selectChab"
          >
            <p>촵땡겨체</p>
          </div>
          <div
            class="experienceMenu-item-selectFont"
            :class="ddagValue ? 'selected' : ''"
            @click="selectDdag"
          >
            <p>딱붙어체</p>
          </div>
        </div>
        <div class="experienceMenu-item">
          <input v-model="sizeValue" type="range" />
        </div>
        <div class="experienceMenu-item">
          <span
            v-for="(color, i) in colors"
            :key="color.id"
            :ref="colorRef"
            :style="{ background: color.background }"
            @click="color.selectColor(i)"
          />
        </div>
      </div>
      <div
        id="bottomMenu"
        contenteditable="true"
        ref="experienceInputRef2"
        type="text"
        class="experienceInput"
      >
        {{ inputModel }}
      </div>
    </div>
    <div class="burgerWrapper">
      <ExperienceBurger class="burgerSvg" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import gsap from "gsap";
import ExperienceBurger from "../assets/experience/ExperienceBurger.vue";

const inputModel = ref("처음 버거 반한 맛!");
const sizeValue = ref(50);
const experienceInputRef1 = ref();
const experienceInputRef2 = ref();

const colorArray = ref([]);
const colorRef = (el) => colorArray.value.push(el);
const colors = [
  {
    background: "#2e2828",
    selectColor: (i) => {
      changeColor("2e2828");
      for (let j = 0; j < colorArray.value.length; j++) {
        colorArray.value[j].style.boxShadow = "";
      }
      colorArray.value[i].style.boxShadow = "0 0 0 2px #000000";
    },
  },
  {
    background: "#ce5b0d",
    selectColor: (i) => {
      changeColor("ce5b0d");
      for (let j = 0; j < colorArray.value.length; j++) {
        colorArray.value[j].style.boxShadow = "";
      }
      colorArray.value[i].style.boxShadow = "0 0 0 2px #000000";
    },
  },
  {
    background: "#ffa526",
    selectColor: (i) => {
      changeColor("ffa526");
      for (let j = 0; j < colorArray.value.length; j++) {
        colorArray.value[j].style.boxShadow = "";
      }
      colorArray.value[i].style.boxShadow = "0 0 0 2px #000000";
    },
  },
  {
    background: "#0068ff",
    selectColor: (i) => {
      changeColor("0068ff");
      for (let j = 0; j < colorArray.value.length; j++) {
        colorArray.value[j].style.boxShadow = "";
      }
      colorArray.value[i].style.boxShadow = "0 0 0 2px #000000";
    },
  },
  {
    background: "#f7342b",
    selectColor: (i) => {
      changeColor("f7342b");
      for (let j = 0; j < colorArray.value.length; j++) {
        colorArray.value[j].style.boxShadow = "";
      }
      colorArray.value[i].style.boxShadow = "0 0 0 2px #000000";
    },
  },
  {
    background: "#1fb118",
    selectColor: (i) => {
      changeColor("1fb118");
      for (let j = 0; j < colorArray.value.length; j++) {
        colorArray.value[j].style.boxShadow = "";
      }
      colorArray.value[i].style.boxShadow = "0 0 0 2px #000000";
    },
  },
];

const chabValue = ref(true);
const ddagValue = ref(false);
const selectChab = () => {
  if (!chabValue.value) {
    ddagValue.value = false;
    chabValue.value = true;
    experienceInputRef1.value.style.fontFamily = "chab";
    experienceInputRef2.value.style.fontFamily = "chab";
  }
};
const selectDdag = () => {
  if (!ddagValue.value) {
    chabValue.value = false;
    ddagValue.value = true;
    experienceInputRef1.value.style.fontFamily = "ddag";
    experienceInputRef2.value.style.fontFamily = "ddag";
  }
};

const changeColor = (color) => {
  experienceInputRef1.value.style.color = `#${color}`;
  experienceInputRef2.value.style.color = `#${color}`;
};

watch(sizeValue, () => {
  if (sizeValue.value > 0) {
    experienceInputRef1.value.style.fontSize = `${sizeValue.value / 5}vw`;
    experienceInputRef2.value.style.fontSize = `${sizeValue.value / 10}vw`;
  }
});

onMounted(() => {
  colorArray.value[0].style.boxShadow = "0 0 0 2px #000000";
  gsap.to("#exL", {
    y: "150%",
    duration: 0.2,
    repeat: -1,
    yoyo: true,
    ease: "none",
  });
  gsap.to("#exR", {
    y: "150%",
    duration: 0.2,
    delay: 0.2,
    repeat: -1,
    yoyo: true,
    ease: "none",
  });
});
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "chab";
  src: url("../assets/font/chab.woff2") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "ddag";
  src: url("../assets/font/ddag.woff2") format("truetype");
  font-style: normal;
}
.experienceContainer {
  width: 100%;
  height: 100%;
  background: #f9f9d7;
  overflow: hidden;
  .experienceWrapper {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    width: 90vw;
    background: #f9f9d7;
    margin: 10vw 0;
    border-radius: 2rem 2rem 2rem 2rem;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    @media screen and (max-width: 1280px) {
      margin: 4vw 0;
    }
    @media screen and (max-width: 480px) {
      & {
        width: 100vw;
        margin: 0;
        background: white;
      }
      #topMenu {
        border-radius: 0 0 0 0;
        box-shadow: none;
      }
      #bottomMenu {
        display: none;
      }
    }
    @media screen and (min-width: 481px) {
      #topMenu {
        display: none;
      }
    }
    .experienceMenu {
      width: 100%;
      display: flex;
      justify-content: space-around;
      background: #c0c683;
      border-radius: 2rem 2rem 0 0;
      padding-bottom: 1.6vw;
      @media screen and (max-width: 480px) {
        & {
          position: relative;
          display: block;
          padding-bottom: 4vw;
        }
      }
      &-item {
        width: 27%;
        height: 3.4vw;
        margin-top: 1.7vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 10vw;
        &:nth-child(3) {
          justify-content: center;
          gap: 6%;
          margin-bottom: 0;
        }
        @media screen and (max-width: 480px) {
          & {
            justify-content: space-between;
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            width: 90%;
            height: calc(var(--vh, 1vh) * 5);
            margin-top: 0;
            margin-bottom: 4vw;
          }
          &:nth-child(1) {
            padding-top: 4vw;
          }
        }
        input {
          cursor: pointer;
        }
        input[type="range"] {
          width: 80%;
          border-radius: 8px;
          outline: none;
          appearance: none;
          background: rgb(217, 217, 217);
          accent-color: rgb(213, 213, 213);
          @media screen and (max-width: 480px) {
            & {
              position: relative;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }

        input[type="range"]::-webkit-slider-thumb {
          margin-top: -6px;
        }
        input[type="range"]::-webkit-slider-runnable-track {
          width: 10px;
          height: 4px;
        }
        &-selectFont {
          width: 40%;
          height: 100%;
          line-height: 3.5vw;
          background: white;
          border-radius: 10vw;
          text-align: center;
          font-size: 1.5vw;
          cursor: pointer;
          @media screen and (max-width: 480px) {
            & {
              width: 48%;
              font-size: 5vw;
              line-height: calc(var(--vh, 1vh) * 5);
              padding: 0;
            }
          }
          @media screen and (min-width: 1281px) {
            & {
              padding: 0 10px;
            }
          }
          &.selected {
            p {
              opacity: 1;
            }
          }
          &:nth-child(1) {
            font-family: "chab";
            color: #f7342b;
          }
          &:nth-child(2) {
            font-family: "ddag";
            color: #1fb118;
          }
          p {
            opacity: 0.4;
          }
        }
        span {
          width: 2vw;
          height: 2vw;
          border-radius: 50%;
          cursor: pointer;
          border: 1px solid white;
          @media screen and (max-width: 480px) {
            & {
              width: 5vw;
              height: 5vw;
              border: 2px solid white;
            }
          }
        }
        .customColor {
          position: absolute;
          transform: translate(0, 80%);
          right: 2vw;
          width: 40px;
          height: 40px;
          border: none;
          margin: 0;
          background: transparent;
        }
      }
      &-item:not(:first-child) {
        background: white;
      }
    }
    .experienceInput {
      width: 90vw;
      height: 45vw;
      font-size: 5vw;
      text-align: center;
      vertical-align: middle;
      display: table-cell;
      font-family: "chab";
      border: none;
      border-radius: 0 0 2rem 2rem;
      padding: 0;
      background: white;
      font-feature-settings: "liga";
      resize: none;
      overflow: hidden;
      &:focus {
        outline-style: none;
      }
      @media screen and (max-width: 480px) {
        & {
          width: 100vw;
          height: calc(var(--vh, 1vh) * 75);
          font-size: 10vw;
        }
      }
    }
  }
  .burgerWrapper {
    position: relative;
    width: 26vw;
    top: -14.5vw;
    left: -3vw;
    .burgerSvg {
      position: absolute;
      width: 100%;
      @media screen and (max-width: 480px) {
        & {
          display: none;
        }
      }
    }
  }
}
</style>
