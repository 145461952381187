<template>
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 48c13.2546 0 24-10.7454 24-24S37.2546-.00001067 24-.00001183C10.7454-.000013.00000458 10.7454.00000342 24 .00000226 37.2546 10.7454 48 24 48Zm0-43.07935c10.537 0 19.0775 8.54235 19.0775 19.07755 0 10.5352-8.5405 19.0775-19.0775 19.0775S4.92246 34.5352 4.92246 23.9982 13.463 4.92065 24 4.92065Z"
      fill="#8E0606"
    />
    <path
      d="m13.5028 25.0365 2.5128-2.2175c.462-.4077 1.1686-.3642 1.578.0978l3.6634 4.2376V11.4196c0-.6178.5-1.1179 1.1178-1.1179h3.3535c.616 0 1.116.5001 1.116 1.1179v15.6171l3.5619-4.1199c.4076-.462 1.1142-.5055 1.578-.0978l2.5129 2.2175c.462.4095.5073 1.1161.0978 1.5781l-9.758 11.2055c-.4438.5055-1.2301.5055-1.6758 0l-9.758-11.2055c-.4076-.462-.3641-1.1686.0997-1.5781Z"
      fill="#8E0606"
    />
  </svg>
</template>
