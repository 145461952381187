<template>
  <svg
    ref="twinkleRef"
    viewBox="0 0 240 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M240 120c-66.28 0-120 53.73-120 120 0-66.28-53.72-120-120-120 66.28 0 120-53.72 120-120 0 66.28 53.73 120 120 120Z"
      fill="#FEFEFE"
    />
  </svg>
</template>

<script setup>
import { ref, defineExpose } from "vue";
const twinkleRef = ref();
defineExpose({
  twinkleRef,
});
</script>
