<template>
  <svg viewBox="0 0 1921 1836" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.0100098 0H1920.01v1181.03l-966 646.97L.0100098 1181.03V0Z"
      fill="white"
    />
    <g clip-path="url(#clip0_2_77)">
      <path
        d="M1118.88 1729.5H801.138c-2.83 0-5.12 2.29-5.12 5.11v5.12c0 20.46 19.88 40.92 82 40.92h164.002c62.12 0 82-20.46 82-40.92v-5.12c0-2.82-2.3-5.11-5.12-5.11h-.02Z"
        fill="#F28518"
      />
      <path
        d="m1083.01 1688.58-27.13 27.07c-2 2-5.25 2-7.25 0l-27.13-27.07h61.51Z"
        fill="#FFD519"
      />
      <path
        d="M785.76 1688.58h348.5v30.69c0 5.65-4.59 10.23-10.25 10.23h-328c-5.66 0-10.25-4.58-10.25-10.23v-30.69Z"
        fill="#AA4500"
      />
      <path
        d="M1124.01 1678.35h-328c-5.66 0-10.25 4.58-10.25 10.23h348.5c0-5.65-4.59-10.23-10.25-10.23Z"
        fill="#FFD600"
      />
      <path
        d="M796.229 1673.98c0 2.42 2.3 4.38 5.12 4.38h317.761c2.83 0 5.12-1.96 5.12-4.38v-21.89c0-2.42-2.3-4.38-5.12-4.38H801.359c-2.83 0-5.12 1.96-5.12 4.38v21.89h-.01Z"
        fill="#F7342B"
      />
      <path
        d="M1118.09 1627.26H801.93c-3.27 0-5.92 2.65-5.92 5.91v8.64c0 3.26 2.65 5.91 5.92 5.91h316.16c3.27 0 5.92-2.65 5.92-5.91v-8.64c0-3.26-2.65-5.91-5.92-5.91Z"
        fill="#DDE5AC"
      />
      <path
        d="M1134.26 1647.67c0 1.6-.82 3.08-2.16 3.94l-17.35 11.08c-3.01 1.92-6.77 2.88-10.53 2.88s-7.52-.96-10.52-2.88l-15.01-9.58c-3.01-1.92-6.76-2.88-10.52-2.88s-7.52.96-10.53 2.88l-15.01 9.58c-3 1.92-6.77 2.88-10.52 2.88s-7.52-.96-10.53-2.88l-15.01-9.58c-3-1.92-6.759-2.88-10.519-2.88s-7.52.96-10.53 2.88l-15 9.58c-3.01 1.92-6.77 2.88-10.53 2.88s-7.52-.96-10.53-2.88l-15-9.58c-3.01-1.92-6.76-2.88-10.53-2.88-3.77 0-7.52.96-10.52 2.88l-15.01 9.58c-3.01 1.92-6.76 2.88-10.53 2.88-3.77 0-7.52-.96-10.52-2.88l-15.01-9.58c-3.01-1.92-6.76-2.88-10.53-2.88-3.77 0-7.51.96-10.52 2.88l-15.01 9.58c-3 1.92-6.76 2.88-10.52 2.88s-7.52-.96-10.53-2.88l-17.35-11.08c-1.34-.86-2.16-2.34-2.16-3.94 0-2.82 2.3-5.11 5.12-5.11h338.299c2.83 0 5.12 2.29 5.12 5.11Z"
        fill="#00E030"
      />
      <path
        d="M960.01 1484c-92.25 0-164 51.15-164 132.98v5.12c0 2.82 2.3 5.11 5.12 5.11h317.75c2.83 0 5.12-2.29 5.12-5.11v-5.12c.01-81.83-71.74-132.98-163.99-132.98Z"
        fill="#F28518"
      />
      <path
        d="M1039.96 1601.8c0 2.26 1.83 4.09 4.1 4.09 2.27 0 4.1-1.83 4.1-4.09s-1.83-4.09-4.1-4.09c-2.27 0-4.1 1.83-4.1 4.09Zm9.22 15.95c0 2.26 1.84 4.09 4.1 4.09s4.1-1.83 4.1-4.09-1.83-4.09-4.1-4.09c-2.27 0-4.1 1.83-4.1 4.09Zm9.23-15.95c0 2.26 1.83 4.09 4.1 4.09 2.27 0 4.1-1.83 4.1-4.09s-1.83-4.09-4.1-4.09c-2.27 0-4.1 1.83-4.1 4.09Zm-9.23-15.94c0 2.26 1.84 4.09 4.1 4.09s4.1-1.83 4.1-4.09-1.83-4.09-4.1-4.09c-2.27 0-4.1 1.83-4.1 4.09Zm-18.44 0c0 2.26 1.83 4.09 4.1 4.09 2.27 0 4.1-1.83 4.1-4.09s-1.83-4.09-4.1-4.09c-2.27 0-4.1 1.83-4.1 4.09Zm-9.23 15.94c0 2.26 1.83 4.09 4.1 4.09 2.27 0 4.1-1.83 4.1-4.09s-1.84-4.09-4.1-4.09-4.1 1.83-4.1 4.09Zm9.23 15.95c0 2.26 1.83 4.09 4.1 4.09 2.27 0 4.1-1.83 4.1-4.09s-1.83-4.09-4.1-4.09c-2.27 0-4.1 1.83-4.1 4.09Zm-150.678-15.95c0 2.26-1.83 4.09-4.1 4.09-2.27 0-4.1-1.83-4.1-4.09s1.83-4.09 4.1-4.09c2.27 0 4.1 1.83 4.1 4.09Zm-9.235 15.95c0 2.26-1.83 4.09-4.1 4.09-2.27 0-4.1-1.83-4.1-4.09s1.83-4.09 4.1-4.09c2.27 0 4.1 1.83 4.1 4.09Zm-9.219-15.95c0 2.26-1.83 4.09-4.1 4.09-2.27 0-4.1-1.83-4.1-4.09s1.83-4.09 4.1-4.09c2.27 0 4.1 1.83 4.1 4.09Zm9.219-15.94c0 2.26-1.83 4.09-4.1 4.09-2.27 0-4.1-1.83-4.1-4.09s1.83-4.09 4.1-4.09c2.27 0 4.1 1.83 4.1 4.09Zm18.453 0c0 2.26-1.83 4.09-4.1 4.09-2.27 0-4.1-1.83-4.1-4.09s1.84-4.09 4.1-4.09 4.1 1.83 4.1 4.09Zm9.227 15.94c0 2.26-1.84 4.09-4.1 4.09s-4.1-1.83-4.1-4.09 1.84-4.09 4.1-4.09 4.1 1.83 4.1 4.09Zm-9.227 15.95c0 2.26-1.83 4.09-4.1 4.09-2.27 0-4.1-1.83-4.1-4.09s1.84-4.09 4.1-4.09 4.1 1.83 4.1 4.09Z"
        fill="#FFA526"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M936.063 1599.99c.93-1.9 3.24-2.69 5.14-1.76 12.92 6.31 25.31 6.28 37.56.03 1.89-.96 4.21-.22 5.17 1.67.97 1.89.22 4.2-1.67 5.16-14.49 7.39-29.44 7.36-44.44.03-1.91-.93-2.7-3.23-1.76-5.13Z"
        fill="#8E0606"
      />
      <path
        d="M944.643 1570.95c0 6.35-2.9 12.02-7.45 15.77-3.55 2.93-8.1 4.69-13.05 4.69-4.95 0-9.51-1.76-13.05-4.69-4.55-3.75-7.45-9.43-7.45-15.77 0-11.3 9.18-20.46 20.5-20.46s20.5 9.16 20.5 20.46Z"
        fill="white"
      />
      <path
        d="M939.508 1578.62c0 2.97-.85 5.75-2.32 8.1-3.55 2.93-8.1 4.69-13.05 4.69-4.95 0-9.51-1.76-13.05-4.69-1.47-2.35-2.32-5.13-2.32-8.1 0-8.47 6.88-15.34 15.38-15.34s15.38 6.87 15.38 15.34h-.02Z"
        fill="#8E0606"
      />
      <path
        d="M1016.38 1570.95c0 6.35-2.9 12.02-7.45 15.77-3.55 2.93-8.1 4.69-13.053 4.69-4.95 0-9.51-1.76-13.05-4.69-4.55-3.75-7.45-9.43-7.45-15.77 0-11.3 9.18-20.46 20.5-20.46 11.323 0 20.503 9.16 20.503 20.46Z"
        fill="white"
      />
      <path
        d="M1011.26 1578.62c0 2.97-.85 5.75-2.32 8.1-3.55 2.93-8.1 4.69-13.052 4.69-4.95 0-9.51-1.76-13.05-4.69-1.47-2.35-2.32-5.13-2.32-8.1 0-8.47 6.88-15.34 15.38-15.34 8.502 0 15.382 6.87 15.382 15.34h-.02Z"
        fill="#8E0606"
      />
      <path
        d="M939.507 1693.69v138.1h-20.47c-11.96-4.56-22.84-7.86-33.96-10.23-25.05-5.35-51.35-6.04-94.2-6.14-3.32 0-6.73-.01-10.25-.01v-142.17c3.51 0 6.93 0 10.25.01 74.62.03 101.71.86 148.63 20.44Z"
        fill="white"
      />
      <path
        d="M960.007 1693.69v127.87h-74.93c-25.05-5.35-51.35-6.04-94.2-6.14v-152.41c92.75 0 120.12 10.23 169.13 30.68Zm169.123-30.68v152.42c-42.84.09-69.14.78-94.19 6.13h-74.93v-127.87c49-20.45 76.38-30.68 169.12-30.68Z"
        fill="white"
      />
      <path
        d="M1139.38 1673.24v142.17c-3.52 0-6.93.01-10.25.02-42.84.09-69.14.78-94.19 6.13-11.12 2.37-22 5.67-33.96 10.23h-20.47v-138.1c46.91-19.58 74.01-20.41 148.62-20.44 3.32-.01 6.74-.01 10.25-.01Z"
        fill="white"
      />
      <path
        d="M929.257 1703.92v131.95c-3.51-1.46-6.91-2.82-10.22-4.08-11.96-4.56-22.84-7.86-33.96-10.23-25.05-5.35-51.35-6.04-94.2-6.14-3.32 0-6.73-.01-10.25-.01l-2.74.06c-4.12.09-7.51-3.22-7.51-7.34v-119.55c0-2.83 2.29-5.12 5.12-5.12h15.38c67.14.15 93.66 1.78 138.38 20.45v.01Zm220.373-15.34v119.49c0 4.05-3.29 7.34-7.34 7.34h-2.91c-3.52 0-6.93.01-10.25.02-42.84.09-69.14.78-94.19 6.13-11.12 2.37-22 5.67-33.96 10.23-3.31 1.26-6.71 2.62-10.22 4.08v-131.95c44.71-18.67 71.23-20.3 138.37-20.45h15.38c2.83 0 5.12 2.29 5.12 5.12v-.01Z"
        fill="#0B3CD8"
      />
      <path d="M990.76 1703.92h-61.5v131.95h61.5v-131.95Z" fill="#00298E" />
      <path
        d="M770.38 1760.19c14.15 0 25.62-11.45 25.62-25.57 0-14.12-11.47-25.57-25.62-25.57s-25.62 11.44-25.62 25.56 11.47 25.57 25.62 25.57v.01Zm379.26 0c14.15 0 25.62-11.45 25.62-25.57 0-14.12-11.47-25.57-25.62-25.57s-25.62 11.45-25.62 25.57c0 14.12 11.47 25.57 25.62 25.57Z"
        fill="#F28518"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_77">
        <path fill="white" d="M744.76 1484h430.5v351.87h-430.5z" />
      </clipPath>
    </defs>
  </svg>
</template>
