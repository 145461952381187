<template>
  <svg
    ref="monsterRef"
    viewBox="0 0 902 861"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M841.879 328.341v2.365c.182 38.664-39.391 99.616-88.699 99.888l-604.878 3.276c-49.3072.272-89.5177-60.315-89.7906-98.979C57.6016 168.047 231.087 55.9684 443.873 52.8753c218.516-3.275 397.096 104.5277 398.006 275.4657Z"
      fill="#151324"
    />
    <path
      d="m16.3945 630.518 869.9605-4.707.415 76.78c.077 14.1-11.324 25.625-25.424 25.701l-818.7437 4.43c-14.1006.076-25.625-11.324-25.7013-25.425l-.4154-76.78-.0911.001Z"
      fill="#AA4500"
    />
    <path
      d="m860.623 600.524-818.7556 4.458c-14.1008 0-25.5633 11.553-25.4723 25.745l869.9729-4.731c0-14.1-11.553-25.563-25.745-25.472Z"
      fill="#FFD600"
    />
    <path
      d="m758.381 626.625-67.32 68.048c-5.003 5.003-13.1 5.094-18.103.091l-68.048-67.32 153.562-.819h-.091Z"
      fill="#FFD519"
    />
    <path
      d="M42.324 593.975c0 6.004 5.822 10.917 12.8269 10.917l793.1921-4.276c7.096 0 12.736-5.004 12.736-11.008l-.273-54.765c0-6.005-5.822-10.917-12.827-10.917l-793.1918 4.276c-7.0959 0-12.7364 5.003-12.7364 11.007l.2732 54.766Z"
      fill="#F7342B"
    />
    <path
      d="m845.135 472.667-789.2688 4.271c-8.1392.044-14.7016 6.678-14.6576 14.817l.1177 21.742c.044 8.139 6.6778 14.702 14.8171 14.658l789.2686-4.271c8.139-.044 14.702-6.677 14.658-14.817l-.118-21.742c-.044-8.139-6.678-14.702-14.817-14.658Z"
      fill="#DDE5AC"
    />
    <path
      d="M885.732 523.562c0 4.003-2.002 7.733-5.368 9.916l-43.212 27.929c-7.459 4.821-16.83 7.278-26.2 7.369-9.37.091-18.74-2.275-26.291-7.096l-37.572-23.744c-7.551-4.731-16.92-7.096-26.291-7.096-9.37 0-18.74 2.547-26.2 7.369l-37.299 24.198c-7.46 4.822-16.83 7.278-26.2 7.369-9.371.091-18.74-2.274-26.291-7.096l-37.572-23.744c-7.551-4.73-16.921-7.095-26.291-7.095-9.37 0-18.741 2.547-26.2 7.368l-37.299 24.199c-7.46 4.822-16.83 7.278-26.2 7.369-9.37 0-18.741-2.274-26.291-7.096l-37.572-23.744c-7.551-4.73-16.921-7.096-26.292-7.096-9.37 0-18.74 2.548-26.2 7.369l-37.298 24.199c-7.46 4.821-16.83 7.278-26.201 7.369-9.37.091-18.74-2.275-26.291-7.096l-37.572-23.744c-7.551-4.731-16.92-7.096-26.291-7.096-9.37 0-18.74 2.547-26.2 7.369l-37.299 24.199c-7.46 4.821-16.83 7.277-26.2002 7.368-9.3702.091-18.7399-2.274-26.2907-7.095l-43.4853-27.474c-3.366-2.093-5.4581-5.823-5.4581-9.825 0-7.096 5.64-12.828 12.7359-12.828l844.3184-4.548c7.096 0 12.828 5.64 12.828 12.736l-.455-.182Z"
      fill="#00E030"
    />
    <path
      d="M448.602 116.555C218.35 117.828 39.9524 246.737 41.044 451.426v12.827c.091 7.096 5.8222 12.736 12.918 12.736l793.192-4.276c7.096 0 12.737-5.822 12.737-12.827v-12.827c-1.092-204.689-180.945-331.687-411.198-330.413l-.091-.091Z"
      fill="#F28518"
    />
    <path
      d="M649.387 346.184c0 5.641 4.639 10.189 10.28 10.189 5.64 0 10.189-4.639 10.189-10.28 0-5.64-4.64-10.189-10.28-10.189-5.641 0-10.189 4.64-10.189 10.28Zm23.273 39.748c0 5.641 4.64 10.189 10.28 10.189s10.189-4.639 10.189-10.28c0-5.64-4.64-10.189-10.28-10.189s-10.189 4.64-10.189 10.28Zm22.746-40.031c0 5.64 4.64 10.189 10.28 10.189 5.641 0 10.189-4.64 10.189-10.28s-4.64-10.189-10.28-10.189-10.189 4.64-10.189 10.28Zm-23.187-39.748c0 5.64 4.639 10.189 10.28 10.189 5.64 0 10.189-4.64 10.189-10.28s-4.64-10.189-10.28-10.189c-5.641 0-10.189 4.64-10.189 10.28Zm-46.039.266c0 5.64 4.639 10.189 10.28 10.189 5.64 0 10.189-4.64 10.189-10.28 0-5.641-4.64-10.189-10.28-10.189-5.641 0-10.189 4.639-10.189 10.28Zm-22.832 40.031c0 5.64 4.639 10.189 10.28 10.189 5.64 0 10.189-4.64 10.189-10.28s-4.64-10.189-10.28-10.189c-5.641 0-10.189 4.64-10.189 10.28Zm23.203 39.765c0 5.641 4.639 10.189 10.28 10.189 5.64 0 10.189-4.639 10.189-10.28 0-5.64-4.639-10.188-10.28-10.188-5.64 0-10.189 4.639-10.189 10.279Zm-376.277-37.848c0 5.64-4.549 10.28-10.189 10.28-5.641 0-10.28-4.549-10.28-10.189 0-5.641 4.548-10.28 10.189-10.28 5.64 0 10.28 4.548 10.28 10.189Zm-22.832 40.031c0 5.64-4.549 10.28-10.189 10.28-5.641 0-10.28-4.549-10.28-10.189s4.548-10.28 10.189-10.28c5.64 0 10.28 4.549 10.28 10.189Zm-23.293-39.766c0 5.641-4.549 10.28-10.189 10.28-5.641 0-10.28-4.548-10.28-10.189 0-5.64 4.548-10.28 10.189-10.28 5.64 0 10.28 4.549 10.28 10.189Zm22.832-40.031c0 5.64-4.549 10.28-10.189 10.28-5.641 0-10.28-4.549-10.28-10.189s4.548-10.28 10.189-10.28c5.64 0 10.28 4.549 10.28 10.189Zm46.039-.266c0 5.641-4.549 10.28-10.189 10.28-5.641 0-10.28-4.548-10.28-10.189 0-5.64 4.548-10.28 10.189-10.28 5.64 0 10.28 4.549 10.28 10.189Zm23.293 39.748c0 5.641-4.549 10.28-10.189 10.28-5.641 0-10.28-4.548-10.28-10.189 0-5.64 4.548-10.279 10.189-10.279 5.64 0 10.28 4.548 10.28 10.188Zm-22.852 40.032c0 5.64-4.549 10.28-10.189 10.28s-10.28-4.549-10.28-10.189c0-5.641 4.549-10.28 10.189-10.28s10.28 4.548 10.28 10.189Z"
      fill="#FFA526"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M509.906 342.123c2.54 4.918.612 10.964-4.306 13.503-36.206 18.697-73.638 18.811-111.293.67-4.986-2.402-7.081-8.392-4.679-13.379 2.403-4.986 8.392-7.081 13.379-4.679 32.213 15.519 63.01 15.27 93.396-.421 4.918-2.54 10.964-.612 13.503 4.306Z"
      fill="#8E0606"
    />
    <path
      d="M360.176 321.709c28.292-.182 51.035-23.198 50.853-51.491-.182-28.292-23.198-51.036-51.49-50.854-28.293.182-51.036 23.198-50.854 51.491.182 28.293 23.198 51.036 51.491 50.854Z"
      fill="white"
    />
    <path
      d="M360.084 308.973c21.197-.091 38.3-17.376 38.209-38.573-.091-21.196-17.376-38.299-38.572-38.208-21.197.091-38.3 17.376-38.209 38.572.091 21.197 17.376 38.3 38.572 38.209Z"
      fill="#8E0606"
    />
    <path
      d="M539.202 320.785c28.293-.182 51.036-23.198 50.854-51.491-.181-28.292-23.198-51.035-51.49-50.853-28.293.181-51.036 23.198-50.854 51.49.182 28.293 23.198 51.036 51.49 50.854Z"
      fill="white"
    />
    <path
      d="M539.202 307.959c21.196-.091 38.299-17.376 38.208-38.572-.091-21.197-17.375-38.3-38.572-38.209-21.197.091-38.3 17.376-38.209 38.573.091 21.196 17.376 38.299 38.573 38.208Z"
      fill="#8E0606"
    />
    <path
      opacity=".5"
      d="M851.158 345.446v2.639c.273 42.575-39.391 109.895-88.607 110.077l-142.918.727c49.307-.272 88.88-67.501 88.607-110.077v-2.638c-.91-165.388-138.734-277.2851-320.224-299.8463 21.105-2.9111 42.757-4.5487 64.863-4.9126C671.396 37.9582 850.067 156.951 851.068 345.355l.09.091Z"
      fill="#151324"
    />
    <path
      d="M861.346 188.877c-23.653-40.21-57.495-75.326-100.616-104.4371-40.938-27.6557-88.88-48.9434-142.554-63.3171C565.229 6.93107 508.462-.0738358 449.239.199083h-6.732C324.697 1.92757 215.439 34.1319 135.019 90.899c-42.3025 29.839-75.5077 65.409-98.5238 105.71C12.0235 239.367-.166874 285.945.106045 335.161l.636876 119.265C1.01584 501.459 43.2271 539.304 94.9906 539.031l711.7714-3.821c51.855-.273 93.611-38.572 93.338-85.605l-.637-121.631c-.273-49.853-13.1-96.613-38.117-139.097Zm-19.923 141.826c.182 38.664-39.391 99.616-88.698 99.889l-604.879 3.275c-49.3074.272-89.5174-60.315-89.7903-98.979-.4549-83.422 42.6663-153.107 112.5333-202.414 69.867-49.2167 166.39-78.0551 272.828-79.6017h6.095C665.209 51.6897 840.513 159.129 841.423 328.338v2.365Z"
      fill="#EBE7DB"
    />
    <path
      d="m354.365 465.045-35.752.193.194 35.752 35.751-.193-.193-35.752Zm56.945-.393-35.751.194.193 35.752 35.752-.194-.194-35.752Zm57.036-.301-35.752.194.193 35.752 35.752-.194-.193-35.752Zm57.039-.23-35.752.193.193 35.752 35.752-.193-.193-35.752Zm56.945-.393-35.752.194.194 35.752 35.751-.194-.193-35.752Z"
      fill="#131F26"
    />
    <path
      d="M117.288 270.66c-2.092 0-4.184-.455-6.095-1.547-6.186-3.366-8.551-11.098-5.185-17.376 23.198-43.03 63.68-80.874 116.991-109.531 39.937-21.378 72.778-30.203 74.143-30.567 6.823-1.819 13.827 2.275 15.647 9.098 1.819 6.823-2.275 13.827-9.098 15.647-1.728.455-124.632 34.024-175.031 127.453-2.275 4.276-6.641 6.732-11.19 6.732l-.182.091Zm-18.1082 43.39c7.9882 0 14.4652-6.476 14.4652-14.464 0-7.989-6.477-14.465-14.4652-14.465-7.9887 0-14.465 6.476-14.465 14.465 0 7.988 6.4763 14.464 14.465 14.464Z"
      fill="white"
    />
    <path
      d="m859.934 728.154-804.9579 4.33c-7.2011 0-12.9253 5.896-12.9253 12.989v12.988c.3693 51.864 50.962 103.358 208.3712 102.529l415.45-2.211c157.41-.829 207.449-52.968 207.172-104.739v-12.989c-.093-7.185-5.909-12.897-13.11-12.897Z"
      fill="#EBE7DB"
    />
  </svg>
</template>

<script setup>
import { ref, defineExpose } from "vue";
const monsterRef = ref();
defineExpose({
  monsterRef,
});
</script>
