<template>
  <svg viewBox="0 0 960 769" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 322">
      <g id="Group 319">
        <g id="Group">
          <g id="body">
            <g id="patty">
              <path
                id="Vector"
                d="M309.953 505.023H649.93v29.894c0 5.501-4.479 9.965-9.999 9.965H319.952c-5.519 0-9.999-4.464-9.999-9.965v-29.894Z"
                fill="#AA4500"
              />
              <path
                id="cheese_2"
                d="M639.931 495.059H319.952c-5.519 0-9.999 4.464-9.999 9.964H649.93c0-5.5-4.479-9.964-9.999-9.964Z"
                fill="#FFD600"
              />
              <path
                id="cheese_1"
                d="m599.937 505.023-26.458 26.367c-1.96 1.953-5.12 1.953-7.079 0l-26.459-26.367h59.996Z"
                fill="#FFD519"
              />
            </g>
            <path
              id="tomato"
              d="M320.152 490.796c0 2.352 2.24 4.265 5 4.265h309.979c2.76 0 5-1.913 5-4.265v-21.324c0-2.352-2.24-4.265-5-4.265H325.152c-2.76 0-5 1.913-5 4.265v21.324Z"
              fill="#F7342B"
            />
            <path
              id="onion"
              d="M634.152 445.277H325.733c-3.192 0-5.78 2.579-5.78 5.76v8.41c0 3.181 2.588 5.76 5.78 5.76h308.419c3.192 0 5.78-2.579 5.78-5.76v-8.41c0-3.181-2.588-5.76-5.78-5.76Z"
              fill="#DDE5AC"
            />
            <path
              id="lettuce"
              d="M649.928 465.168c0 1.554-.8 2.989-2.1 3.826l-16.919 10.802c-2.94 1.873-6.6 2.81-10.259 2.81-3.66 0-7.34-.937-10.26-2.81l-14.639-9.327c-2.94-1.873-6.599-2.81-10.259-2.81s-7.34.937-10.259 2.81l-14.639 9.327c-2.92 1.873-6.6 2.81-10.26 2.81-3.659 0-7.339-.937-10.279-2.81l-14.639-9.327c-2.92-1.873-6.6-2.81-10.259-2.81-3.66 0-7.34.937-10.26 2.81l-14.639 9.327c-2.94 1.873-6.599 2.81-10.259 2.81s-7.34-.937-10.259-2.81l-14.639-9.327c-2.94-1.873-6.6-2.81-10.26-2.81-3.659 0-7.339.937-10.259 2.81l-14.639 9.327c-2.94 1.873-6.6 2.81-10.279 2.81-3.68 0-7.34-.937-10.26-2.81l-14.639-9.327c-2.94-1.873-6.599-2.81-10.259-2.81s-7.34.937-10.259 2.81l-14.639 9.327c-2.92 1.873-6.6 2.81-10.26 2.81-3.659 0-7.339-.937-10.259-2.81l-16.919-10.802c-1.32-.837-2.1-2.292-2.1-3.826 0-2.75 2.24-4.982 5-4.982h329.978c2.76 0 5 2.232 5 4.982h-.12Z"
              fill="#00E030"
            />
          </g>
          <g id="head">
            <path
              id="face(bun)"
              d="M479.942 305.736c-89.994 0-159.989 49.823-159.989 129.54v4.983c0 2.75 2.24 4.982 5 4.982h309.979c2.76 0 5-2.232 5-4.982v-4.983c0-79.717-69.996-129.54-159.99-129.54Z"
              fill="#F28518"
            />
            <g id="cheeks">
              <g id="cheek_R">
                <path
                  id="Vector_2"
                  d="M527.949 410.527c0 2.192 1.8 3.986 4 3.986s4-1.794 4-3.986-1.8-3.986-4-3.986-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_3"
                  d="M536.945 426.048c0 2.193 1.8 3.986 4 3.986s4-1.793 4-3.986c0-2.192-1.8-3.986-4-3.986s-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_4"
                  d="M545.945 410.527c0 2.192 1.8 3.986 4 3.986s4-1.794 4-3.986-1.8-3.986-4-3.986-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_5"
                  d="M536.945 394.982c0 2.192 1.8 3.986 4 3.986s4-1.794 4-3.986-1.8-3.986-4-3.986-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_6"
                  d="M518.949 394.982c0 2.192 1.8 3.986 4 3.986s4-1.794 4-3.986-1.8-3.986-4-3.986-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_7"
                  d="M509.949 410.527c0 2.192 1.8 3.986 4 3.986s4-1.794 4-3.986-1.8-3.986-4-3.986-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_8"
                  d="M518.949 426.048c0 2.193 1.8 3.986 4 3.986s4-1.793 4-3.986c0-2.192-1.8-3.986-4-3.986s-4 1.794-4 3.986Z"
                  fill="#FFA526"
                />
              </g>
              <g id="cheek_L">
                <path
                  id="Vector_9"
                  d="M371.956 410.527c0 2.192-1.799 3.986-3.999 3.986s-4-1.794-4-3.986 1.8-3.986 4-3.986 3.999 1.794 3.999 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_10"
                  d="M362.956 426.048c0 2.193-1.799 3.986-3.999 3.986s-4-1.793-4-3.986c0-2.192 1.8-3.986 4-3.986s3.999 1.794 3.999 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_11"
                  d="M353.956 410.527c0 2.192-1.799 3.986-3.999 3.986s-4-1.794-4-3.986 1.8-3.986 4-3.986 3.999 1.794 3.999 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_12"
                  d="M362.956 394.982c0 2.192-1.799 3.986-3.999 3.986s-4-1.794-4-3.986 1.8-3.986 4-3.986 3.999 1.794 3.999 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_13"
                  d="M380.953 394.982c0 2.192-1.8 3.986-4 3.986s-4-1.794-4-3.986 1.8-3.986 4-3.986 4 1.794 4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_14"
                  d="M389.953 410.527c0 2.192-1.8 3.986-4 3.986s-4-1.794-4-3.986 1.8-3.986 4-3.986 4 1.794 4 3.986Z"
                  fill="#FFA526"
                />
                <path
                  id="Vector_15"
                  d="M380.953 426.048c0 2.193-1.8 3.986-4 3.986s-4-1.793-4-3.986c0-2.192 1.8-3.986 4-3.986s4 1.794 4 3.986Z"
                  fill="#FFA526"
                />
              </g>
            </g>
            <path
              id="mouth"
              d="M429.945 410.404c13.619 6.637 26.959 6.637 39.998 0"
              stroke="#8E0606"
              stroke-width="3.75"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <g id="eye_R">
              <path
                id="White"
                d="M414.952 400.403c11.039 0 19.998-8.928 19.998-19.929 0-11.001-8.959-19.929-19.998-19.929-11.039 0-19.999 8.928-19.999 19.929 0 11.001 8.96 19.929 19.999 19.929Z"
                fill="white"
              />
              <path
                id="black"
                d="M414.952 395.421c8.28 0 14.999-6.696 14.999-14.947 0-8.25-6.719-14.947-14.999-14.947-8.279 0-14.999 6.697-14.999 14.947 0 8.251 6.72 14.947 14.999 14.947Z"
                fill="#8E0606"
              />
            </g>
            <g id="eye_L">
              <path
                id="white"
                d="M484.936 400.403c11.039 0 19.999-8.928 19.999-19.929 0-11.001-8.96-19.929-19.999-19.929-11.039 0-19.998 8.928-19.998 19.929 0 11.001 8.959 19.929 19.998 19.929Z"
                fill="white"
              />
              <path
                id="black_2"
                d="M484.936 395.421c8.28 0 14.999-6.696 14.999-14.947 0-8.25-6.719-14.947-14.999-14.947-8.279 0-14.998 6.697-14.998 14.947 0 8.251 6.719 14.947 14.998 14.947Z"
                fill="#8E0606"
              />
            </g>
          </g>
          <g id="Group_2">
            <path
              id="Vector_16"
              d="M479.945 274.845c2.761 0 5-2.23 5-4.982 0-2.751-2.239-4.982-5-4.982s-5 2.231-5 4.982c0 2.752 2.239 4.982 5 4.982Z"
              fill="#AA0C0C"
            />
            <path
              id="Vector_17"
              d="M519.94 311.715h-79.995v-1.993c0-22.022 17.899-39.859 39.998-39.859 22.098 0 39.997 17.837 39.997 39.859v1.993Z"
              fill="#F7342B"
            />
            <path
              id="Vector_18"
              d="M503.245 307.892c-25.359-10.024-50.557-9.985-75.635 0-2.3.917-1.54 3.826.98 3.826h73.655c2.54 0 3.28-2.909.98-3.826h.02Z"
              fill="#AA0C0C"
            />
          </g>
        </g>
        <g id="Group_3">
          <path
            id="Vector_19"
            d="M720.334 421.881H612.961v107h107.373v-107Z"
            fill="#E5A061"
          />
          <path
            id="Vector_20"
            d="M720.334 455.318H612.961v14.267h107.373v-14.267Z"
            fill="#D3884A"
          />
        </g>
        <path id="Vector_21" d="M960 528.881H0v240h960v-240Z" fill="#EDEBEB" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_130_2414">
        <path fill="white" d="M240 552.881h480v192H240z" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup></script>
