<template>
  <svg viewBox="0 0 341 272" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.0400391 232H340.04v30c0 5.52-4.48 10-10 10h-320c-5.51996 0-9.9999609-4.48-9.9999609-10v-30Z"
      fill="#AA4500"
    />
    <path
      d="M330.04 222h-320c-5.51996 0-9.9999609 4.48-9.9999609 10H340.04c0-5.52-4.48-10-10-10Z"
      fill="#FFD600"
    />
    <path
      d="m290.04 232-26.46 26.46c-1.95 1.95-5.12 1.95-7.07 0L230.05 232h60-.01Z"
      fill="#FFD519"
    />
    <path
      d="M10.2598 217.72c0 2.36 2.24 4.28 5 4.28H325.26c2.76 0 5-1.92 5-4.28v-21.39c0-2.36-2.24-4.28-5-4.28H15.2598c-2.76 0-5 1.92-5 4.28v21.39Z"
      fill="#F7342B"
    />
    <path
      d="M324.27 172.05H15.81c-3.1867 0-5.77 2.583-5.77 5.77v8.46c0 3.187 2.5833 5.77 5.77 5.77h308.46c3.187 0 5.77-2.583 5.77-5.77v-8.46c0-3.187-2.583-5.77-5.77-5.77Z"
      fill="#DDE5AC"
    />
    <path
      d="M340.04 192c0 1.56-.79 3.01-2.11 3.85L321 206.69c-2.93 1.88-6.6 2.82-10.27 2.82s-7.34-.94-10.27-2.82l-14.64-9.37c-2.93-1.88-6.6-2.81-10.27-2.81s-7.33.94-10.27 2.81l-14.64 9.37c-2.93 1.88-6.6 2.82-10.26 2.82s-7.33-.94-10.27-2.82l-14.64-9.37c-2.93-1.88-6.6-2.81-10.27-2.81s-7.33.94-10.27 2.81l-14.64 9.37c-2.93 1.88-6.6 2.82-10.27 2.82s-7.33-.94-10.27-2.82l-14.64-9.37c-2.93-1.88-6.6-2.81-10.27-2.81s-7.34.94-10.27 2.81l-14.64 9.37c-2.94 1.88-6.6 2.82-10.27 2.82s-7.33-.94-10.26-2.82l-14.64-9.37c-2.93-1.88-6.6-2.81-10.27-2.81s-7.33.94-10.27 2.81l-14.64 9.37c-2.93 1.88-6.6 2.82-10.27 2.82s-7.33-.94-10.27-2.82L2.11002 195.85C.800016 195.01 0 193.56 0 192c0-2.76 2.24-5 5-5h330c2.76 0 5 2.24 5 5h.04Z"
      fill="#00E030"
    />
    <path
      d="M170.04 32c-90 0-160 50-160 130v5c0 2.76 2.24 5 5 5h310c2.76 0 5-2.24 5-5v-5c0-80-70-130-160-130Z"
      fill="#F28518"
    />
    <path
      d="M248.04 137.17c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm9 15.59c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm9-15.59c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm-9-15.59c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm-18 0c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm-9 15.59c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm9 15.59c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4Zm-147-15.59c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Zm-9 15.59c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Zm-9-15.59c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Zm9-15.59c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Zm18 0c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Zm9 15.59c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Zm-9 15.59c0 2.21-1.79 4-4 4s-4-1.79-4-4 1.79-4 4-4 4 1.79 4 4Z"
      fill="#FFA526"
    />
    <path
      d="M150 143.51c13.62 6.67 26.95 6.67 40 0"
      stroke="#8E0606"
      stroke-width="7.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M135.04 127c11.05 0 20-8.95 20-20s-8.95-20-20-20-20 8.95-20 20 8.95 20 20 20Z"
      fill="white"
    />
    <path
      d="M135.04 122c8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15-8.28 0-15 6.72-15 15 0 8.28 6.72 15 15 15Z"
      fill="#8E0606"
    />
    <path
      d="M205.04 127c11.05 0 20-8.95 20-20s-8.95-20-20-20-20 8.95-20 20 8.95 20 20 20Z"
      fill="white"
    />
    <path
      d="M205.04 122c8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15-8.28 0-15 6.72-15 15 0 8.28 6.72 15 15 15Z"
      fill="#8E0606"
    />
    <g id="sunglass">
      <path
        d="M155.75 88.6499h-44c-2.761 0-5 2.2386-5 5V123.65c0 2.761 2.239 5 5 5h44c2.761 0 5-2.239 5-5V93.6499c0-2.7614-2.239-5-5-5Z"
        fill="#F7342B"
      />
      <path
        d="M155.75 132.4h-44c-4.82 0-8.75-3.92-8.75-8.75V93.6499c0-4.83 3.93-8.75 8.75-8.75h44c4.82 0 8.75 3.92 8.75 8.75V123.65c0 4.83-3.93 8.75-8.75 8.75Zm-44-40.0001c-.69 0-1.25.56-1.25 1.25V123.65c0 .69.56 1.25 1.25 1.25h44c.69 0 1.25-.56 1.25-1.25V93.6499c0-.69-.56-1.25-1.25-1.25h-44Z"
        fill="#8E0606"
      />
      <path
        d="M227.75 88.6499h-44c-2.761 0-5 2.2386-5 5V123.65c0 2.761 2.239 5 5 5h44c2.761 0 5-2.239 5-5V93.6499c0-2.7614-2.239-5-5-5Z"
        fill="#2DAAE0"
      />
      <path
        d="M227.75 132.4h-44c-4.82 0-8.75-3.92-8.75-8.75V93.6499c0-4.83 3.93-8.75 8.75-8.75h44c4.82 0 8.75 3.92 8.75 8.75V123.65c0 4.83-3.93 8.75-8.75 8.75Zm-44-40.0001c-.69 0-1.25.56-1.25 1.25V123.65c0 .69.56 1.25 1.25 1.25h44c.69 0 1.25-.56 1.25-1.25V93.6499c0-.69-.56-1.25-1.25-1.25h-44Z"
        fill="#8E0606"
      />
      <path d="M178.749 104.9h-18.8v7.5h18.8v-7.5Z" fill="#8E0606" />
    </g>
  </svg>
</template>
