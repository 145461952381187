<template>
  <svg viewBox="0 0 709 131" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_334_6084"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="709"
      height="131"
    >
      <path fill="#D9D9D9" d="M.710938.482422h707.5v130h-707.5z" />
    </mask>
    <g mask="url(#mask0_334_6084)">
      <path
        d="M705.711 130.482H.710938C95.7809 49.3934 218.821.482422 353.211.482422c134.39 0 257.43 48.900978 352.5 129.999578Z"
        fill="#FFD600"
      />
      <ellipse
        rx="19.6899"
        ry="12.0617"
        transform="scale(1 -1) rotate(-19.5549 2.47366604 -1825.9726983)"
        fill="#FFB400"
      />
      <ellipse
        rx="27.5752"
        ry="17.0586"
        transform="scale(1 -1) rotate(19.044 263.40826336 511.4228959)"
        fill="#FFB400"
      />
      <ellipse
        rx="58.077"
        ry="35.9276"
        transform="matrix(.999913 .0131779 .0131779 -.999913 225.004 125.179)"
        fill="#FFB400"
      />
      <ellipse
        rx="35.5768"
        ry="21.7937"
        transform="matrix(.942321 .33471 .33471 -.942321 511.271 60.5489)"
        fill="#FFB400"
      />
    </g>
  </svg>
</template>
