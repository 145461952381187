<template>
  <div class="conceptContainer">
    <div class="maskWrapper">
      <div class="mask" />
    </div>
    <p class="conceptContainer-number">①</p>
    <div class="conceptIntro">
      <p class="conceptIntro-title">개척정신</p>
      <p class="conceptIntro-des">
        언제, 어디서나<br />
        고객의 마음을 촵~땡겨버릴 수 있도록<br />
        전국 방방곡곡 매장을 오픈하는<br />
        개척정신을 발휘해온 롯데리아
      </p>
    </div>
    <Swiper
      class="slider"
      :effect="'coverflow'"
      :grabCursor="true"
      :centeredSlides="true"
      :slides-per-view="'auto'"
    >
      <SwiperSlide class="slider-slide">
        <div class="slider-slide-content">
          <div class="slider-slide-content-margin">
            <p class="year">1979</p>
            <svg
              class="hrLine"
              viewBox="0 0 160 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13h159M147 1l11.971 11.9706L147 24.9411"
                stroke="#8e0606"
              />
            </svg>
            <p class="yearDes">롯데리아 1호점 오픈<br />(서울 중구 소공동)</p>
          </div>
        </div>
        <div class="box">
          <Vue3Lottie
            v-if="isIos"
            class="box-lottie"
            :loop="true"
            :auto-play="true"
            background-color="transparent"
            :animation-data="pioneering1"
          />
          <video
            v-else
            src="../assets/concept/2-01-01_pioneering(1).webm"
            class="box-lottie"
            loop
            muted
            playsinline
          />
        </div>
      </SwiperSlide>
      <SwiperSlide class="slider-slide">
        <div class="slider-slide-content">
          <div class="slider-slide-content-margin">
            <p class="year">1997</p>
            <svg
              class="hrLine"
              viewBox="0 0 160 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13h159M147 1l11.971 11.9706L147 24.9411"
                stroke="#8e0606"
              />
            </svg>
            <p class="yearDes">
              업계 최초 드라이브스루점 오픈<br />(서울 명일 D/T점)
            </p>
          </div>
        </div>
        <div class="box">
          <Vue3Lottie
            v-if="isIos"
            class="box-lottie"
            :loop="true"
            :auto-play="true"
            background-color="transparent"
            :animation-data="pioneering2"
          />
          <video
            v-else
            src="../assets/concept/2-01-02_pioneering(2).webm"
            class="box-lottie"
            loop
            muted
            playsinline
          />
        </div>
      </SwiperSlide>
      <SwiperSlide class="slider-slide">
        <div class="slider-slide-content">
          <div class="slider-slide-content-margin">
            <p class="year">1998</p>
            <svg
              class="hrLine"
              viewBox="0 0 160 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13h159M147 1l11.971 11.9706L147 24.9411"
                stroke="#8e0606"
              />
            </svg>
            <p class="yearDes">베트남 1호점 오픈<br />(호치민_렉스호텔점)</p>
          </div>
        </div>
        <div class="box">
          <Vue3Lottie
            v-if="isIos"
            class="box-lottie"
            :loop="true"
            :auto-play="true"
            background-color="transparent"
            :animation-data="pioneering3"
          />
          <video
            v-else
            src="../assets/concept/2-01-03_pioneering(3).webm"
            class="box-lottie"
            loop
            muted
            playsinline
          />
        </div>
      </SwiperSlide>
      <SwiperSlide class="slider-slide">
        <div class="slider-slide-content">
          <div class="slider-slide-content-margin">
            <p class="year">2012</p>
            <svg
              class="hrLine"
              viewBox="0 0 160 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13h159M147 1l11.971 11.9706L147 24.9411"
                stroke="#8e0606"
              />
            </svg>
            <p class="yearDes">업계 최초로 1,000호점 돌파<br />(종로3가점)</p>
          </div>
        </div>
        <div class="box">
          <Vue3Lottie
            v-if="isIos"
            class="box-lottie"
            :loop="true"
            :auto-play="true"
            background-color="transparent"
            :animation-data="pioneering4"
          />
          <video
            v-else
            src="../assets/concept/2-01-04_pioneering(4).webm"
            class="box-lottie"
            loop
            muted
            playsinline
          />
        </div>
      </SwiperSlide>
      <SwiperSlide class="slider-slide">
        <div class="slider-slide-content">
          <div class="slider-slide-content-margin">
            <p class="year">2039</p>
            <svg
              class="hrLine"
              viewBox="0 0 159 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 13h159" stroke="#8E0606" />
            </svg>
            <p class="yearDes">우주정거장 1호점 오픈<br />(창립 60주년 기념)</p>
          </div>
        </div>
        <div class="box">
          <Vue3Lottie
            v-if="isIos"
            class="box-lottie"
            :loop="true"
            :auto-play="true"
            background-color="transparent"
            :animation-data="pioneering5"
          />
          <video
            v-else
            src="../assets/concept/2-01-05_pioneering(5).webm"
            class="box-lottie"
            loop
            muted
            playsinline
          />
        </div>
      </SwiperSlide>
    </Swiper>
    <div class="bike">
      <Vue3Lottie
        class="bike-lottie"
        :loop="true"
        :auto-play="true"
        background-color="transparent"
        :animation-data="pioneeringBike"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

import { Vue3Lottie } from "vue3-lottie";
import pioneering1 from "../assets/concept/2-01-01_pioneering(1).json";
import pioneering2 from "../assets/concept/2-01-02_pioneering(2).json";
import pioneering3 from "../assets/concept/2-01-03_pioneering(3).json";
import pioneering4 from "../assets/concept/2-01-04_pioneering(4).json";
import pioneering5 from "../assets/concept/2-01-05_pioneering(5).json";
import pioneeringBike from "../assets/concept/2-01-06_pioneering(bike).json";

const isIos = ref(false);
const ua = navigator.userAgent.toLowerCase();
let isIpad =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

onMounted(() => {
  if (
    ua.indexOf("iphone") > -1 ||
    ua.indexOf("ipad") > -1 ||
    ua.indexOf("ipod") > -1 ||
    isIpad
  ) {
    isIos.value = true;
  }
});
</script>

<style lang="scss" scoped>
.conceptContainer {
  width: 100%;
  background: #ffd600;
  font-family: "chab";
  overflow: hidden;
  .maskWrapper {
    position: absolute;
    width: 100%;
    padding-bottom: 140%;
    overflow: hidden;
    .mask {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 140%;
      padding-bottom: 140%;
      border-radius: 50%;
      background: #ffee33;
      @media screen and (max-width: 480px) {
        & {
          width: 200%;
          padding-bottom: 200%;
        }
      }
    }
  }
  &-number {
    position: relative;
    text-align: center;
    font-size: 4vw;
    padding-top: 12vw;
    color: #8e0606;
    @media screen and (max-width: 480px) {
      & {
        font-size: 6vw;
      }
    }
  }
  .conceptIntro {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    color: #8e0606;
    &-title {
      font-size: 15vw;
      letter-spacing: 0.01rem;
      margin-bottom: 4vw;
    }
    &-des {
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 2vw;
      letter-spacing: 0.075rem;
      word-break: keep-all;
      margin-bottom: 10vw;
      white-space: pre-line;
      @media screen and (max-width: 480px) {
        & {
          font-size: 4vw;
          margin-bottom: 50vw;
        }
      }
    }
  }
  .slider {
    &-slide {
      cursor: url("../assets/dragCursor.svg"), auto;
      width: calc(100% / 3 * 2);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media screen and (max-width: 480px) {
        & {
          display: block;
        }
      }
      &-content {
        width: 50%;
        height: 50%;
        @media screen and (max-width: 480px) {
          & {
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            width: 75%;
          }
        }
        &-margin {
          .hrLine {
            position: relative;
            width: 80%;
            display: inline-block;
            margin: 3% 0;
            @media screen and (max-width: 480px) {
              & {
                width: 100%;
                margin: 5% 0;
              }
            }
          }
          p {
            color: #8e0606;
            word-break: keep-all;
            white-space: pre-line;
          }
          .year {
            font-size: 6vw;
            letter-spacing: 0.01rem;
            @media screen and (max-width: 480px) {
              & {
                font-size: 7vw;
              }
            }
          }
          .yearDes {
            font-size: 2vw;
            letter-spacing: 0.075rem;
            @media screen and (max-width: 480px) {
              & {
                position: relative;
                left: 50%;
                transform: translate(-50%, 0);
                font-size: 3.31vw;
                margin-bottom: 10vw;
              }
            }
          }
        }
      }
      .box {
        width: 50%;
        height: 0;
        padding-bottom: 50%;
        background: rgb(255, 255, 255);
        overflow: hidden;
        @media screen and (max-width: 480px) {
          & {
            position: relative;
            left: 50%;
            transform: translate(-50%, 0);
            width: 75%;
            padding-bottom: 75%;
          }
        }
        &-lottie {
          position: relative;
          left: 50%;
          transform: translate(-50%, -6%);
          width: 115%;
        }
      }
    }
  }
  .bike {
    position: relative;
    left: 50%;
    width: 30%;
    padding-bottom: 12vw;
    z-index: 1;
    pointer-events: none;
    &-lottie {
      position: absolute;
      transform: translate(-50%, -27%);
      width: 100%;
    }
  }
}
</style>
