<template>
  <svg
    ref="spaceBurgerRef"
    viewBox="0 0 200 287"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m186.687 154.228-40.922 11.204 7.843 28.648 40.922-11.205-7.843-28.647Z"
      fill="#ED010A"
    />
    <path
      d="M170.411 180.071c.968-.259 1.851-.539 2.748-.736.459-.101.607-.308.606-.772.006-1.09.033-2.178.107-3.257.032-.451-.116-.405-.425-.314-1.829.511-3.662 1.01-5.495 1.508-.832.223-.853.229-1.143-.586-.728-2.011-.607-3.995.323-5.913 1.004-2.075 2.651-3.431 4.857-4.051 3.765-1.06 7.536.883 9.047 4.193 1.717 3.755.188 8.177-3.424 10.023-2.322 1.188-4.691 1.177-7.045.052-.035-.02-.064-.048-.168-.136l.012-.011Z"
      fill="#FEA800"
    />
    <path
      d="m167.97 180.232-4.832 1.324c-.413.11-.829.185-1.244.043-.706-.253-1.025-.792-1.069-1.499-.026-.45.01-.909.024-1.37.14-3.702.285-7.412.417-11.118.007-.3.112-.422.396-.498 1.295-.34 2.593-.695 3.889-1.057.271-.072.328-.022.315.257-.141 3.535-.275 7.068-.401 10.606-.005.117-.01.235-.014.352.005.427.188.566.607.454.792-.212 1.576-.429 2.359-.647 1.535-.418 3.079-.832 4.61-1.264.311-.083.377-.028.353.29-.051.783-.074 1.565-.083 2.344-.004.255-.086.357-.343.426-1.665.446-3.327.906-4.99 1.359l.006-.002Z"
      fill="#FEFEFE"
    />
    <path
      d="m148.321 164.734-2.556.699 12.88 47.04 2.556-.699-12.88-47.04Z"
      fill="#292930"
    />
    <path
      d="M112.377 253.246c-.59-1.01-1.88-1.34-2.89-.76-1.01.58-1.34 1.88-.76 2.89l5.32 9.11c.59 1.01 1.88 1.34 2.89.76 1.01-.58 1.34-1.88.76-2.89l-5.32-9.11Z"
      fill="#F28518"
    />
    <path
      d="M117.697 262.346c-.59-1.01-1.88-1.34-2.89-.76-1.01.58-1.34 1.88-.76 2.89l5.32 9.11c.59 1.01 1.88 1.34 2.89.76 1.01-.59 1.34-1.88.76-2.89l-5.32-9.11Z"
      fill="#F28518"
    />
    <path
      d="m120.786 266.236-4.86 2.84 4.61 7.89 4.86-2.84-4.61-7.89Z"
      fill="#EBE7DB"
    />
    <path
      d="m124.685 272.926.71 1.21-4.86 2.84-.71-1.21c-.38-.64-.48-1.41-.29-2.13s.66-1.34 1.3-1.71c.64-.37 1.41-.48 2.13-.29s1.34.66 1.71 1.3l.01-.01Z"
      fill="#131F26"
    />
    <path
      d="m111.723 252.118-3.644 2.13c-1.006.588-1.344 1.88-.756 2.886.588 1.006 1.88 1.345 2.886.757l3.643-2.13c1.006-.588 1.345-1.88.757-2.886-.588-1.006-1.88-1.345-2.886-.757Z"
      fill="#EBE7DB"
    />
    <path
      d="m113.848 255.764-3.644 2.13c-1.006.588-1.344 1.881-.756 2.887.588 1.006 1.88 1.344 2.886.756l3.643-2.129c1.006-.589 1.345-1.881.757-2.887s-1.88-1.345-2.886-.757Z"
      fill="#ADAAA1"
    />
    <path
      d="m115.984 259.403-3.643 2.13c-1.006.588-1.345 1.88-.757 2.886.589 1.006 1.881 1.345 2.887.757l3.643-2.13c1.006-.588 1.345-1.88.757-2.886-.588-1.006-1.881-1.345-2.887-.757Z"
      fill="#EBE7DB"
    />
    <path
      d="m118.115 263.05-3.643 2.129c-1.006.588-1.345 1.881-.757 2.887s1.881 1.345 2.887.756l3.643-2.129c1.006-.588 1.345-1.881.757-2.887-.589-1.006-1.881-1.345-2.887-.756Z"
      fill="#ADAAA1"
    />
    <path
      d="M88.9268 253.378c.58-1.01 1.8699-1.36 2.8799-.79 1.01.57 1.3601 1.87.7901 2.88l-5.2201 9.16c-.58 1.01-1.87 1.36-2.88.79-1.01-.57-1.36-1.87-.79-2.88l5.2201-9.16Z"
      fill="#F28518"
    />
    <path
      d="M83.708 262.538c.58-1.01 1.87-1.36 2.88-.79 1.01.57 1.36 1.87.79 2.88l-5.22 9.16c-.58 1.01-1.87 1.36-2.88.79-1.01-.57-1.36-1.87-.79-2.88l5.22-9.16Z"
      fill="#F28518"
    />
    <path
      d="m80.657 266.457 4.89 2.79-4.53 7.94-4.89-2.79 4.53-7.94Z"
      fill="#EBE7DB"
    />
    <path
      d="m76.827 273.177-.7 1.22 4.89 2.79.7-1.22c.37-.65.47-1.42.27-2.14-.2-.72-.67-1.33-1.32-1.7-.65-.37-1.42-.47-2.14-.27-.72.2-1.33.67-1.7 1.32Z"
      fill="#131F26"
    />
    <path
      d="m87.4717 255.921 3.666 2.091c1.0124.577 2.3009.224 2.8781-.788.5772-1.013.2245-2.301-.7879-2.878l-3.666-2.091c-1.0123-.577-2.3009-.224-2.8781.788-.5772 1.013-.2244 2.301.7879 2.878Z"
      fill="#EBE7DB"
    />
    <path
      d="m85.3858 259.58 3.666 2.09c1.0123.577 2.3009.224 2.8781-.788.5772-1.012.2244-2.301-.7879-2.878l-3.666-2.09c-1.0123-.578-2.3009-.225-2.8781.787-.5772 1.013-.2244 2.301.7879 2.879Z"
      fill="#ADAAA1"
    />
    <path
      d="m83.294 263.255 3.666 2.091c1.0123.577 2.3009.224 2.8781-.788.5772-1.013.2244-2.301-.7879-2.878l-3.666-2.091c-1.0124-.577-2.3009-.224-2.8781.788-.5772 1.013-.2245 2.301.7879 2.878Z"
      fill="#EBE7DB"
    />
    <path
      d="m81.208 266.912 3.666 2.09c1.0123.577 2.3009.224 2.8781-.788.5772-1.012.2244-2.301-.7879-2.878l-3.666-2.09c-1.0123-.578-2.3009-.225-2.8781.787-.5771 1.013-.2244 2.301.7879 2.879Z"
      fill="#ADAAA1"
    />
    <path
      d="M143.478 198.167v.26c.02 4.25-4.33 10.95-9.75 10.98l-66.4904.36c-5.42.03-9.84-6.63-9.87-10.88-.1-18.34 18.97-30.66 42.36-31 24.0204-.36 43.6504 11.49 43.7504 30.28Z"
      fill="#151324"
    />
    <path
      d="m144.207 242.156-87.1898.47c-.78 0-1.4.64-1.4 1.41v1.41c.04 5.63 5.52 11.22 22.57 11.13l44.9998-.24c17.05-.09 22.47-5.75 22.44-11.37v-1.41c-.01-.78-.64-1.4-1.42-1.4Z"
      fill="#EBE7DB"
    />
    <path
      d="m52.7383 231.383 95.6287-.518.046 8.44c.008 1.55-1.245 2.817-2.795 2.825l-89.9989.487c-1.55.009-2.8168-1.244-2.8251-2.794l-.0457-8.44h-.01Z"
      fill="#AA4500"
    />
    <path
      d="m145.538 228.086-89.9997.49c-1.55 0-2.81 1.27-2.8 2.83l95.6297-.52c0-1.55-1.27-2.81-2.83-2.8Z"
      fill="#FFD600"
    />
    <path
      d="m134.3 230.955-7.4 7.48c-.55.55-1.44.56-1.99.01l-7.48-7.4 16.88-.09h-.01Z"
      fill="#FFD519"
    />
    <path
      d="M55.5886 227.366c0 .66.64 1.2 1.41 1.2l87.1904-.47c.78 0 1.4-.55 1.4-1.21l-.03-6.02c0-.66-.64-1.2-1.41-1.2l-87.1904.47c-.78 0-1.4.55-1.4 1.21l.03 6.02Z"
      fill="#F7342B"
    />
    <path
      d="m143.836 214.032-86.759.469c-.8947.005-1.616.734-1.6112 1.629l.0129 2.39c.0049.894.7341 1.616 1.6288 1.611l86.7585-.469c.895-.005 1.616-.735 1.611-1.629l-.013-2.39c-.004-.895-.734-1.616-1.628-1.611Z"
      fill="#DDE5AC"
    />
    <path
      d="M148.299 219.626c0 .44-.221.85-.591 1.09l-4.75 3.07c-.82.53-1.85.8-2.88.81-1.03.01-2.06-.25-2.89-.78l-4.13-2.61c-.83-.52-1.859-.78-2.889-.78-1.03 0-2.06.28-2.88.81l-4.101 2.66c-.82.53-1.85.8-2.88.81-1.03.01-2.059-.25-2.889-.78l-4.13-2.61c-.83-.52-1.861-.78-2.891-.78s-2.06.28-2.88.81l-4.099 2.66c-.82.53-1.85.8-2.88.81-1.0305 0-2.0605-.25-2.8905-.78l-4.13-2.61c-.83-.52-1.86-.78-2.89-.78s-2.06.28-2.88.81l-4.1 2.66c-.82.53-1.85.8-2.88.81-1.03.01-2.06-.25-2.89-.78l-4.13-2.61c-.83-.52-1.86-.78-2.89-.78s-2.06.28-2.88.81l-4.1 2.66c-.82.53-1.85.8-2.88.81-1.03.01-2.06-.25-2.89-.78l-4.78-3.02c-.37-.23-.6-.64-.6-1.08 0-.78.62-1.41 1.4-1.41l92.8095-.5c.78 0 1.411.62 1.411 1.4l-.05-.02Z"
      fill="#00E030"
    />
    <path
      d="M100.248 174.887c-25.3102.14-44.9202 14.31-44.8002 36.81v1.41c.01.78.64 1.4 1.42 1.4l87.1902-.47c.78 0 1.4-.64 1.4-1.41v-1.41c-.12-22.5-19.89-36.46-45.2-36.32l-.01-.01Z"
      fill="#F28518"
    />
    <path
      d="M122.318 200.128c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm2.559 4.369c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm2.5-4.4c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm-2.549-4.369c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm-5.06.029c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm-2.51 4.4c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm2.551 4.371c0 .62.51 1.12 1.13 1.12.62 0 1.12-.51 1.12-1.13 0-.62-.51-1.12-1.13-1.12-.62 0-1.12.51-1.12 1.13Zm-41.3617-4.16c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.12-1.13.62 0 1.13.5 1.13 1.12Zm-2.5098 4.4c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.12-1.13.62 0 1.13.5 1.13 1.12Zm-2.5605-4.371c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.4999-1.13 1.1199-1.13.62 0 1.1301.5 1.1301 1.12Zm2.5097-4.4c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.12-1.13.62 0 1.13.5 1.13 1.12Zm5.0606-.029c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.12-1.13.62 0 1.13.5 1.13 1.12Zm2.5605 4.369c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.12-1.13.62 0 1.13.5 1.13 1.12Zm-2.5117 4.4c0 .62-.5 1.13-1.12 1.13-.62 0-1.13-.5-1.13-1.12 0-.62.5-1.13 1.12-1.13.62 0 1.13.5 1.13 1.12Z"
      fill="#FFA526"
    />
    <path
      d="M94.7578 200.248c3.84 1.85 7.5902 1.829 11.2502-.06"
      stroke="#8E0606"
      stroke-width="2.11"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M90.5273 197.438c3.11-.02 5.61-2.55 5.59-5.66-.02-3.11-2.55-5.61-5.66-5.59-3.11.02-5.61 2.55-5.59 5.66.02 3.11 2.55 5.61 5.66 5.59Z"
      fill="white"
    />
    <path
      d="M90.5174 196.038c2.33-.01 4.21-1.91 4.2-4.24-.01-2.33-1.91-4.21-4.24-4.2-2.33.01-4.21 1.91-4.2 4.24.01 2.33 1.91 4.21 4.24 4.2Z"
      fill="#8E0606"
    />
    <path
      d="M110.207 197.336c3.11-.02 5.61-2.55 5.59-5.66-.02-3.11-2.55-5.61-5.66-5.59-3.11.02-5.61 2.55-5.59 5.66.02 3.11 2.55 5.61 5.66 5.59Z"
      fill="white"
    />
    <path
      d="M110.207 195.926c2.33-.01 4.21-1.91 4.2-4.24-.01-2.33-1.91-4.21-4.24-4.2-2.33.01-4.21 1.91-4.2 4.24.01 2.33 1.91 4.21 4.24 4.2Z"
      fill="#8E0606"
    />
    <path
      opacity=".5"
      d="M144.498 200.047v.29c.03 4.68-4.33 12.08-9.74 12.1l-15.71.08c5.42-.03 9.77-7.42 9.74-12.1v-.29c-.1-18.18-15.25-30.48-35.2001-32.96 2.32-.32 4.7-.5 7.1301-.54 24.02-.38 43.66 12.7 43.77 33.41l.01.01Z"
      fill="#151324"
    />
    <path
      d="M145.618 182.837c-2.6-4.42-6.32-8.28-11.06-11.48-4.5-3.04-9.77-5.38-15.67-6.96-5.82-1.56-12.06-2.33-18.57-2.3h-.7402c-12.95.19-24.96 3.73-33.8 9.97-4.65 3.28-8.3 7.19-10.83 11.62-2.69 4.7-4.03 9.82-4 15.23l.07 13.11c.03 5.17 4.67 9.33 10.36 9.3l78.2402-.42c5.7-.03 10.29-4.24 10.26-9.41l-.07-13.37c-.03-5.48-1.44-10.62-4.19-15.29Zm-2.19 15.59c.02 4.25-4.33 10.95-9.75 10.98l-66.4903.36c-5.42.03-9.8399-6.63-9.8699-10.88-.05-9.17 4.6899-16.83 12.3699-22.25 7.68-5.41 18.2901-8.58 29.9901-8.75h.6702c23.71-.13 42.98 11.68 43.08 30.28v.26Z"
      fill="#EBE7DB"
    />
    <path
      d="m89.8889 213.194-3.9299.021.0212 3.93 3.93-.021-.0213-3.93Zm6.2598-.043-3.9299.021.0212 3.93 3.9299-.021-.0212-3.93Zm6.2693-.034-3.9297.022.0212 3.93 3.9305-.022-.022-3.93Zm6.27-.025-3.93.021.021 3.93 3.93-.021-.021-3.93Zm6.26-.043-3.93.021.021 3.93 3.93-.021-.021-3.93Z"
      fill="#131F26"
    />
    <path
      d="M63.8286 191.826c-.23 0-.4599-.05-.6699-.17-.68-.37-.9401-1.22-.5701-1.91 2.55-4.73 7-8.89 12.86-12.04 4.39-2.35 8.0001-3.32 8.1501-3.36.75-.2 1.5199.25 1.7199 1 .2.75-.25 1.52-1 1.72-.19.05-13.7 3.74-19.24 14.01-.25.47-.7299.74-1.2299.74l-.0201.01Zm-1.9905 4.77c.8781 0 1.59-.712 1.59-1.59 0-.878-.7119-1.59-1.59-1.59-.8782 0-1.5901.712-1.5901 1.59 0 .878.7119 1.59 1.5901 1.59Z"
      fill="white"
    />
    <path
      d="M162.117 217.146c4.413 0 7.99-3.577 7.99-7.99s-3.577-7.99-7.99-7.99-7.99 3.577-7.99 7.99 3.577 7.99 7.99 7.99Zm-123.7891.031c4.4127 0 7.99-3.577 7.99-7.99 0-4.412-3.5773-7.99-7.99-7.99-4.4128 0-7.99 3.578-7.99 7.99 0 4.413 3.5772 7.99 7.99 7.99Z"
      fill="#F28518"
    />
  </svg>
</template>

<script setup>
import { ref, defineExpose } from "vue";
const spaceBurgerRef = ref();
defineExpose({
  spaceBurgerRef,
});
</script>
