<template>
  <svg viewBox="0 0 480 480" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M477.464 0H0v477.482c62.0194 0 122.468-11.715 179.674-34.807 59.231-23.911 112.373-59.041 157.949-104.422 45.583-45.375 80.869-98.282 104.886-157.25 23.195-56.953 34.962-117.1345 34.962-178.87955 0-.71021 0-1.420416-.007-2.12345Z"
      fill="#001E5B"
    />
    <path
      d="M474.934 0c.008.703034.008 1.41324.008 2.12345 0 30.26635-2.926 60.64745-8.69 90.31835-5.83 29.9502-14.599 59.4282-26.085 87.6142-23.887 58.653-58.993 111.28-104.331 156.417-45.338 45.138-98.199 80.089-157.106 103.87-28.318 11.435-57.927 20.166-88.0103 25.969-29.8028 5.739-60.3189 8.652-90.7197 8.652V480c122.843 0 245.685-46.658 339.41-139.968C433.675 246.184 480.54 123.002 479.993 0h-5.059Z"
      fill="white"
    />
    <path
      d="M448.223 0c.274 57.742-10.636 115.52-32.736 169.847-3.703 9.111-7.717 18.121-12.055 27.017-21.256 43.653-50.086 84.572-86.482 120.807-87.521 87.133-202.235 130.7-316.95 130.7V0h448.223Z"
      fill="#001235"
    />
    <path
      d="M407.51 197.43c8.286 0 15.002-6.687 15.002-14.936 0-8.248-6.716-14.935-15.002-14.935-8.285 0-15.002 6.687-15.002 14.935 0 8.249 6.717 14.936 15.002 14.936Zm-148.228 97.364c16.567 0 29.998-13.371 29.998-29.865s-13.431-29.865-29.998-29.865-29.997 13.371-29.997 29.865 13.43 29.865 29.997 29.865ZM95.2153 418.627c13.8057 0 24.9967-11.141 24.9967-24.886 0-13.744-11.191-24.886-24.9967-24.886-13.8052 0-24.9965 11.142-24.9965 24.886 0 13.745 11.1913 24.886 24.9965 24.886ZM352.561 107.528c8.285 0 15.002-6.687 15.002-14.9359 0-8.2488-6.717-14.9359-15.002-14.9359-8.286 0-15.002 6.6871-15.002 14.9359 0 8.2489 6.716 14.9359 15.002 14.9359Zm64.894-60.3457c8.286 0 15.003-6.687 15.003-14.9359 0-8.2488-6.717-14.9359-15.003-14.9359-8.285 0-15.002 6.6871-15.002 14.9359 0 8.2489 6.717 14.9359 15.002 14.9359ZM203.021 388.62c5.524 0 10.002-4.458 10.002-9.958 0-5.499-4.478-9.957-10.002-9.957-5.524 0-10.001 4.458-10.001 9.957 0 5.5 4.477 9.958 10.001 9.958Z"
      fill="#00298E"
    />
  </svg>
</template>
