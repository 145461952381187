<template>
  <div class="stickerContainer">
    <div class="posterWrapper">
      <div class="speech">촵딱 포스터도 구경하고!</div>
      <img
        ref="posterRef"
        src="../assets/poster/posterA1.webp"
        alt="posterA1"
      />
      <div class="pagination">
        <div
          @click="onPrev"
          class="prevBtn btn"
          :style="selected1 ? { opacity: 0, cursor: 'default' } : ''"
        />
        <div class="pageWrapper">
          <a
            @click="onClickPagination1"
            :style="
              selected1
                ? { color: '#8E0606', cursor: 'default' }
                : { color: '#ffee33' }
            "
            >1</a
          >
          <a
            @click="onClickPagination2"
            :style="
              selected2
                ? { color: '#8E0606', cursor: 'default' }
                : { color: '#ffee33' }
            "
            >2</a
          >
          <a
            @click="onClickPagination3"
            :style="
              selected3
                ? { color: '#8E0606', cursor: 'default' }
                : { color: '#ffee33' }
            "
            >3</a
          >
          <a
            @click="onClickPagination4"
            :style="
              selected4
                ? { color: '#8E0606', cursor: 'default' }
                : { color: '#ffee33' }
            "
            >4</a
          >
          <a
            @click="onClickPagination5"
            :style="
              selected5
                ? { color: '#8E0606', cursor: 'default' }
                : { color: '#ffee33' }
            "
            >5</a
          >
        </div>
        <div
          @click="onNext"
          class="nextBtn btn"
          :style="selected5 ? { opacity: 0, cursor: 'default' } : ''"
        />
      </div>
    </div>
    <div class="line" />
    <div class="posterWrapper">
      <div class="speech">촵딱 스티커도 받아가!~</div>
      <img
        src="../assets/poster/posterB1.webp"
        style="border: none; height: 100%"
        alt="posterA1"
      />
      <a href="/download/lotteria_sticker.zip" download>
        <div class="download">다운받기</div>
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const selected1 = ref(true);
const selected2 = ref(false);
const selected3 = ref(false);
const selected4 = ref(false);
const selected5 = ref(false);

const posterRef = ref();

const onPrev = () => {
  if (selected2.value) {
    selected2.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA1.webp");
    selected1.value = true;
  } else if (selected3.value) {
    selected1.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA2.webp");
    selected2.value = true;
  } else if (selected4.value) {
    selected1.value = false;
    selected2.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA3.webp");
    selected3.value = true;
  } else if (selected5.value) {
    selected1.value = false;
    selected2.value = false;
    selected3.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA4.webp");
    selected4.value = true;
  }
};
const onNext = () => {
  if (selected1.value) {
    selected1.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA2.webp");
    selected2.value = true;
  } else if (selected2.value) {
    selected2.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA3.webp");
    selected3.value = true;
  } else if (selected3.value) {
    selected1.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA4.webp");
    selected4.value = true;
  } else if (selected4.value) {
    selected1.value = false;
    selected2.value = false;
    selected3.value = false;
    selected4.value = false;
    posterRef.value.src = require("../assets/poster/posterA5.webp");
    selected5.value = true;
  }
};

const onClickPagination1 = () => {
  if (!selected1.value) {
    selected2.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA1.webp");
    selected1.value = true;
  }
};
const onClickPagination2 = () => {
  if (!selected2.value) {
    selected1.value = false;
    selected3.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA2.webp");
    selected2.value = true;
  }
};
const onClickPagination3 = () => {
  if (!selected3.value) {
    selected1.value = false;
    selected2.value = false;
    selected4.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA3.webp");
    selected3.value = true;
  }
};
const onClickPagination4 = () => {
  if (!selected4.value) {
    selected1.value = false;
    selected2.value = false;
    selected3.value = false;
    selected5.value = false;
    posterRef.value.src = require("../assets/poster/posterA4.webp");
    selected4.value = true;
  }
};
const onClickPagination5 = () => {
  if (!selected5.value) {
    selected1.value = false;
    selected2.value = false;
    selected3.value = false;
    selected4.value = false;
    posterRef.value.src = require("../assets/poster/posterA5.webp");
    selected5.value = true;
  }
};
</script>

<style lang="scss" scoped>
.stickerContainer {
  width: 100%;
  display: flex;
  overflow: hidden;
  @media screen and (max-width: 480px) {
    & {
      flex-direction: column;
    }
  }
  .posterWrapper {
    width: calc(50% - 1px);
    padding: 10%;
    box-sizing: border-box;
    @media screen and (max-width: 480px) {
      & {
        width: 100%;
        padding: 20%;
      }
    }
    &:first-child {
      background: url(../assets/poster/posterABack.png) center center/cover
        no-repeat;
    }
    &:last-child {
      background: url(../assets/poster/posterBBack.png) center center/cover
        no-repeat;
    }
    .speech {
      position: absolute;
      text-align: center;
      width: 30%;
      margin-top: -6%;
      padding: 0.5% 0;
      color: #8e0606;
      background: #ffffff;
      border-radius: 20em;
      font-size: 1.2em;
      font-family: "chab";
      @media screen and (max-width: 1280px) {
        & {
          font-size: 1em;
        }
      }
      @media screen and (max-width: 768px) {
        & {
          margin-top: -8%;
          font-size: 0.58em;
        }
      }
      @media screen and (max-width: 480px) {
        & {
          width: 60%;
          margin-top: -15%;
          font-size: 0.9em;
          padding: 1% 0;
        }
      }
      @media screen and (min-width: 1919px) {
        & {
          font-size: 1.6em;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        border: 25px solid transparent;
        border-top-color: #ffffff;
        border-bottom: 0;
        margin-left: -25px;
        margin-bottom: -20px;
        @media screen and (max-width: 768px) {
          & {
            border: 15px solid transparent;
            border-top-color: #ffffff;
            border-bottom: 0;
            margin-left: -15px;
            margin-bottom: -15px;
          }
        }
        @media screen and (max-width: 480px) {
          & {
            border: 15px solid transparent;
            border-top-color: #ffffff;
            border-bottom: 0;
            margin-left: -15px;
            margin-bottom: -15px;
          }
        }
      }
    }
    img {
      position: relative;
      width: 100%;
      border: 4px solid white;
      vertical-align: bottom;
      box-sizing: border-box;
      background-size: cover;
    }
    .pagination {
      position: absolute;
      left: 0;
      width: 26%;
      margin: 3vw 0 0 12%;
      display: flex;
      justify-content: space-around;
      @media screen and (max-width: 768px) {
        & {
          width: 34%;
          margin: 14px 0 0 8%;
        }
      }
      @media screen and (max-width: 480px) {
        & {
          width: 76%;
          margin: 20px 0 0 12%;
        }
      }
      .btn {
        position: relative;
        width: 22px;
        height: 22px;
        cursor: pointer;
        @media screen and (max-width: 480px) {
          & {
            width: 22px;
            height: 22px;
          }
        }
        @media screen and (min-width: 1479px) {
          & {
            width: 35px;
            height: 35px;
          }
        }
        @media screen and (min-width: 1919px) {
          & {
            width: 50px;
            height: 50px;
          }
        }
      }
      .prevBtn {
        background: url("../assets/concept/prevArrow.png") center center/contain
          no-repeat;
      }
      .nextBtn {
        background: url("../assets/concept/nextArrow.png") center center/contain
          no-repeat;
      }
      .pageWrapper {
        display: flex;
        gap: 10px;
        @media screen and (min-width: 1919px) {
          & {
            gap: 15px;
          }
        }
        a {
          font-family: "chab";
          cursor: pointer;
          @media screen and (max-width: 480px) {
            & {
              font-size: 1em;
            }
          }
          @media screen and (min-width: 1479px) {
            & {
              font-size: 1.5em;
            }
          }
          @media screen and (min-width: 1919px) {
            & {
              font-size: 2.2em;
            }
          }
        }
      }
    }
    a {
      color: #7a2900;
      .download {
        position: absolute;
        right: 25%;
        transform: translate(50%, 0);
        margin: 2vw 0 0 0;
        width: 20%;
        padding: 10px;
        border-radius: 10vw;
        background: #ffee33;
        text-align: center;
        cursor: pointer;
        font-size: 2vw;
        font-family: "ddag";
        @media screen and (max-width: 480px) {
          & {
            width: 40%;
            right: 50%;
            margin: 4vw 0 0 0;
            font-size: 2.5vw;
          }
        }
      }
    }
  }
  .line {
    position: relative;
    transform: translate3d(-50%, 0, 0);
    left: 50%;
    width: 2px;
    height: 100%;
    background: white;
    @media screen and (max-width: 480px) {
      & {
        width: 100%;
        height: 2px;
      }
    }
  }
}
</style>
